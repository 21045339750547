import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import iconClose from "../../assets/images/icon-login.png";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as Yup from "yup";
  import { InputControl } from "../form/formControls";
  
  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Vui lòng nhập mật khẩu hiện tại"),
      
    passwordNew: Yup.string()
      .required("Vui lòng nhập mật khẩu mới")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[a-zA-Z0-9!@#$%&*]+$/,
        "Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số, 1 ký tự đặc biệt, và không chứa dấu."
      )
      .min(8, "Mật khẩu mới phải có ít nhất 8 ký tự"),
    confirmPasswordNew: Yup.string()
      .oneOf([Yup.ref("passwordNew"), null], "Mật khẩu xác nhận không khớp")
      .required("Vui lòng nhập lại mật khẩu mới"),
  });
  
  
  const PopupChangePasswordCurrent = ({ isOpen, onClose, defaultValues, onSubmit }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {},
    });
    const [focusStates, setFocusStates] = useState({});

    useEffect(() => {
        reset(defaultValues);
    }, [isOpen]);

    const handleFocus = (field) => {
        setFocusStates((prev) => ({ ...prev, [field]: true }));
      };
  
      const handleBlur = (field) => {
        setFocusStates((prev) => ({ ...prev, [field]: false }));
      };
  
    const submitHandler = (data) => {
      onSubmit(data);
    };
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"625px"}>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            p={"25px 24px 24px 24px"}
            borderBottom={"1px solid var(--color-boder)"}
          >
            <Text
              fontSize={"18px"}
              fontWeight={600}
              lineHeight={"normal"}
              color="var(--color-black)"
            >
              Đổi mật khẩu
            </Text>
            <Image src={iconClose} cursor={"pointer"} onClick={onClose} />
          </Flex>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Flex p={"20px 40px"} flexDirection={"column"} gap={"20px"} >
                <InputControl
                    type="password"
                    label="Mật khẩu cũ"
                    placeholder="Nhập mật khẩu cũ"
                    errors={errors.password}
                    register={register("password")}
                    onFocus={() => handleFocus("password")}
                    onBlur={() => handleBlur("password")}
                    state={focusStates["password"]}
                    mwidth={"100%"}
                />
                <InputControl
                    type="password"
                    label="Mật khẩu mới"
                    placeholder="Nhập mật khẩu mới"
                    errors={errors.passwordNew}
                    register={register("passwordNew")}
                    onFocus={() => handleFocus("passwordNew")}
                    onBlur={() => handleBlur("passwordNew")}
                    state={focusStates["passwordNew"]}
                    mwidth={"100%"}
                />
                <InputControl
                    type="password"
                    label="Nhập mật khẩu mới"
                    placeholder="Nhập lại mật khẩu mới"
                    errors={errors.confirmPasswordNew}
                    register={register("confirmPasswordNew")}
                    onFocus={() => handleFocus("confirmPasswordNew")}
                    onBlur={() => handleBlur("confirmPasswordNew")}
                    state={focusStates["confirmPasswordNew"]}
                    mwidth={"100%"}
                />
            </Flex>
            
            <Flex justifyContent={"flex-end"} p={"0px 40px 24px 40px"}>
              <Button
                type="submit"
                borderRadius={"8px"}
                background={"var(--linear-gradient-employee)"}
                color={"var(--color-main)"}
                w={"164px"}
                h={"44px"}
                _hover={{
                  filter: "brightness(90%)",
                }}
                _active={{
                  filter: "brightness(90%)",
                }}
              >
                Đổi mật khẩu
              </Button>
            </Flex>
          </form>
        </ModalContent>
      </Modal>
    );
  };
  
  export default PopupChangePasswordCurrent;
  