import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  Button,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  MenuButton,
  Image,
  HStack,
  Img,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useEmployee } from "../../../hooks/useEmployee";
import { formatTableValue } from "./formatTable";
import SearchableSelect from "../../../components/selectForm";
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../../assets/images/employee/edit.svg";
import iconKey from "../../../assets/images/employee/key.svg";
import iconUnlock from "../../../assets/images/employee/unlock.svg";
import iconLock from "../../../assets/images/employee/lock.svg";
import iconDelete from "../../../assets/images/employee/delete.svg";
import FormEmployee from "../../../components/form/formEmployee";
import useDebounce from "../../../hooks/useDebounce";
import { clientAPI } from "../../../api/client";
import chevronLeft from "../../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../../assets/images/employee/chevron-right.svg";
import ReactPaginate from "react-paginate";
import FormInfo from "../../../components/form/formInfo";
import { employeeCall } from "../../../api/employee";
import { commonCall } from "../../../api/common";
import toast from "react-hot-toast";
import DeleteDialog from "../../../components/dialog/delete";
import PopupChangePassword from "../../../components/popup/popupChangePassword";
import { useCategories } from "../../../hooks/useCategories";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const SalesPerson = ({ department, isRefetchingWithCreate }) => {
  // Stats
  const searchQueryParams = new URLSearchParams({
    department: department,
  }).toString();
  const [stats, setStats] = useState({
    totalEmployees: 0,
    activeEmployees: 0,
    retiredEmployees: 0,
    newEmployees: 0,
  });
  const getStats = async () => {
    const data = await clientAPI(
      "get",
      `/employee/statsEmployeeByDepartment?${searchQueryParams}`
    );
    if (data) setStats(data);
  };
  useEffect(() => {
    getStats();
  }, [department]);

  const totalEmployee = [
    {
      title: "Tổng nhân viên",
      total: stats?.totalEmployees,
    },
    {
      title: "Nhân viên đang hđ",
      total: stats?.activeEmployees,
    },
    {
      title: "Nhân viên đã nghỉ",
      total: stats?.retiredEmployees,
    },
    {
      title: "Nhân viên mới",
      total: stats?.newEmployees,
    },
  ];

  // table
  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(null);
  // const [searchDepartment, setSearchDepartment] = useState(null);
  const [saleBlock, setSaleBlock] = useState(null);
  const [saleArea, setSaleArea] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);
  //

  //sort
  const [sortStates, setSortStates] = useState({
    fullName: 0,
    code: 0,
    status: 0,
  });
  
  const handleSort = (field) => { 
    setSortStates((prevSortStates) => ({
      ...prevSortStates,
      [field]: prevSortStates[field] === 1 ? -1 : prevSortStates[field] + 1,
    }));
  };

  const {
    employeeData,
    totalPages,
    refetch,
    isLoading,
    isRefetching,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useEmployee(
    department, 
    debouncedSearchTerm, 
    branch, 
    saleBlock, 
    saleArea,
    null,
    sortStates.fullName,
    sortStates.code,
    sortStates.status
  );
  const { constantData } = useCategories();
  // refech create
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const prevIsRefetchingWithCreate = usePrevious(isRefetchingWithCreate);

  useEffect(() => {
    if (
      isRefetchingWithCreate &&
      prevIsRefetchingWithCreate !== isRefetchingWithCreate
    ) {
      refetch();
      getStats();
    }
  }, [isRefetchingWithCreate, prevIsRefetchingWithCreate, refetch]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  //
  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "num",
      },
      {
        label: "Ảnh ĐD",
        key: "avatar",
      },
      {
        label: "MSNV",
        key: "code",
      },
      {
        label: "Họ tên",
        key: "fullName",
      },
      {
        label: "Chức danh",
        key: "jobTitle",
      },
      // {
      //   label: "Khối bộ phận",
      //   key: "department",
      // },
      {
        label: "Khối",
        key: "saleBlock",
      },
      {
        label: "Vùng",
        key: "saleArea",
      },
      {
        label: "Chi nhánh",
        key: "branch",
      },
      {
        label: "SĐT&Email",
        key: "contact",
      },
      {
        label: "Số CCCD",
        key: "identificationNumber",
      },
      {
        label: "Ngày cấp&N/c CCCD",
        key: "identificationNumberInfo",
      },
      {
        label: "Chỗ ở hiện tại",
        key: "currentAddress",
      },
      {
        label: "Stk&Ngân hàng",
        key: "bankInfo",
      },
      {
        label: "Ngày vào làm",
        key: "startTime",
      },
      {
        label: "Ngày nghỉ",
        key: "endTime",
      },
      {
        label: "TTHĐ",
        key: "status",
      },
    ],
    data: employeeData,
  };
  //

  const branches = Object.entries(constantData?.branches || {}).map(
    ([key, value]) => {
      return {
        key,
        value,
      };
    }
  );
  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const departments = useMemo(
    () => formatEntries(constantData?.departments),
    [constantData?.departments]
  );
  const saleAreas = useMemo(
    () => formatEntries(constantData?.saleAreas),
    [constantData?.saleAreas]
  );
  const saleBlocks = useMemo(
    () => formatEntries(constantData?.saleBlocks),
    [constantData?.saleBlocks]
  );

  // 
  const [dataEdit, setDataEdit] = useState(null);
  const handleOpenEditEmployee = (data) => {
    setDataEdit({
      saleBlockId: data?.saleBlock?._id,
      saleAreaId: data?.saleArea?._id,
      jobTitleId: data?.jobTitle?._id,
      jobPositionId: data?.jobPosition?._id,
      branchId: data?.branch?._id,
      ...data,
    });
  };

  const handleCloseEditEmployee = () => {
    setDataEdit(null);
  };

  // 
  const [dataDetail, setDataDetail] = useState(null);
  const handleOpenDetailEmployee = (data) => {
    setDataDetail(data);
  };

  const handleCloseDetailEmployee = () => {
    setDataDetail(null);
  };

  // 
  const onSubmit = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật ...");
    try {
      const { id } = data;
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      if (filteredData?.file) {
        const { filePath } = await commonCall.uploadAvatar(filteredData?.file);
        if (filePath) filteredData.avatar = filePath;
      }
      delete filteredData.id;
      delete filteredData.file;

      if (filteredData?.status === "1") {
        filteredData.endTime = new Date();
      }

      await employeeCall.update(id, { ...filteredData });
      await refetch();
      await getStats();
      setDataEdit(null);
      toast.success("Cập nhật thành công");
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  //
  const [dataPassword, setDataPassword] = useState(null); 
  
  const onSubmitPassword = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật mật khẩu ...");
    const { id, password } = data;
    try {
      await employeeCall.updatePassword({ id, password });
      await refetch();
      await getStats();
      toast.success("Cập nhập mật khẩu thành công");
      setDataPassword(null)
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  const handleOpenChangePassword = (data) => {
    setDataPassword(data);
  };

  const handleCloseChangePassword = () => {
    setDataPassword(null);
  };

  // 
  const handleChangeEmployeeStatus = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật trạng thái ...");
    const { id, status } = data;
    let newStatus = 0;
    if (Boolean(status)) newStatus = 0;
    else newStatus = 1;
    try {
      await employeeCall.updateStatus({ id, status: newStatus });
      await refetch();
      await getStats();
      toast.success("Cập nhập trang thái thành công");
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  //
  const [dataDel, setDataDel] = useState(null); 
  const handleOpenDeleteEmployee = (data) => {
    setDataDel(data);
  };

  const handleCloseDeleteEmployee = () => {
    setDataDel(null);
  };

  const handleDeleteEmployee = async (data) => {
    const toastUpdate = toast.loading("Đang xóa nhân viên ...");
    const { id } = data;
    try {
      await employeeCall.delete(id);
      await refetch();
      await getStats();
      toast.success("Xóa thành công");
      setDataDel(null);
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex alignItems={"center"} gap={{ base: "16px" }}>
        {totalEmployee.map((item, index) => (
          <Flex
            w={{ base: "192px" }}
            key={index}
            flexDirection={"column"}
            backgroundColor={"#FFF"}
            p={"16px 42px 16px 16px"}
            borderRadius={{ base: "12px" }}
            gap={{ base: "12px" }}
          >
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={400}
              color="var(--text-color-Subtittle)"
            >
              {item.title}
            </Text>
            <Text fontSize={{ base: "20px" }} fontWeight={500} color={"#000"}>
              {item.total}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Flex
        minHeight="calc(100vh - 297.5px)"
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup width="300px" borderRadius={{ base: "8px" }}>
            <Input
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
              backgroundColor={"var(--fill-avatar)"}
              onChange={onChangeSearch}
            />
            <InputRightElement
              cursor={"pointer"}
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="auto"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }}>
            <SearchableSelect
              isShowSearch={false}
              options={branches}
              initialSelectedOption={{ key: 0, value: "Chọn chi nhánh" }}
              setValue={setBranch}
            />
            {/* <SearchableSelect
              isShowSearch={false}
              options={jobPositions}
              initialSelectedOption={{ key: 0, value: "Chọn chức danh" }}
              setValue={setSearchDepartment}
            /> */}
            <SearchableSelect
              isShowSearch={true}
              options={saleAreas}
              initialSelectedOption={{ key: 0, value: "Chọn vùng" }}
              setValue={setSaleArea}
            />
            <SearchableSelect
              isShowSearch={true}
              options={saleBlocks}
              initialSelectedOption={{ key: 0, value: "Chọn khối" }}
              setValue={setSaleBlock}
            />
          </Flex>
        </Flex>
        <Flex
          minHeight="calc(100vh - 365.5px)"
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Skeleton isLoaded={!isLoading && !isRefetching}>
            <TableContainer width={"100%"} pb={"16px"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="var(--fill-avatar)"
                >
                  <Tr h={{ base: "41px" }}>
                    {historyTableData.headers.map((e, index) => {
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={"20%"}
                        >
                           <Box
                              cursor={"pointer"}
                              onClick={() => { 
                                handleSort(e.key);
                              }}
                              textAlign={"center"}
                              display={"flex"}
                              gap={"4px"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Text
                                color={
                                  (sortStates[e.key] == 1 ||
                                    sortStates[e.key] == -1) &&
                                  "var(--color-option-employee-hover)"
                                }
                              >
                                {e.label}{" "}
                              </Text>

                              <Text>
                                {sortStates[e.key] === 1 ? (
                                  <TiArrowSortedUp color="var(--color-option-employee-hover)" />
                                ) : sortStates[e.key] === -1 ? (
                                  <TiArrowSortedDown color="var(--color-option-employee-hover)" />
                                ) : (
                                  ""
                                )}
                              </Text>
                            </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {historyTableData.data?.length > 0 ? (
                    historyTableData.data?.map((e, rowIndex) => {
                      const items = { ...e };
                      delete items.detail;
                      delete items.edit;
                      const keyValues = Object.keys(items);
                      return (
                        <Fragment key={rowIndex}>
                          <Tr
                            w={"100%"}
                            key={rowIndex}
                            h={"72px"}
                            backgroundColor={
                              rowIndex % 2 === 0
                                ? "#transparent"
                                : "var(--fill-avatar)"
                            }
                          >
                            <Td p={"16px 16px 8px 16px"} w={"20%"}>
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                >
                                  <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color={"var(--color-info-employee)"}
                                >
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenEditEmployee(e?.edit)
                                    }
                                  >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenChangePassword(e?.edit)
                                    }
                                  >
                                    <Image src={iconKey} />
                                    Đổi mật khẩu
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleChangeEmployeeStatus(e?.edit)
                                    }
                                  >
                                    {items?.status ? (
                                      <Image src={iconUnlock} />
                                    ) : (
                                      <Image src={iconLock} />
                                    )}{" "}
                                    <Text
                                      color={
                                        !items?.status &&
                                        "var(--text-red-employee)"
                                      }
                                    >
                                      {!items?.status
                                        ? "Dừng hoạt động"
                                        : "Mở hoạt động"}
                                    </Text>
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenDeleteEmployee(e?.edit)
                                    }
                                  >
                                    <Image src={iconDelete} /> Xóa nhân viên
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width;
                              let whiteSpace;
                              if (keyvalue === "fullName") {
                                width = "116px";
                                whiteSpace = "normal";
                              } else if (keyvalue === "code") {
                                width = "126px";
                              } else {
                                width = "auto";
                                whiteSpace = "inherit";
                              }
                              const isShow =
                                keyvalue === "avatar" ||
                                keyvalue === "code" ||
                                keyvalue === "fullName";
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={"20%"}
                                  key={index}
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    fontWeight={500}
                                    color={"#293755"}
                                    w={width}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                    onClick={
                                      isShow
                                        ? () =>
                                            handleOpenDetailEmployee(e?.detail)
                                        : undefined
                                    }
                                  >
                                    {formatTableValue(
                                      items[keyvalue],
                                      keyvalue
                                    )}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={historyTableData.headers.length}
                        textAlign="center"
                        padding={"70px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Skeleton>
          <Flex
            m={"50px 16px 16px 16px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"20px"}
              color={"var(--text-color-Subtittle)"}
            >
              Showing {startEntry} to {endEntry} of {entries} entries
            </Text>
            <HStack spacing={2} justify="flex-end">
              <ReactPaginate
                className="pagination-custom"
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"ellipsis"}
                breakLabel={"..."}
                previousLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />
                }
                nextLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />
                }
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      {dataEdit && (
        <FormEmployee
          isOpen={!!dataEdit}
          onClose={handleCloseEditEmployee}
          onSubmit={onSubmit}
          defaultValues={dataEdit}
          department={department}
        />
      )}
      {dataDel && <DeleteDialog
        isOpen={!!dataDel}
        onClose={handleCloseDeleteEmployee}
        title={"Xóa nhân viên kinh doanh"}
        description={`Bạn có muốn xóa nhân viên ${dataDel?.fullName} không ?`}
        action={() => handleDeleteEmployee(dataDel)}
      />}
      {dataPassword && <PopupChangePassword
        isOpen={!!dataPassword}
        onClose={handleCloseChangePassword}
        onSubmit={onSubmitPassword}
        defaultValues={dataPassword}
      />}
      {/* form */}
      {dataDetail && (
        <FormInfo
          isOpen={!!dataDetail}
          onClose={handleCloseDetailEmployee}
          value={dataDetail}
        />
      )}
    </Flex>
  );
};

export default SalesPerson;
