import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  Button,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  MenuButton,
  Image,
  HStack,
  Text,
  useDisclosure,
  Skeleton,
  Img,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import SearchableSelect from "../../../components/selectForm";
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../../assets/images/employee/edit.svg";
import iconClock from "../../../assets/images/timeKeeping/clock.svg";
import calendarIcon from "../../../assets/images/onleave/calendar.svg";
import PopupTimeKeepingHistory from "../../../components/popup/popupTimeKeepingHistory";
import { usePopupForm } from "../../../contexts/usePopupForm";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PopUpTimeKeepingEditHistory from "../../../components/popup/popupTimekeepingEditHistory";
import { useCheckIn } from "../../../hooks/useCheckIn";
import { useCategories } from "../../../hooks/useCategories";
import useDebounce from "../../../hooks/useDebounce";
import chevronLeft from "../../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../../assets/images/employee/chevron-right.svg";
import ReactPaginate from "react-paginate";
import { checkinCall } from "../../../api/checkin";
import { formatDate } from "../../../utils/utils";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";

const AttendanceList = ({ isRefetchingWithCreate }) => {
  const { onOpen,onClose,onToggle } = useDisclosure();
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  //

  //
  const [saleBlockId, setSaleBlockId] = useState(null);
  const [saleAreaId, setSaleAreaId] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const [sortStates, setSortStates] = useState({
    checkinTime: 0,
    fullName: 0,
    code: 0,
  });

  const handleSort = (field) => {
    setSortStates((prevSortStates) => ({
      ...prevSortStates,
      [field]: prevSortStates[field] === 1 ? -1 : prevSortStates[field] + 1,
    }));
  };

  const {
    checkInData,
    totalPages,
    refetch,
    isLoading,
    isRefetching,
    setCurrentPage,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useCheckIn(
    debouncedSearchTerm,
    saleBlockId,
    saleAreaId,
    startDate,
    endDate,
    sortStates.checkinTime,
    sortStates.fullName,
    sortStates.code
  );

  const { constantData } = useCategories();
  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "Ngày tháng",
        key: "dateTime",
      },
      {
        label: "Thời gian check in",
        key: "checkinTime",
      },
      {
        label: "Mã nhân viên",
        key: "code",
      },
      {
        label: "Tên nhân viên",
        key: "fullName",
      },
      {
        label: "Địa điểm chấm",
        key: "name",
      },
      {
        label: "Ngày công",
        key: "workingDayRate",
      },
      {
        label: "Lí do",
        key: "reason",
      },
    ],
    data: checkInData,
  };

  const formatEntries = (entries) =>
    Object.entries(entries || {}).map(([key, value]) => ({ key, value }));

  const saleAreas = useMemo(
    () => formatEntries(constantData?.saleAreas),
    [constantData?.saleAreas]
  );
  const saleBlocks = useMemo(
    () => formatEntries(constantData?.saleBlocks),
    [constantData?.saleBlocks]
  );

  // refech create
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const prevIsRefetchingWithCreate = usePrevious(isRefetchingWithCreate);

  useEffect(() => {
    if (
      isRefetchingWithCreate &&
      prevIsRefetchingWithCreate !== isRefetchingWithCreate
    ) {
      refetch();
    }
  }, [isRefetchingWithCreate, prevIsRefetchingWithCreate, refetch]);

  //
  const [dataEdit, setDataEdit] = useState(null);
  const handleOpenEdit = (data) => {
    setDataEdit(data);
  };

  const handleCloseEdit = () => {
    onClose();
    setDataEdit(null);
  };

  const onSubmit = async (data) => { 
    const toastCreate = toast.loading("Đang sửa chấm công ...");
    const { _id, dateTime, checkinTime, reason } = data;
    // console.log({
    //   dateTime: formatDate(dateTime),
    //   checkinTime: formatTimeDb(checkinTime),
    // });
    try {
      await checkinCall.update(_id, {
        dateTime: formatDate(dateTime),
        checkinTime: checkinTime,
        comment: reason,
      });
      onClose();
      await refetch();
      onToggle();
      toast.success("Update thành công");
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi sửa");
      toast.dismiss(toastCreate);
    }

    toast.dismiss(toastCreate);
  };

  //
  const { popupTimeKeepingHistory, setpopupTimeKeepingHistory } =
    usePopupForm();
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const openModalDetail = (data) => {
    setIsLoadingDetail(true);
    setpopupTimeKeepingHistory(data);
  };
  const closeModalDetail = () => {
    setIsLoadingDetail(false);
    setpopupTimeKeepingHistory(null);
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        minHeight="calc(100vh - 167px)"
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup width={"300px"} borderRadius={{ base: "8px" }}>
            <Input
              h={"44px"}
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
              onChange={onChangeSearch}
            />
            <InputRightElement
              h={"44px"}
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }}>
            <InputGroup>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Từ ngày - Đến ngày"
                className="datePickerStyle"
              />
              {!startDate && !endDate && (
                <InputRightElement pointerEvents="none" h={"44px"}>
                  <Image src={calendarIcon} />
                </InputRightElement>
              )}
            </InputGroup>
            <SearchableSelect
              isShowSearch={false}
              options={saleAreas}
              initialSelectedOption={{ key: 0, value: "Chọn vùng" }}
              setValue={setSaleAreaId}
            />
            <SearchableSelect
              isShowSearch={false}
              options={saleBlocks}
              initialSelectedOption={{ key: 0, value: "Chọn khối" }}
              setValue={setSaleBlockId}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"100%"}
          minH="calc(100vh - 235px)"
        >
          <Skeleton isLoaded={!isLoading && !isRefetching}>
            <TableContainer w={"100%"} pb={"32px"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                >
                  <Tr h={{ base: "41px" }}>
                    {historyTableData.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "") {
                        width = "2%";
                        textAlign = "center";
                      } else if (e.key === "dateTime") {
                        width = "10%";
                        textAlign = "start";
                      } else if (e.key === "checkinTime") {
                        width = "15%";
                        textAlign = "start";
                      } else if (e.key === "fullName" || e.key === "code") {
                        width = "10%";
                        textAlign = "start";
                      } else if (e.key === "workingDayRate") {
                        width = "10%";
                        textAlign = "center";
                      } else if (e.key === "name") {
                        width = "40%";
                        textAlign = "end";
                      } else {
                        width = "15%";
                        textAlign = "end";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              handleSort(e.key);
                            }}
                            textAlign={textAlign}
                            display={"flex"}
                            gap={"4px"}
                            alignItems={"center"}
                          >
                            <Text
                              color={
                                (sortStates[e.key] === 1 ||
                                  sortStates[e.key] === -1) &&
                                "var(--color-option-employee-hover)"
                              }
                            >
                              {e.label}{" "}
                            </Text>

                            <Text>
                              {sortStates[e.key] === 1 ? (
                                <TiArrowSortedUp color="var(--color-option-employee-hover)" />
                              ) : sortStates[e.key] === -1 ? (
                                <TiArrowSortedDown color="var(--color-option-employee-hover)" />
                              ) : (
                                ""
                              )}
                            </Text>
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {historyTableData.data?.length > 0 ? (
                    historyTableData.data?.map((e, rowIndex) => {
                      const items = { ...e };
                      delete items.detail;
                      delete items.edit;
                      delete items.export;
                      const keyValues = Object.keys(items);
                      return (
                        <Tr key={rowIndex} h={"41px"}>
                          <Td
                            p={"16px 0px 8px 0px"}
                            w={rowIndex === 0 ? "20px" : "auto"}
                            bg={
                              rowIndex % 2 === 0
                                ? "var(--color-main)"
                                : "var(--fill-avatar)"
                            }
                          >
                            <Menu>
                              <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                              >
                                <AiOutlineMore fontSize={"20px"} />
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={"16px"}
                                  onClick={() => handleOpenEdit(e?.edit)}
                                >
                                  <Image src={iconEdit} />
                                  <Text
                                    fontSize={{ base: "14px" }}
                                    fontWeight={500}
                                    lineHeight={"140%"}
                                    color="var(--color-option-employee)"
                                  >
                                    Chỉnh sửa
                                  </Text>
                                </MenuItem>
                                <MenuItem
                                  alignItems={"center"}
                                  display={"flex"}
                                  gap={"16px"}
                                  onClick={() => openModalDetail(e?.detail)}
                                >
                                  <Image src={iconClock} />
                                  <Text
                                    fontSize={{ base: "14px" }}
                                    fontWeight={500}
                                    lineHeight={"140%"}
                                    color="var(--color-option-employee)"
                                  >
                                    Lịch sử chỉnh sửa
                                  </Text>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                          {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let widthText = "100%";
                            let textAlign = "auto";
                            let whiteSpace = "inherit";
                            if (keyvalue === "") {
                              width = "2%";
                              textAlign = "center";
                            } else if (keyvalue === "dateTime") {
                              width = "10%";
                              textAlign = "start";
                            } else if (keyvalue === "checkinTime") {
                              width = "15%";
                              textAlign = "start";
                            } else if (keyvalue === "workingDayRate") {
                              width = "10%";
                              textAlign = "center";
                            } else if (
                              keyvalue === "fullName" ||
                              keyvalue === "code"
                            ) {
                              width = "10%";
                              textAlign = "start";
                            } else if (keyvalue === "name") {
                              width = "40%";
                              textAlign = "end";
                            } else {
                              width = "15%";
                              textAlign = "end";
                              whiteSpace = "inherit";
                            }
                            return (
                              <Td
                                p={"0px 16px 0px 16px"}
                                w={width}
                                key={index}
                                bg={
                                  rowIndex % 2 === 0
                                    ? "var(--color-main)"
                                    : "var(--fill-avatar)"
                                }
                              >
                                <Box
                                  fontSize={{ base: "14px" }}
                                  lineHeight={"19.6px"}
                                  textAlign={textAlign}
                                  fontWeight={500}
                                  w={widthText}
                                  whiteSpace={whiteSpace}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  display="-webkit-box"
                                  sx={{
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                  color="var(--color-option-employee)"
                                >
                                  {formatTableValue(e[keyvalue], keyvalue)}
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={historyTableData.headers.length}
                        textAlign="center"
                        padding={"40px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Skeleton>
          <Flex
            m={"50px 16px 16px 16px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"20px"}
              color={"var(--text-color-Subtittle)"}
            >
              Showing {startEntry} to {endEntry} of {entries} entries
            </Text>
            <HStack spacing={2} justify="flex-end">
              <ReactPaginate
                className="pagination-custom"
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"ellipsis"}
                breakLabel={"..."}
                previousLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />
                }
                nextLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />
                }
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      {dataEdit && (
        <PopUpTimeKeepingEditHistory
          isOpen={onOpen}
          onClose={handleCloseEdit}
          onSubmit={onSubmit}
          defaultValues={dataEdit}
        />
      )}
      {/*  */}
      {popupTimeKeepingHistory && (
        <PopupTimeKeepingHistory
          isOpen={!!popupTimeKeepingHistory}
          onClose={closeModalDetail}
          data={popupTimeKeepingHistory}
          isLoading={isLoadingDetail}
          setIsLoading={setIsLoadingDetail}
        />
      )}
    </Flex>
  );
};

export default AttendanceList;
