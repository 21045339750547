import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchAllData() {
  const [jobTitleData, supportDepartment, branchData] = await Promise.all([
    clientAPI("get", `/jobTitle?page=1&limit=9999`),
    clientAPI("get", `/supportDepartment?page=1&limit=9999`),
    clientAPI("get", `/branch?page=1&limit=9999`),
  ]);

  return {
    jobTitles: jobTitleData?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    supportDepartment: supportDepartment?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
    branches: branchData?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name ,
    })) || [],
  };
}

export function usePersonnelInfo() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    jobTitles: data?.jobTitles || {}, 
    supportDepartment: data?.supportDepartment || {},
    branches: data?.branches || {},
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
