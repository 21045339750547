import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { userGroupRolesLinksCall } from "../../../api/userGroupRolesLinks";
import TreeCheckbox from "./treeCheckBox";
import toast from "react-hot-toast";
import SearchBox from "./searchBox";

const AddAdminManagement = ({
  isOpen,
  onClose,
  data,
  defaultValues,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues || {},
  });

  const [tree, setTree] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);

      // Tách chuỗi `group_role_code` thành mảng
      const initialSelectedRoles = defaultValues?.group_role_code
        ? defaultValues?.group_role_code.split(",")
        : [];

      setSelectedRoles(initialSelectedRoles); // Cài đặt các quyền đã chọn ban đầu

      userGroupRolesLinksCall.tree().then((response) => {
        setTree(response);
      });
    } else {
      setSelectedRoles([]); // Reset lại selectedRoles khi modal đóng
    }
  }, [isOpen, defaultValues, reset]);

  const toggleRole = (code, children = []) => {
    setSelectedRoles((prevRoles) => {
      const isSelected = prevRoles.includes(code);

      // Trường hợp không có quyền con
      if (children.length === 0) {
        // Nếu đã chọn thì bỏ chọn, nếu chưa chọn thì chọn
        return isSelected
          ? prevRoles.filter((role) => role !== code)
          : [...prevRoles, code];
      }

      const isFullySelected =
        isSelected && children.every((child) => prevRoles.includes(child));
      const isPartiallySelected = isSelected && !isFullySelected;

      if (!isSelected) {
        // Trạng thái 1: Check (tích) - Chọn quyền cha và tất cả quyền con
        return [...prevRoles, code, ...children];
      } else if (isFullySelected) {
        // Trạng thái 2: Indeterminate (dấu trừ) - Chỉ giữ lại quyền cha, bỏ chọn quyền con
        return [code, ...prevRoles.filter((role) => !children.includes(role))];
      } else if (isPartiallySelected) {
        // Trạng thái 3: Uncheck (bỏ chọn) - Bỏ quyền cha và quyền con
        return prevRoles.filter(
          (role) => role !== code && !children.includes(role)
        );
      }
    });
  };

  const onchangeEmployee = (option) => {
    setValue("user_id", option);
  };

  const submitHandler = (data) => {
    if (!data?.user_id) {
      toast.error("Vui lòng chọn nhân viên");
      return;
    }
    if (selectedRoles.length === 0) {
      toast.error("Vui lòng chọn quyền cần cấp");
      return;
    }
    const uniqueRoles = Array.from(new Set(selectedRoles));
    onSubmit({ ...data, group_role_code: uniqueRoles });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex minH={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {defaultValues ? "Sửa Admin" : "Admin mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"545px"} m={"24px auto"}>
              <Flex alignItems={"flex-start"} gap={"27px"} mb={"16px"}>
                <SearchBox
                  label="Nhân viên"
                  isSearchable={true}
                  placeholder="Chọn nhân viên"
                  selectedMain={watch("user_id")}
                  onChange={onchangeEmployee}
                  initialSelectedEmp={defaultValues?.employeeObject || null}
                />
                {tree && (
                  <TreeCheckbox
                    node={tree}
                    selectedRoles={selectedRoles}
                    toggleRole={toggleRole}
                    defaultExpandedRoles={selectedRoles} // truyền thêm prop này để tự động mở rộng nhánh
                  />
                )}
              </Flex>
            </Box>
            <Flex p={"24px"} justifyContent={"flex-end"}>
              <Button
                type="submit"
                w={"164px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--linear-gradient-employee)"}
                _hover={{ filter: "brightness(90%)" }}
              >
                {defaultValues ? "Sửa admin" : "Tạo admin"}
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddAdminManagement;
