import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import FormFeature from "./form/formFeature";
import { usePersonnelInfo } from "../../../hooks/usePersonnelInfo";
import { ROLES_CONFIG } from "../../../utils";
import { useSelector } from "react-redux";

const OrganizationalInfor = () => {
  const {
    jobTitles,
    supportDepartment,
    branches,
    refetch,
    isLoading,
    isRefetching,
  } = usePersonnelInfo();

  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = (requiredRoles) => {
    return (
      requiredRoles.some((role) => roles.includes(role)) ||
      roles.includes("ALL")
    );
  };

  const gridItemsData = [
    {
      title: "Danh sách chức danh",
      button: "Thêm chức danh",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên chức danh", key: "name" },
        ],
        data: jobTitles,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
          .TAB_CD_AND_PB_AND_CN.CHILDREN_TAB.CHILDREN_TAB_DSCD.role,
      },
      type: 0,
    },
    {
      title: "Danh sách phòng ban",
      button: "Thêm phòng ban",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên phòng ban", key: "name" },
        ],
        data: supportDepartment,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
          .TAB_CD_AND_PB_AND_CN.CHILDREN_TAB.CHILDREN_TAB_DSPB.role,
      },
      type: 1,
    },
    {
      title: "Danh sách chi nhánh",
      button: "Thêm chi nhánh",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên chi nhánh", key: "name" },
        ],
        data: branches,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
          .TAB_CD_AND_PB_AND_CN.CHILDREN_TAB.CHILDREN_TAB_DSCN.role,
      },
      type: 2,
    },
  ];

  return (
    <Grid minH={"80vh"} templateColumns="repeat(3, 1fr)" gap={4}>
      {gridItemsData.map(
        (item, index) =>
          hasPermission(item.data.role) && (
            <GridItem
              key={index}
              p={"16px"}
              bg={"var(--color-main)"}
              borderRadius={"16px"}
            >
              <FormFeature
                title={item.title}
                button={item.button}
                data={item.data}
                isLoading={isLoading}
                isRefetching={isRefetching}
                refetch={refetch}
                type={item.type}
              />
            </GridItem>
          )
      )}
    </Grid>
  );
};

export default OrganizationalInfor;
