import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";

async function fetchAllData() {
  const [department, saleArea, saleBlock, businessCategory ] = await Promise.all([
    clientAPI("get", `/department?page=1&limit=9999`),
    clientAPI("get", `/saleArea?page=1&limit=9999`),
    clientAPI("get", `/saleBlock?page=1&limit=9999`),
    clientAPI("get", `/businessCategory?page=1&limit=9999`),
  ]);

  return {
    department: department?.items?.map(item => ({ 
        id: item._id,
        code: item.code,
        name: item.name,
    })) || [],
    saleArea: saleArea?.items?.map(item => ({ 
        id: item._id,
        code: item.code,
        name: item.name ,
    })) || [],
    saleBlock: saleBlock?.items?.map(item => ({ 
        id: item._id,
        code: item.code,
        name: item.name,
        saleAreaId: item.saleAreaId,
    })) || [],
    saleAreaSelect: saleArea?.items?.map(item => ({ 
        value: item._id,
        label: item.name,
    })) || [],
    businessCategory: businessCategory?.items?.map(item => ({ 
      id: item._id,
      code: item?.code || "",
      name: item?.name || "",
      rate: item?.rate || 0,
      is_related_other_type: item?.is_related_other_type || false,
      min_working_days: item?.min_working_days || 0,
      related_to: item?.related_to || "",
    })) || [],
    businessCategorySelect: businessCategory?.items?.map(item => ({ 
      value: item._id,
      label: item.name,
    })) || [],
  };
}

export function usePersonnelStructure() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    department: data?.department || {}, 
    saleArea: data?.saleArea || {},
    saleBlock: data?.saleBlock || {},
    saleAreaSelect: data?.saleAreaSelect || {},
    businessCategory: data?.businessCategory || {},
    businessCategorySelect: data?.businessCategorySelect || {},
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
