import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconUpFile from "../../assets/images/product/iconUpFile.svg";
import plus from "../../assets/images/product/plus.svg";
import TableApartments from "./tableApartments";
import FormNew from "./formApartments/fromNew";
import * as XLSX from "xlsx";
import toast from 'react-hot-toast';

export default function Apartment() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState({
      headers: [
        {
          label: "",
          key: "",
        },
        {
          label: "STT",
          key: "",
        },
        {
          label: "Mã nhân viên",
          key: "code",
        },
        {
          label: "Tên căn hộ",
          key: "nameApartment",
        },
        {
          label: "DT đất",
          key: "landArea",
        },
        {
          label: "DT xây dựng",
          key: "constructionArea",
        },
        {
          label: "Chiều rộng",
          key: "width",
        },
        {
          label: "Chiều dài",
          key: "height",
        },
        {
          label: "Phòng ngủ",
          key: "bedroom",
        },
        {
          label: "Phòng VS",
          key: "toilet",
        },
        {
          label: "Hướng",
          key: "direction",
        },
        {
          label: "Loại hình",
          key: "type",
        },
        {
          label: "Tính chất",
          key: "nature",
        },
        {
          label: "Dự án",
          key: "project",
        },
        {
          label: "Phân khu",
          key: "subdivision",
        },
        {
          label: "Giá trị",
          key: "price",
        },
        {
          label: "Giá tính HH",
          key: "priceHH",
        },
      ],
      data: [
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCE THE DIAMOND RESIDENCETHE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
        {code: 'S3-1208',nameApartment: 'Tên căn hộ', landArea: '150m2', constructionArea: '150m2', width: '10m2', height: '10m2', bedroom: '3', toilet: '2', direction: 'Tây nam bắc', type: 'Căn hộ CC', nature: 'Căn hộ CC', project: 'THE DIAMOND RESIDENCE', subdivision: 'Phân khu A', price: '1 tỷ 950 triệu',priceHH: '352 triệu'},
      ],
    });

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
          // Hiện thông báo tải file
          const loadingToastId = toast.loading("Đang tải file...");
  
          const reader = new FileReader();
          reader.onload = (e) => {
              const binaryStr = e.target.result;
              const workbook = XLSX.read(binaryStr, { type: "binary" });
  
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
              setTableData((prevState) => ({
                  ...prevState,
                  data: jsonData,
              }));
  
              toast.success("Tải file thành công!");
              toast.dismiss(loadingToastId);
              event.target.value = null;
          };
  
          reader.onerror = () => {
              toast.error("Đã xảy ra lỗi khi tải file.");
              toast.dismiss(loadingToastId);
          };
  
          reader.readAsBinaryString(file);
      } else {
          toast.error("Vui lòng chọn một file.");
      }
  };
  

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý sản phẩm
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"} >
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                position={"relative"}
                overflow={"hidden"}
            >
                <Image src={iconUpFile} zIndex={1} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Tải file lên
                </Text>
                <Input 
                  type="file" 
                  accept=".xlsx,.csv"
                  fontSize={{ base: "15px" }}
                  fontWeight={600}
                  color="var(--color-main)"
                  opacity={"0"}
                  position={"absolute"}
                  zIndex={2}
                  w={"100%"}
                  h={"200%"}
                  bottom={"0"}
                  left={"0"}
                  cursor={"pointer"}
                  pointerEvents={"auto"}
                  onChange={handleFileUpload}
                />
            </Button>
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={onOpen}
            >
                <Image src={plus} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Thêm căn hộ
                </Text>
            </Button>
        </Flex>
        
      </Flex>
      <Box mt={{ base: "23px" }}><TableApartments tableData={tableData} /></Box>
      <FormNew
          isOpen={isOpen}
          onClose={onClose}
        />
    </Box>
  );
}
