import { Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => { 
  switch (key) {
    case "startTime":
      const timestamp = value;

      const date = new Date(timestamp);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return <Text>{formattedDate}</Text>;
    case "contact":
      return (
        <>
          <Text>{value.phone}</Text>
          <Text>{value.email}</Text>
        </>
        
      );
    case "identificationInfo":
      return (
        <>
          <Text>{value.dateOfIdentification}</Text>
          <Text>{value.placeOfIdentification}</Text>
        </>
        
      );
    default:
      return <Text>{value}</Text>;
  }
};