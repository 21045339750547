import React, { createContext, useContext, useState } from "react";

const PopupFormInfoContext = createContext();

export const ModalFormInfo = ({ children }) => {
  const [popupFormInfoContextVisible, setPopupFormInfoContextVisible] = useState(false);
  return (
    <PopupFormInfoContext.Provider
      value={{
        popupFormInfoContextVisible,
        setPopupFormInfoContextVisible,
      }}
    >
      {children}
    </PopupFormInfoContext.Provider>
  );
};

export const usePopupFormInfo = () => useContext(PopupFormInfoContext);