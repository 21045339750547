import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Skeleton, Table, TableContainer, Tbody, Td, Text, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import iconPlus from '../../../../assets/images/personnelCategories/plus.svg';
import iconEdit from "../../../../assets/images/employee/edit.svg";
import icontrash from "../../../../assets/images/product/trash.svg";
import { AiOutlineMore } from 'react-icons/ai';
import { formatTableValue } from './formatTableValue';
import DeleteDialog from "../../../../components/dialog/delete";
import FormCustom from './formCustom';
import toast from 'react-hot-toast';
import { jobTitleCall } from '../../../../api/jobTitle';
import { supportDepartmentCall } from '../../../../api/supportDepartment';
import { branchCall } from '../../../../api/branch';

const FormFeature = ({title, button, type, isLoading, refetch, isRefetching, formButton, data }) => {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState({});

    const onPopupIsEdit = (type, data)=>{ 
      setIsEdit(type);
      setIsDelete(false);
      type ? setDataEdit(data) : setDataEdit({});
      onOpen();
    }
    
    const onPopupIsDelete = (data) =>{
        setIsDelete(true);
        setDataEdit(data)
        onOpen();
    }

    const handleSubmitAdd = async(data) =>{ 
      const toastCreate = toast.loading("Đang thêm dữ liệu ...");
      try { 
        if(type===0){
          await jobTitleCall.create(data); 
          await refetch();
          onToggle();
          toast.success("Thêm thành công");
        }
        else if(type===1){
          await supportDepartmentCall.create(data); 
          await refetch();
          onToggle();
          toast.success("Thêm thành công");
        }
        else{
          await branchCall.create(data); 
          await refetch();
          onToggle();
          toast.success("Thêm thành công");
        }
      }
      catch (error) {
        console.error("Error:", error);
        toast.error("Có lỗi xảy ra khi thêm");
      }
      toast.dismiss(toastCreate);
    }

    const handleSubmitEdit = async(data) =>{ 
      const toastEdit = toast.loading("Đang sửa dữ liệu ...");
      const { id, ...dataEdit } = data;
      try { 
        if(type===0){
          await jobTitleCall.update(id, dataEdit); 
          await refetch();
          onToggle();
          toast.success("Sửa thành công");
        }
        else if(type===1){
          await supportDepartmentCall.update(id,dataEdit); 
          await refetch();
          onToggle();
          toast.success("Sửa thành công");
        }
        else{
          await branchCall.update(id,dataEdit); 
          await refetch();
          onToggle();
          toast.success("Sửa thành công");
        }
      }
      catch (error) {
        console.error("Error:", error);
        toast.error("Có lỗi xảy ra khi sửa");
      }
      toast.dismiss(toastEdit);
    }

    const handleSubmitDelete = async() =>{ 
      const toastEdit = toast.loading("Đang xóa dữ liệu ...");
      const { id } = dataEdit; 
      try { 
        if(type===0){
          await jobTitleCall.updateHiddenStatus(id); 
          await refetch();
          onToggle();
          toast.success("Xóa thành công");
        }
        else if(type===1){
          await supportDepartmentCall.updateHiddenStatus(id); 
          await refetch();
          onToggle();
          toast.success("Xóa thành công");
        }
        else{
          await branchCall.updateHiddenStatus(id); 
          await refetch();
          onToggle();
          toast.success("Xóa thành công");
        }
      }
      catch (error) {
        console.error("Error:", error);
        toast.error("Có lỗi xảy ra khi xóa");
      }
      toast.dismiss(toastEdit);
    }

  return (
    <Box>
        <Flex justifyContent={"space-between"} mb={"16px"}>
            <Text 
                fontSize={{ base: "16px" }}
                lineHeight={"16px"}
                fontWeight={600}
                color="var(--color-header-employee)"
            >{title}</Text>
            <Button
                display={"flex"}
                alignItems={"center"}
                p={"0px 12px"}
                gap={"10px"}
                borderRadius={"8px"}
                border={"1px solid var(--color-option-employee-hover)"}
                backgroundImage="var(--color-main)"
                bg={"var(--color-main)"}
                padding={"8px 12px"}
                h={"32px"}
                _hover={{
                    filter: "brightness(95%)",
                }}
                onClick={()=>onPopupIsEdit(false)}
            >
                    <Image src={iconPlus} />
                <Text
                    fontSize={{ base: "13px" }}
                    lineHeight={"16px"}
                    fontWeight={600}
                    color="var(--color-option-employee-hover)"
                >
                    {button}
                </Text>
            </Button>
        </Flex>
        <Skeleton isLoaded={!isLoading && !isRefetching}>
            <TableContainer width={"100%"} pb={"16px"}>
                  <Table w={"100%"} variant="unstyled">
                    <Thead
                      w={"100%"}
                      h={"41px"}
                      color="white"
                      fontWeight="400"
                      backgroundColor="var(--fill-avatar)"
                    >
                      <Tr h={{ base: "41px" }}>
                        {data.headers.map((e, index) => {
                          let width;
                          let textAlign;
                          if (e.key === "") {
                          width = "2%";
                          textAlign = "center";
                          } else {
                          width = "auto";
                          textAlign = "end";
                          }
                          return (
                            <Td
                              p={"16px 16px 8px 16px"}
                              key={index}
                              border={"none"}
                              color={"#51607B"}
                              fontSize={{ base: "14px" }}
                              w={width}
                            >
                              <Box textAlign={textAlign} >{e.label}</Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody w={"100%"} h={"100%"}>
                      {data.data?.length > 0 ? (
                        data.data?.map((e, rowIndex) => {
                          const items = { ...e };
                          delete items.id;
                          delete items.code;
                          const keyValues = Object.keys(items);
                          return (
                            <Tr
                              w={"100%"}
                              key={rowIndex}
                              h={"44px"}
                              backgroundColor={
                                rowIndex % 2 === 0
                                  ? "#transparent"
                                  : "var(--fill-avatar)"
                              }
                            >
                              <Td p={"16px 16px 8px 16px"} w={"2%"}>
                                <Menu>
                                  <MenuButton
                                    _hover={{ backgroundColor: "none" }}
                                    _active={{ backgroundColor: "none" }}
                                    background="none"
                                    as={Button}
                                  >
                                    <AiOutlineMore fontSize={"20px"} />
                                  </MenuButton>
                                  <MenuList
                                    fontSize={{ base: "14px" }}
                                    fontWeight={500}
                                    lineHeight={"140%"}
                                    color={"var(--color-info-employee)"}
                                >
                                    <MenuItem
                                      gap={"16px"}
                                      onClick={()=>onPopupIsEdit(true,e)}
                                    >
                                        <Image src={iconEdit} /> Chỉnh sửa
                                    </MenuItem>
                                    <MenuItem
                                        gap={"16px"}
                                        onClick={()=>onPopupIsDelete(e)}
                                    >
                                        <Image src={icontrash} /> Xóa
                                    </MenuItem>
                                </MenuList>
                                </Menu>
                              </Td>
                              <td style={{textAlign: "center"}}>{rowIndex+1}</td>
                              {keyValues.map((keyvalue, index) => { 
                                let width;
                                let whiteSpace="inherit";
                                let textAlign;
                                if (keyvalue === "") {
                                  width = "2%";
                                  textAlign= "center"
                                } else {
                                  width = "auto";
                                  whiteSpace = "inherit";
                                  textAlign = "end";
                                }
                                return (
                                  <Td
                                    p={"16px 16px 8px 16px"}
                                    w={width}
                                    key={index}
                                  >
                                    <Box
                                      fontSize={{ base: "14px" }}
                                      lineHeight={"19.6px"}
                                      fontWeight={500}
                                      color={"#293755"}
                                      w={width}
                                      whiteSpace={whiteSpace}
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      display="-webkit-box"
                                      sx={{
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}  
                                      textAlign={textAlign}
                                      
                                    >
                                      {formatTableValue(
                                        items[keyvalue],
                                        keyvalue
                                      )}
                                    </Box>
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td
                            colSpan={data.headers.length}
                            textAlign="center"
                            padding={"70px 0"}
                          >
                            Không có dữ liệu
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
          </TableContainer>
        </Skeleton>
        
        {
    !isDelete ? 
      isEdit ?
          <FormCustom
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            type={type}
            defaultValues={dataEdit}
            onSubmit={handleSubmitEdit}
          />
            :
            <FormCustom
                isOpen={isOpen}
                type={type}
                onClose={onClose}
                isEdit={false}
                onSubmit={handleSubmitAdd}
            />
        :
        <DeleteDialog 
          isOpen={isOpen}
          onClose={onClose}
          action={handleSubmitDelete}
          title={type===0 ? "Xóa chức danh" : type===1 ? "Xóa phòng ban" : "Xóa chi nhánh" }
          description= {<Flex flexWrap={"wrap"} display={"inline"}>"Bạn có chắc chắn muốn xóa {type===0 ? "chức danh" : type===1 ? "phòng ban" : "chi nhánh" } <Text fontWeight={"600"} display={"inline"}>{ dataEdit.name }</Text> này không?"</Flex>} 
        />
    }
    </Box>
  )
}

export default FormFeature