import { Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "dateTime":
      return <Text>{value}</Text>;
    case "checkinTime":
      return <Text>{value}</Text>;
    case "code":
      return <Text>{value}</Text>;
    case "fullName":
      return <Text>{value}</Text>;
    case "name":
      return <Text>{value}</Text>;
    case "workingDayRate":
      return <Text>{value}</Text>;
    case "block":
      return <Text>{value}</Text>;
    case "reason":
      return <Text>{value}</Text>;
    default:
      return <Text>{value}</Text>;
  }
};
