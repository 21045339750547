import React, { useState } from "react";
// Slider.jsx
import { Box, Button, Flex } from "@chakra-ui/react";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [1, 2, 3];

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box
      width="100%"
      h="307px"
      backgroundColor="var(--color-background)"
      position="relative"
      overflow="hidden"
      border="1px solid lightgray"
      borderRadius="8px"
      mx="auto"
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        fontSize="24px"
        fontWeight="bold"
        position="absolute"
        top="10px"
        left="10px"
        padding="10px"
        borderRadius="8px"
      >
        {slides[currentSlide]}
      </Box>

      <Flex
        gap={"7px"}
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
      >
        {slides.map((_, index) => (
          <Button
            borderRadius={"4px"}
            w={"56px"}
            h={"37px"}
            key={index}
            bg={currentSlide === index ? "gray.600" : "gray.400"}
            onClick={() => handleSlideChange(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Slider;
