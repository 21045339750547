import React, { memo } from "react";
import {
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogHeader,
} from "@chakra-ui/react";

const DeleteDialog = memo(({ isOpen, onClose, title, description, action, typeLogout }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxW={"500px"} padding={"24px"}>
            <AlertDialogHeader p={0} mb={"16px"} fontSize={typeLogout ? "24px" : "28px"} fontWeight="600" lineHeight="28px">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody p={0} maxW={"424px"} fontSize={"14px"} lineHeight={"19.6px"} fontWeight={400} mb={"32px"}>{description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button 
                w={"112px"} h={"44px"} borderRadius={"8px"} 
                fontSize={"15px"}
                color={"var(--color-main)"}
                fontWeight={"600"}
                bg={"var(--linear-gradient-employee)"} 
                ref={cancelRef} onClick={onClose}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Hủy
              </Button>
              <Button 
                w={"112px"} h={"44px"} borderRadius={"8px"} 
                fontSize={"15px"}
                color={"var(--color-main)"}
                fontWeight={"600"}
                colorScheme="red" onClick={action} ml={4}
                bg={"var(--linear-gradient-red)"}  
                _hover={{
                    filter: "brightness(90%)",
                }}
              >
                {typeLogout ? "Đăng xuất" : "Xóa"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
});

export default DeleteDialog;
