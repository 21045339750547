import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import calendarIcon from "../../assets/images/onleave/calendar.svg";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; 
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../assets/images/product/edit.svg";
import iconDetail from "../../assets/images/employee/detail.svg";
import icontrash from "../../assets/images/product/trash.svg";
import DeleteDialog from "../../components/dialog/delete";
import FormDetail from "./formOnLeave/formDetail";
import FormEdit from "./formOnLeave/formEdit";
import { useOnLeave } from "../../hooks/useOnLeave";
import useDebounce from "../../hooks/useDebounce";
import { customDateFormatType2 } from "../../utils/utils";
import toast from "react-hot-toast";
import { leaveRequestCall } from "../../api/leaveRequest";

const TableOnLeave = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const {
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  // table
  const [search, setSearch] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => { 
    const { value } = e.target;
    setSearch(value);
  }, []);

  const {
    onLeaveData,
    totalPages,
    setCurrentPage,
    refetch,
    isLoading,
    isRefetching,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useOnLeave(debouncedSearchTerm,startDate,endDate);

  useEffect(() => {
    setSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "Thời gian tạo",
        key: "requestDate",
      },
      {
        label: "Mã nhân viên",
        key: "codeEmployee",
      },
      {
        label: "Tên nhân viên",
        key: "employee",
      },
      {
        label: "Ngày bắt đầu nghỉ",
        key: "startDate",
      },
      {
        label: "Số ngày nghỉ",
        key: "daysRequested",
      },
      {
        label: "Phân loại",
        key: "leaveType",
      },
      {
        label: "Lí do nghỉ",
        key: "reason",
      },
      {
        label: "Người duyệt",
        key: "approverName",
      },
      {
        label: "Ngày duyệt",
        key: "approvedDate",
      },
    ],
    data: onLeaveData,
  };

  const handleEdit = async (data) => {
    const dataFormat = {
      requestDate: data.requestDate,
      daysRequested: data.daysRequested,
      leaveTypeCode: data.leaveType,
      reason: data.reason,
      startDate: customDateFormatType2(data.startDate),
    }
    const toastEdit = toast.loading("Đang sửa dữ liệu ...");
    try { 
      await leaveRequestCall.update(data.id,dataFormat); 
      await refetch();
      onToggle();
      toast.success("Sửa thành công");
    }
    catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }
    toast.dismiss(toastEdit);
  }

  const handleDelete = async() =>{ 
    const toastEdit = toast.loading("Đang xóa dữ liệu ...");
    const id = dataEdit.edit.id; 
    try { 
      await leaveRequestCall.delete(id); 
      await refetch();
      onToggle();
      toast.success("Xóa thành công");
    }
    catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi xóa");
    }
    toast.dismiss(toastEdit);
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const onPopupIsEdit = (type, data)=>{ 
    setIsEdit(type);
    setIsDelete(false);
    if(type){ 
      setDataEdit(data.edit)
    }else{
       setDataEdit(data)
    }
   
    onOpen();
  }

  const onPopupIsDelete = (data) =>{
    setDataEdit(data)
    setIsDelete(true);
    onOpen();
  }

  return (
    <>
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          w={{ base: "90%" }}
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup
            maxW="506px"
            borderRadius={{ base: "8px" }}
            w={{ base: "40%" }}
          >
            <Input
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
              onChange={onChangeSearch}
            />
            <InputRightElement
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }}>
            <InputGroup>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Từ ngày - Đến ngày"
                className="datePickerStyle"
              />
              {(!startDate && !endDate) && <InputRightElement pointerEvents="none" h={"44px"}>
                <Image src={calendarIcon} />
              </InputRightElement>}
            </InputGroup>
          </Flex>
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <Skeleton isLoaded={!isLoading && !isRefetching}>
            <TableContainer w={"100%"}>
              <Table w={"100%"} variant="unstyled">
                <Thead
                  w={"100%"}
                  h={"41px"}
                  color="white"
                  fontWeight="400"
                  backgroundColor="#F9FAFD"
                >
                  <Tr h={{ base: "41px" }}>
                    {tableData.headers.map((e, index) => {
                      let width = "auto";
                      let textAlign = "auto";
                      if (e.key === "") {
                        width = "2%";
                        textAlign = "center";
                      } else if (e.key === "requestDate") {
                        width = "5%";
                        textAlign = "start";
                      } else if (e.key === "employee") {
                        width = "15%";
                        textAlign = "start";
                      } else if (e.key  === "codeEmployee" || e.key  === "startDate" || e.key  === "leaveType") {
                        width = "10%";
                        textAlign = "start";
                      } else if (e.key  === "reason") {
                        width = "15%";
                        textAlign = "start";
                      }  else if (e.key  === "daysRequested") {
                        width = "10%";
                        textAlign = "center";
                      }  else if (e.key === "reviewer") {
                        width = "40%";
                        textAlign = "end";
                      }else {
                        width = "15%";
                        textAlign = "end";
                      }
                      return (
                        <Td
                          p={"16px 16px 8px 16px"}
                          key={index}
                          border={"none"}
                          color={"#51607B"}
                          fontSize={{ base: "14px" }}
                          w={width}
                        >
                          <Box textAlign={textAlign}>{e.label}</Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody w={"100%"} h={"100%"}>
                  {tableData.data?.length > 0 ? (
                    tableData.data?.map((e, rowIndex) => {
                      const items = { ...e };
                      delete items.edit;
                      const keyValues = Object.keys(items);
                      return (
                        <Tr key={rowIndex} h={"72px"}>
                          <Td p={"16px 0px 8px 0px"} w={"2%"} bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"}>
                              <Menu>
                                <MenuButton
                                    _hover={{ backgroundColor: "none" }}
                                    _active={{ backgroundColor: "none" }}
                                    background="none"
                                    as={Button}
                                >
                                    <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList
                                    fontSize={{ base: "14px" }}
                                    fontWeight={500}
                                    lineHeight={"140%"}
                                    color={"var(--color-info-employee)"}
                                >
                                    <MenuItem
                                        gap={"16px"}
                                        onClick={()=>onPopupIsEdit(false,e)}
                                    >
                                        <Image src={iconDetail} /> Xem chi tiết
                                    </MenuItem>
                                    <MenuItem
                                      gap={"16px"}
                                      onClick={()=>onPopupIsEdit(true,e)}
                                    >
                                        <Image src={iconEdit} /> Chỉnh sửa
                                    </MenuItem>
                                    <MenuItem
                                        gap={"16px"}
                                        onClick={()=>onPopupIsDelete(e)}
                                    >
                                        <Image src={icontrash} /> Xóa
                                    </MenuItem>
                                </MenuList>
                              </Menu>
                          </Td>
                          {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let widthText = "100%";
                            let textAlign = "auto";
                            let whiteSpace = "inherit";
                            if (keyvalue === "") {
                              width = "2%";
                              textAlign = "center";
                            } else if (keyvalue === "requestDate") {
                              width = "5%";
                              textAlign = "start";
                            } else if (keyvalue === "employee") {
                              width = "15%";
                              textAlign = "start";
                            } else if (keyvalue === "codeEmployee" || keyvalue === "startDate" || keyvalue === "leaveType") {
                              width = "10%";
                              textAlign = "start";
                            } else if (keyvalue === "reason") {
                              width = "15%";
                              textAlign = "start";
                              whiteSpace = "normal";
                            } else if (keyvalue === "daysRequested") {
                              width = "10%";
                              textAlign = "center";
                            } else if (keyvalue === "reviewer") {
                              width = "40%";
                              textAlign = "end";
                            }else {
                              width = "15%";
                              textAlign = "end";
                            }
                            return (
                              <Td 
                                p={"16px 16px 8px 16px"} 
                                w={width} key={index} 
                                bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"} 
                              >
                                <Box
                                  fontSize={{ base: "14px" }}
                                  lineHeight={"19.6px"}
                                  textAlign={textAlign}
                                  fontWeight={500}
                                  color={"#293755"}
                                  w={widthText}
                                  whiteSpace={whiteSpace}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  display="-webkit-box"
                                  sx={{
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}  
                                >
                                  {formatTableValue(e[keyvalue], keyvalue)}
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        colSpan={tableData.headers.length}
                        textAlign="center"
                        padding={"40px 0"}
                      >
                        Không có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Skeleton>
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text
                fontSize={"14px"}
                fontWeight={500}
                lineHeight={"20px"}
                color={"var(--text-color-Subtittle)"}
              >
                Showing {startEntry} to {endEntry} of {entries} entries
              </Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />}
                  nextLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />}
                  renderOnZeroPageCount={null}
                  initialPage={currentPage - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
    {
    !isDelete ? 
      isEdit ?
          <FormEdit
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            defaultValues={dataEdit}
            onSubmit={handleEdit}
          />
            :
            <FormDetail
                isOpen={isOpen}
                onClose={onClose}
                isEdit={true}
                dataDetail={dataEdit}
            />
        :
        <DeleteDialog 
          isOpen={isOpen}
          onClose={onClose}
          action={handleDelete}
          title="Xóa đơn xin nghỉ"
          description= {<Flex flexWrap={"wrap"} display={"inline"}>"Bạn có chắc chắn muốn xóa đơn xin nghỉ phép của <Text fontWeight={"600"} display={"inline"}>Nguyễn Thị Ngọc Anh</Text> này không?"</Flex>} 
        />
    }
    </>
    
  );
};
export default TableOnLeave;