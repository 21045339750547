import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import iconClose from "../../assets/images/icon-login.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import * as Yup from "yup";

const schema = Yup.object().shape({
  password: Yup.string().required("Mật khẩu là bắt buộc"),
});

const PopupChangePassword = ({ isOpen, onClose, defaultValues, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues ? defaultValues : {},
  });

  const passwordValue = watch("password");
  const submitHandler = (data) => {
    const submissionData = {
      ...data,
      id: defaultValues?.id,
    };
    onSubmit(submissionData);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"625px"}>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"25px 24px 24px 24px"}
          borderBottom={"1px solid var(--color-boder)"}
        >
          <Text
            fontSize={"18px"}
            fontWeight={600}
            lineHeight={"normal"}
            color="var(--color-black)"
          >
            Đổi mật khẩu
          </Text>
          <Image src={iconClose} cursor={"pointer"} onClick={onClose} />
        </Flex>
        <form onSubmit={handleSubmit(submitHandler)}>
          <FormControl gap={"8px"} p={"24px 40px"}>
            <FormLabel
              fontSize={{ base: "12px" }}
              fontWeight={400}
              color="var(--color-black-label)"
              m={"0px"}
            >
              Mật khẩu
            </FormLabel>
            <InputGroup display={"flex"} flexDirection={"column"}>
              <Input
                borderRadius={"0px"}
                fontSize={{ base: "14px" }}
                fontWeight={500}
                lineHeight={"normal"}
                type={showPassword ? "text" : "password"}
                placeholder={"Nhập mật khẩu"}
                border={"none"}
                _active={{
                  boxShadow: "none",
                  border: "none",
                  zIndex: "0",
                  borderBottom: "1px solid var(--color-boder)",
                }}
                _focus={{
                  boxShadow: "none",
                  border: "none",
                  zIndex: "0",
                  borderBottom: "1px solid var(--color-boder)",
                }}
                _placeholder={{ color: "var(--text-gray-form)" }}
                p={"0px"}
                borderBottom={"1px solid var(--color-boder)"}
                onChange={(e) => setValue("password", e.target.value)}
                {...register("password")}
              />
              {passwordValue && (
                <InputRightElement>
                  <IconButton
                    _hover={{
                      bg: "var(--color-main)",
                    }}
                    backgroundColor={"none"}
                    icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    onClick={() => setShowPassword(!showPassword)}
                    variant="ghost"
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                  />
                </InputRightElement>
              )}
              {errors.password && (
                <Text fontSize={{ base: "12px" }} color={"red"} mt={"8px"}>
                  {errors.password.message}
                </Text>
              )}
            </InputGroup>
          </FormControl>
          <Flex justifyContent={"flex-end"} p={"0px 40px 24px 40px"}>
            <Button
              type="submit"
              borderRadius={"8px"}
              background={"var(--linear-gradient-employee)"}
              color={"var(--color-main)"}
              w={"164px"}
              h={"44px"}
              _hover={{
                filter: "brightness(90%)",
              }}
              _active={{
                filter: "brightness(90%)",
              }}
            >
              Đổi mật khẩu
            </Button>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PopupChangePassword;
