import { Flex, Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "identificationNumberInfo":
      const identificationNumberInfo = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {identificationNumberInfo?.length &&
            identificationNumberInfo?.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))}
        </Flex>
      );
    default:
      return <Text>{value}</Text>;
  }
};