import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { TabProvider } from "./contexts/useTab";
import { ModalProvider } from "./contexts/usePopupForm";
import { ModalFormInfo } from "./contexts/usePopupFormInfo";
import { ModalJobTitleContext } from "./contexts/usePopupJobTitle";
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ModalJobTitleContext>
                  <ModalFormInfo>
                    <ModalProvider>
                      <TabProvider>
                        <App />
                        <Toaster position="bottom-right" reverseOrder={true} />
                      </TabProvider>
                    </ModalProvider>
                  </ModalFormInfo>
                </ModalJobTitleContext>
            </BrowserRouter>
          </QueryClientProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
