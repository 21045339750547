import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { clientAPI } from "../api/client";

const NUMBER_PER_PAGE = 5;
const queryKey = "employeeGroupRoles";

async function fetchEmployee({ currentPage, search }) {
  try {
    const options = {
      limit: NUMBER_PER_PAGE,
      page: currentPage,
    };
    if (search !== undefined) options.search = search;
    const queryParams = new URLSearchParams(options).toString();

    // Fetch employee data
    let { items, pagination } = await clientAPI(
      "get",
      `/userGroupRoleLink?${queryParams}`
    );

    // Transform the employee data
    let newData = items?.map((item, index) => {
      return {
        // num: index + 1 + NUMBER_PER_PAGE * (currentPage - 1),
        code: item?.employeeObject?.code || "-",
        fullName: item?.employeeObject?.fullName || "-",
        group_role_code: item?.group_role_name || "-",
        edit: {
          employeeObject: {
            label: item?.employeeObject?.fullName,
            value: item?.user_id,
          },
          group_role_code: item?.group_role_code || "",
        },
        delete: {
          user_id: item?.user_id,
          fullName: item?.employeeObject?.fullName,
        },
      };
    });

    const totalpages = Math.ceil(pagination?.total / NUMBER_PER_PAGE);
    const entries = pagination?.total;
    const startEntry = (pagination?.page - 1) * pagination?.limit + 1;
    const endEntry = Math.min(pagination?.page * pagination?.limit, entries);
    return { data: newData, totalpages, entries, startEntry, endEntry };
  } catch (error) {
    console.log("error", error);
    return { data: [], totalpages: 0, entries: 0, startEntry: 0, endEntry: 0 };
  }
}

export function useEmployeeGroupRoles(search) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search],
    queryFn: () =>
      fetchEmployee({
        currentPage,
        search,
      }),
    refetchOnWindowFocus: false,
  });

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    if (data?.totalpages && currentPage > data.totalpages) {
      setCurrentPage(data.totalpages);
    }
  }, [currentPage, data?.totalpages]);

  return {
    employeeData: data?.data,
    totalPages: data?.totalpages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
