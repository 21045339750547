import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import iconAdd from "../../assets/images/projectManagement/icon-add-project.svg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineMore } from "react-icons/ai";
import iconDetail from "../../assets/images/projectManagement/icon-detail.svg";
import iconEdit from "../../assets/images/projectManagement/icon-edit.svg";
import iconUnlock from "../../assets/images/projectManagement/icon-unlock.svg";
import image from "../../assets/images/projectManagement/image.png";
import { formatTableValue } from "./formatTable";
import PopupProjectManagementAdd from "../../components/popup/popupProjectManagementAdd";
import PopupProjectManagementTitle from "../../components/popup/popupProjectManagementTitle";
const ProjectManagement = () => {
  const {
    isOpen: isParentOpen,
    onOpen: onOpenParent,
    onClose: onCloseParent,
  } = useDisclosure();
  const {
    isOpen: isOpenTitle,
    onOpen: onOpenTitle,
    onClose: onCloseTitle,
  } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "Mã dự án",
        key: "id",
      },
      {
        label: "Ảnh",
        key: "image",
      },
      {
        label: "Dự án",
        key: "nameproject",
      },
      {
        label: "Chủ đầu tư",
        key: "owner",
      },
      {
        label: "Phân loại",
        key: "classify",
      },
      {
        label: "Địa chỉ",
        key: "address",
      },
      {
        label: "Thành phố",
        key: "city",
      },
      {
        label: "Tài liệu",
        key: "file",
      },
    ],
    data: [
      {
        id: "08/2024",
        image: "",
        nameproject: "THE DIAMOND RESIDENCE",
        owner: "Tổng Công ty Đầu tư Phát triển Nhà và Đô thị HUD",
        classify: "Tên phân loại",
        address: "25 Lê Văn Lương, Đống Đa, Hà Nội",
        city: "Hà Nội",
        file: "",
      },
      {
        id: "08/2024",
        image: image,
        nameproject: "THE DIAMOND RESIDENCE",
        owner: "Tổng Công ty Đầu tư Phát triển Nhà và Đô thị HUD",
        classify: "Tên phân loại",
        address: "25 Lê Văn Lương, Đống Đa, Hà Nội",
        city: "Hà Nội",
        file: "",
      },
      {
        id: "08/2024",
        image: "",
        nameproject: {
          name: "THE DIAMOND RESIDENCE",
          des: "Mô tả dự án được hiển thị tối đa là 2 dòng hết 2 dòng thì sẽ hiển thị 2 dòng hết 2 dòng thì sẽ hiển thị",
        },
        owner: "Tổng Công ty Đầu tư Phát triển Nhà và Đô thị HUD",
        classify: "Tên phân loại",
        address: "25 Lê Văn Lương, Đống Đa, Hà Nội",
        city: "Hà Nội",
        file: "",
      },
      {
        id: "08/2024",
        image: image,
        nameproject: {
          name: "THE DIAMOND RESIDENCE",
          des: "Mô tả dự án được hiển thị tối đa là 2 dòng hết 2 dòng thì sẽ hiển thị 2 dòng hết 2 dòng thì sẽ hiển thị",
        },
        owner: "Tổng Công ty Đầu tư Phát triển Nhà và Đô thị HUD",
        classify: "Tên phân loại",
        address: "25 Lê Văn Lương, Đống Đa, Hà Nội",
        city: "Hà Nội",
        file: "",
      },
    ],
  };
  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý dự án
          </Text>
        </Flex>

        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              backgroundImage: "var(--color-button)",
            }}
            onClick={() => onOpenParent()}
          >
            <Image src={iconAdd} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Thêm dự án
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <Flex flexDirection={"column"} gap={{ base: "16px" }}>
          <Flex
            minHeight="calc(100vh - 167px)"
            backgroundColor="var(--color-main)"
            borderRadius={{ base: "12px" }}
            flexDirection={"column"}
          >
            <Flex
              alignItems={"center"}
              gap={{ base: "32px" }}
              p={"29px 0px 40px 16px"}
            >
              <Text
                fontSize={{ base: "16px" }}
                fontWeight={600}
                lineHeight={"normal"}
                color="var(--color-black)"
              >
                Danh sách dự án
              </Text>
            </Flex>
            <Flex
              flexDirection={"column"}
              justifyContent={"space-between"}
              width={"100%"}
              minH="calc(100vh - 235px)"
            >
              <TableContainer w={"100%"} pb={"32px"}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    borderBottom={"1px solid var(--color-boder)"}
                  >
                    <Tr h={{ base: "41px" }}>
                      {historyTableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "3%";
                          textAlign = "center";
                        } else if (e.key === "id") {
                          width = "5%";
                          textAlign = "center";
                        } else if (e.key === "image") {
                          width = "15%";
                          textAlign = "start";
                        } else if (e.key === "nameproject") {
                          width = "15%";
                          textAlign = "start";
                        } else if (e.key === "owner") {
                          width = "15%";
                          textAlign = "start";
                        } else if (e.key === "classify") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "address") {
                          width = "20%";
                          textAlign = "end";
                        } else {
                          width = "10%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 12px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {historyTableData.data?.length > 0 ? (
                      historyTableData.data?.map((e, rowIndex) => {
                        const keyValues = Object.keys(e);
                        return (
                          <Tr
                            w={"100%"}
                            key={rowIndex}
                            h={"86px"}
                            backgroundColor={
                              rowIndex % 2 === 0
                                ? "#transparent"
                                : "var(--fill-avatar)"
                            }
                          >
                            <Td p={"16px 16px 8px 16px"} verticalAlign={"top"}>
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                  alignItems={"unset"}
                                >
                                  <AiOutlineMore fontSize={"20px"} />
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    alignItems={"center"}
                                    display={"flex"}
                                    gap={"16px"}
                                    onClick={() => onOpenTitle()}
                                  >
                                    <Image src={iconDetail} />
                                    <Text
                                      fontSize={{ base: "14px" }}
                                      fontWeight={500}
                                      lineHeight={"140%"}
                                      color="var(--color-option-employee)"
                                    >
                                      Xem chi tiết
                                    </Text>
                                  </MenuItem>
                                  <MenuItem
                                    alignItems={"center"}
                                    display={"flex"}
                                    gap={"16px"}
                                    onClick={onOpenParent}
                                  >
                                    <Image src={iconEdit} />
                                    <Text
                                      fontSize={{ base: "14px" }}
                                      fontWeight={500}
                                      lineHeight={"140%"}
                                      color="var(--color-option-employee)"
                                    >
                                      Chỉnh sửa
                                    </Text>
                                  </MenuItem>
                                  <MenuItem
                                    alignItems={"center"}
                                    display={"flex"}
                                    gap={"16px"}
                                  >
                                    <Image src={iconUnlock} />
                                    <Text
                                      fontSize={{ base: "14px" }}
                                      fontWeight={500}
                                      lineHeight={"140%"}
                                      color="var(--color-option-employee)"
                                    >
                                      Đóng dự án
                                    </Text>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "3%";
                                textAlign = "center";
                              } else if (keyvalue === "id") {
                                width = "5%";
                                textAlign = "center";
                              } else if (keyvalue === "image") {
                                width = "15%";
                                textAlign = "start";
                              } else if (keyvalue === "nameproject") {
                                width = "15%";
                                textAlign = "start";
                                whiteSpace = "normal";
                                widthText = "227px";
                              } else if (keyvalue === "owner") {
                                width = "15%";
                                textAlign = "start";
                                whiteSpace = "normal";
                                widthText = "261px";
                              } else if (keyvalue === "classify") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "address") {
                                width = "20%";
                                textAlign = "end";
                              } else {
                                width = "10%";
                                textAlign = "end";
                              }
                              return (
                                <Td p={"16px 16px 8px 16px"} w={width} key={index} 
                                  bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"} 
                                    verticalAlign={"top"}>
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"#293755"}
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "3",
                                      WebkitBoxOrient: "vertical",
                                    }}  
                                  >
                                    {formatTableValue(e[keyvalue], keyvalue)}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={historyTableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              <Flex
                m={"50px 16px 16px 16px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"20px"}
                  color={"var(--text-color-Subtittle)"}
                >
                  Showing 1 to 10 of {historyTableData.headers.length} entries
                </Text>
                <HStack gap={"6px"} justify="flex-end">
                  <Button
                    p={"8px"}
                    borderRadius={"4px"}
                    isDisabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    border={"0.5px solid var(--Line)"}
                    backgroundColor={"#FFF"}
                  >
                    <IoIosArrowBack />
                  </Button>

                  {[1, 2, 3].map((page) => (
                    <Button
                      p={"8px"}
                      border={"0.5px solid var(--Line)"}
                      key={page}
                      onClick={() => handlePageChange(page)}
                      bg={
                        currentPage === page
                          ? "var(--color-secondary)"
                          : "transparent"
                      }
                      color={currentPage === page ? "white" : "gray.500"}
                      _hover={{
                        bg:
                          currentPage === page
                            ? "var(--color-secondary)"
                            : "transparent",
                      }}
                    >
                      {page}
                    </Button>
                  ))}

                  <Button
                    p={"8px"}
                    borderRadius={"4px"}
                    border={"0.5px solid var(--Line)"}
                    backgroundColor={"#FFF"}
                    isDisabled={currentPage === 3}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <IoIosArrowForward />
                  </Button>
                </HStack>
              </Flex>
            </Flex>
          </Flex>
          <PopupProjectManagementAdd
            isOpen={isParentOpen}
            onClose={onCloseParent}
          />
          <PopupProjectManagementTitle
            isOpen={isOpenTitle}
            onClose={onCloseTitle}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default ProjectManagement;
