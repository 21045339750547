export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "8px",
        backgroundColor: "var(--color-background)",
      },
    },
  },
};
