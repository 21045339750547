import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import DefaultLayout from "./layouts";
import { routes } from "./routes";
import { Fragment, useCallback, useEffect } from "react";
import SignIn from "./pages/signIn";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];
  
  useEffect(() => {
    if (!user?.accessToken) {
      navigate("/login");
    }
  }, [dispatch, navigate, user]);

  const hasPermission = useCallback(
    (routeRoles) => {
      if (!routeRoles || routeRoles.length === 0 || roles?.includes("ALL")) {
        return true;
      }
      return routeRoles.some((role) => roles?.includes(role));
    },
    [roles]
  );

  const filteredRoutes = routes.filter((route) => hasPermission(route.role));

  return (
    <Routes>
      {user?.accessToken ? (
        filteredRoutes.map((route, index) => {
          const Layout = route.isShowHeader ? DefaultLayout : Fragment;
          const Page = route.page;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
          );
        })
      ) : (
        <Route path="*" element={<SignIn />} />
      )}
    </Routes>
  );
}

export default App;
