import React, { useRef, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
// Đường dẫn icon upload
import iconImage from "../../assets/images/projectManagement/icon-image.svg";

const UploadImage = ({ title }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex flexDirection={"column"} gap={"8px"}>
      <Text fontSize="12px" fontWeight={400} color="var(--color-black-label)">
        {title}
      </Text>
      <Box
        position="relative"
        w="259px"
        h="146px"
        borderRadius="8px"
        bg="var(--color-background)"
        overflow="hidden"
      >
        {imagePreview ? (
          <Image
            src={imagePreview}
            alt="Selected"
            w="259px"
            h="146px"
            objectFit="contain"
          />
        ) : null}
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          p={"8px"}
          w={"32px"}
          h={"32px"}
          position="absolute"
          bottom="12px"
          right="12px"
          borderRadius={"50%"}
          border={"2px solid var(--color-main)"}
          onClick={handleIconClick}
          cursor="pointer"
        >
          <Image boxSize={"16px"} src={iconImage} />
        </Flex>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </Box>
    </Flex>
  );
};

export default UploadImage;
