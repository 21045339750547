import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { InputControl } from "../../../../components/form/formControls";
  
const schemaType1 = Yup.object().shape({
    name: Yup.string()
        .required("Vui lòng nhập tên chức danh"),
    code: Yup.string()
        .required("Vui lòng nhập mã chức danh"),
});

const schemaType2 = Yup.object().shape({
    name: Yup.string()
        .required("Vui lòng nhập tên phòng ban"),
    code: Yup.string()
        .required("Vui lòng nhập mã phòng ban"),
});

const schemaType3 = Yup.object().shape({
    name: Yup.string()
        .required("Vui lòng nhập tên chi nhánh"),
    code: Yup.string()
        .required("Vui lòng nhập mã chi nhánh"),
});

const FormCustom = ({ isOpen, onClose,  type, data,defaultValues, onSubmit, isEdit }) => {
    const [focusStates, setFocusStates] = useState({});

    let schema = null;
    if(type===0){
        schema = schemaType1;
    }else if(type===1){
        schema = schemaType2;
    } else{
        schema = schemaType3;
    }

    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues || {},
    });

    useEffect(() => {
        if (isOpen) {
          if (isEdit) {
            reset(defaultValues);
          } else {
            reset({
              code: '',
              name: ''
            });
          }
        }
    }, [isOpen, isEdit, defaultValues, reset]);

    const submitHandler = (data) => { 
        onSubmit(data);
    };

    const handleFocus = (field) => {
        setFocusStates((prev) => ({ ...prev, [field]: true }));
    };

    const handleBlur = (field) => {
        setFocusStates((prev) => ({ ...prev, [field]: false }));
    };

  return (
    <Modal isOpen={isOpen} onClose={onClose} >
    <ModalOverlay />
    <ModalContent
      backgroundColor="var(--color-main)"
      borderRadius={"16px"}
      h={"max-content"}
      w={"100%"}
      maxW={"625px"}
      overflowY={"auto"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          width: "4px",
          borderRadius: "50px",
          background: "var(--color-background)",
        },
        "&::-moz-scrollbar": {
          width: "4px",
        },
        "&::-moz-scrollbar-thumb": {
          width: "4px",
          borderRadius: "50px",
          background: "var(--color-background)",
        },
      }}
    >
         <form onSubmit={handleSubmit(submitHandler)}>
            <Flex flexDirection={"column"}>
                <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"10px"}
                    padding={"25px 24px"}
                    borderBottom={"1px solid var(--bg-line-employee)"}
                >
                <Text
                    color="var(--color-header-employee)"
                    fontSize={"18px"}
                    fontWeight={"600"}
                >
                    {
                        isEdit ?
                        type===0 ? "Sửa chức danh" : type===1 ? "Sửa phòng ban" : "Sửa chi nhánh" 
                        : 
                        type===0 ? "Chức danh mới" : type===1 ? "Phòng ban mới" : "Thêm chi nhánh" 
                    }
                </Text>
                <Box cursor={"pointer"} onClick={onClose}>
                    <Image src={CloseIconBlack} />
                </Box>
                </Flex>
                <Box 
                    flex={1}
                >
                    <Box
                        m={"24px auto 0"}
                        w={"545px"}
                        borderRadius={"8px"}
                        overflow={"hidden"}
                    >   
                    {
                            type===0
                            ?
                            <Flex
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                                gap={"27px"}
                            >
                                <InputControl
                                    label="Mã chức danh"
                                    placeholder="Nhập mã chức danh"
                                    errors={ errors.code}
                                    register={register("code")}
                                    onFocus={() => handleFocus("code")}
                                    onBlur={() => handleBlur("code")}
                                    state={focusStates["code"]}
                                    mwidth="100%"
                                />
                                <InputControl
                                    label="Tên chức danh"
                                    placeholder="Nhập tên chức danh"
                                    errors={ errors.name}
                                    register={register("name")}
                                    onFocus={() => handleFocus("name")}
                                    onBlur={() => handleBlur("name")}
                                    state={focusStates["name"]}
                                    mwidth="100%"
                                />
                            </Flex>
                            :
                                type===1
                                ?
                                <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"flex-start"}
                                    gap={"27px"}
                                >
                                    <InputControl
                                        label="Mã phòng ban"
                                        placeholder="Nhập mã phòng ban"
                                        errors={ errors.code}
                                        register={register("code")}
                                        onFocus={() => handleFocus("code")}
                                        onBlur={() => handleBlur("code")}
                                        state={focusStates["code"]}
                                        mwidth="100%"
                                    />
                                    <InputControl
                                        label="Tên phòng ban"
                                        placeholder="Nhập tên phòng ban"
                                        errors={ errors.name}
                                        register={register("name")}
                                        onFocus={() => handleFocus("name")}
                                        onBlur={() => handleBlur("name")}
                                        state={focusStates["name"]}
                                        mwidth="100%"
                                    />
                                </Flex>
                                :
                                <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"flex-start"}
                                    gap={"27px"}
                                >
                                    <InputControl
                                        label="Mã chi nhánh"
                                        placeholder="Nhập mã chi nhánh"
                                        errors={ errors.code}
                                        register={register("code")}
                                        onFocus={() => handleFocus("code")}
                                        onBlur={() => handleBlur("code")}
                                        state={focusStates["code"]}
                                        mwidth="100%"
                                    />
                                    <InputControl
                                        label="Tên chi nhánh"
                                        placeholder="Nhập tên chi nhánh"
                                        errors={ errors.name}
                                        register={register("name")}
                                        onFocus={() => handleFocus("name")}
                                        onBlur={() => handleBlur("name")}
                                        state={focusStates["name"]}
                                        mwidth="100%"
                                    />
                                </Flex>

                        }
                    </Box>
                </Box>
                <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
                    {
                        isEdit ?
                        <>
                            <Button 
                                w={"112px"} 
                                h={"44px"} 
                                lineHeight={"18px"} 
                                fontSize={"15px"}
                                fontWeight={600}
                                color={"var(--color-option-employee-hover)"} 
                                border={"1px solid var(--color-option-employee-hover)"}
                                bg={"var(--color-main)"}  
                                onClick={onClose}
                                _hover={{
                                    filter: "brightness(90%)",
                                }}
                            >
                                Hủy
                            </Button>
                            <Button 
                                type="submit"
                                w={"112px"} 
                                h={"44px"} 
                                lineHeight={"18px"} 
                                fontSize={"15px"}
                                fontWeight={600}
                                color={"var(--color-main)"} 
                                bg={"var(--linear-gradient-employee)"}  
                                _hover={{
                                    filter: "brightness(90%)",
                                }}
                            >
                                Lưu
                            </Button>
                        </>
                        :
                            <Button 
                                type="submit"
                                p={"13px 20px"}
                                lineHeight={"18px"} 
                                fontSize={"15px"}
                                fontWeight={600}
                                h={"44px"} 
                                color={"var(--color-main)"} 
                                bg={"var(--linear-gradient-employee)"}  
                                _hover={{
                                    filter: "brightness(90%)",
                                }}
                            >
                                {type===0 ? "Tạo chức danh" : type===1 ? "Tạo phòng ban" : "Tạo chi nhánh"}
                            </Button>
                    }
                    
                </Flex>
            </Flex>
        </form>
    </ModalContent>
  </Modal>
  )
}

export default FormCustom