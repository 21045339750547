import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import iconClose from "../../assets/images/icon-login.png";
import { useState, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useCheckInHistorys } from "../../hooks/useCheckinHistorys";

const PopupTimeKeepingHistory = ({ isOpen, onClose, data, isLoading, setIsLoading }) => {
  const { checkInHistorysData, isLoading: apiLoading, isRefetching } = useCheckInHistorys(data?.documentId);

  useEffect(() => {
    if (!apiLoading && !isRefetching) {
      setIsLoading(false);
    }
  }, [apiLoading, isRefetching]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"625px"}>
        <Flex
          p={{ base: "25px 24px 24px 24px" }}
          pb={{ base: "24px" }}
          alignContent={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px solid var(--color-boder)"}
        >
          <Text
            fontSize={{ base: "18px" }}
            fontWeight={600}
            color="#000"
            lineHeight={"normal"}
          >
            Lịch sử chỉnh sửa
          </Text>
          <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
        </Flex>
        <Flex
          p={{ base: "24px" }}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={{ base: "24px" }}
        >
          <Text
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"normal"}
            color="var(--color-header-employee)"
          >
            {data?.code}-{data?.fullName}
          </Text>
          <Box p={{ base: "0px 16px 40px 16px" }}>
            <Skeleton isLoaded={!isLoading && !isRefetching}>
              <Flex
                width={"100%"}
                border={"1px solid var(--Line)"}
                borderRadius={"8px"}
                flexDirection={"column"}
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  p={{ base: "12px 12px 8px 12px" }}
                  backgroundColor="var(--fill-avatar)"
                  borderRadius={"8px"}
                >
                  <Text
                    fontSize={{ base: "12px" }}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    Người chỉnh sửa
                  </Text>
                  <Text
                    fontSize={{ base: "12px" }}
                    fontWeight={400}
                    lineHeight={"normal"}
                  >
                    Thời gian chỉnh sửa
                  </Text>
                </Flex>
                <Flex width={"100%"} flexDirection={"column"}>
                  {checkInHistorysData && checkInHistorysData.length > 0 ? (
                    checkInHistorysData?.map((item, index) => (
                      <Flex
                        key={index}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        p={"8px 12px 8px 12px"}
                        flex={1}
                        w={"100%"}
                      >
                        <Text
                          fontSize={{ base: "12px" }}
                          fontWeight={500}
                          lineHeight={"140%"}
                          color="var(--color-info-employee)"
                        >
                          {`${item?.changer}`}
                        </Text>
                        <Text
                          fontSize={{ base: "12px" }}
                          fontWeight={500}
                          lineHeight={"140%"}
                          color="var(--color-info-employee)"
                        >
                          {`${item?.createdAt}`}
                        </Text>
                      </Flex>
                    ))
                  ) : (
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      p={"8px 12px 8px 12px"}
                    >
                      <Text
                        fontSize={{ base: "12px" }}
                        fontWeight={500}
                        lineHeight={"140%"}
                        color="var(--color-info-employee)"
                      >
                        {`Không có dữ liệu`}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Skeleton>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default PopupTimeKeepingHistory;
