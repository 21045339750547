import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconUpFile from "../../assets/images/product/iconUpFile.svg";
import plus from "../../assets/images/product/plus.svg";
import TableBooking from "./tableBooking";
import * as XLSX from "xlsx";
import toast from 'react-hot-toast';
import AddBooking from "./formBooking/addBooking";

export default function Booking() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState({
      headers: [
        {
          label: "",
          key: "",
        },
        {
          label: "MĐNBK",
          key: "code",
        },
        {
          label: "Tên đợt booking",
          key: "nameBooking",
        },
        {
          label: "Tên dự án",
          key: "nameProject",
        },
        {
          label: "Sản phẩm",
          key: "product",
        },
        {
          label: "TG bắt đầu",
          key: "timeStart",
        },
        {
          label: "TG kết thúc",
          key: "timeEnd",
        },
        {
          label: "Số tiền booking",
          key: "priceBooking",
        },
        {
          label: "Nhân viên tạo",
          key: "employee",
        },
        {
          label: "Ngày tạo",
          key: "dateCreate",
        },
      ],
      data: [
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameBooking: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: '-', timeStart: '20/08/2024', timeEnd: '22/09/2024', priceBooking: '1tỷ 450 triệu', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
      ],
    });

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
          // Hiện thông báo tải file
          const loadingToastId = toast.loading("Đang tải file...");
  
          const reader = new FileReader();
          reader.onload = (e) => {
              const binaryStr = e.target.result;
              const workbook = XLSX.read(binaryStr, { type: "binary" });
  
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
              setTableData((prevState) => ({
                  ...prevState,
                  data: jsonData,
              }));
  
              toast.success("Tải file thành công!");
              toast.dismiss(loadingToastId);
              event.target.value = null;
          };
  
          reader.onerror = () => {
              toast.error("Đã xảy ra lỗi khi tải file.");
              toast.dismiss(loadingToastId);
          };
  
          reader.readAsBinaryString(file);
      } else {
          toast.error("Vui lòng chọn một file.");
      }
  };
  

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Đợt nhận booking
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"} >
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={onOpen}
            >
                <Image src={plus} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    ĐN booking mới
                </Text>
            </Button>
        </Flex>
        
      </Flex>
      <Box mt={{ base: "23px" }}><TableBooking tableData={tableData} /></Box>
      <AddBooking
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            onSubmit={()=>{console.log("dat")}}
        />
    </Box>
  );
}
