import React, { createContext, useContext, useState } from "react";

const PopupFormContext = createContext();

export const ModalProvider = ({ children }) => {
  const [popupFormContextVisible, setPopupFormContextVisible] = useState(false);
  const [popupFormEdit, setPopupFormEdit] = useState(null);
  const [popupTimeKeepingHistory, setpopupTimeKeepingHistory] = useState(null);
  const [popupProjectManagement, setPopupProjectManagement] = useState(true);
  return (
    <PopupFormContext.Provider
      value={{
        popupFormContextVisible,
        setPopupFormContextVisible,
        popupFormEdit,
        setPopupFormEdit,
        popupTimeKeepingHistory,
        setpopupTimeKeepingHistory,
        popupProjectManagement,
        setPopupProjectManagement,
      }}
    >
      {children}
    </PopupFormContext.Provider>
  );
};

export const usePopupForm = () => useContext(PopupFormContext);
