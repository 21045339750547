import { Box, Flex, Image, Text } from "@chakra-ui/react";
import iconFile from "../../assets/images/projectManagement/icon-file.svg";
import noImage from "../../assets/images/projectManagement/noImage.png";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "id":
      return <Text textAlign={"center"}>{value}</Text>;
    case "image":
      const isImage = value;
      return (
        <Box>
          {isImage ? (
            <Image width={"100%"} minW={"153px"} h={"86px"} src={isImage}></Image>
          ) : (
            // <Box h={"48px"} w={"100%"} backgroundColor={"#D9D9D9"}></Box>
            <Image width={"100%"} minW={"153px"} h={"86px"} src={noImage}></Image>
          )}
        </Box>
      );
    case "nameproject":
      return <Box>
        <Text textAlign={"start"}>{value.name}</Text>
        <Text fontSize={"14px"} fontWeight={400} color={"var(--color-label-employee)"} textAlign={"start"}>{value.des}</Text>
      </Box>;
    case "owner":
      return <Text textAlign={"start"}>{value}</Text>;
    case "classify":
      return <Text textAlign={"start"}>{value}</Text>;
    case "address":
      return <Text textAlign={"end"}>{value}</Text>;
    case "city":
      return <Text textAlign={"end"}>{value}</Text>;
    default:
      return (
        <Flex justifyContent={"flex-end"}>
          <Image src={iconFile} />
        </Flex>
      );
  }
};
