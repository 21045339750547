import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useTab } from "../../contexts/useTab";
import iconExcel from "../../assets/images/timeKeeping/excel.svg";
import iconFile from "../../assets/images/timeKeeping/file.svg";
import AttendanceList from "./AttendanceList";
import TimeKeepingPoints from "./TimekeepingPoints";
import iconButton from "../../assets/images/timeKeeping/icon-button.svg";
import PopUpTimeKeeping from "../../components/popup/popupTimeKeepingEdit";
import toast from "react-hot-toast";
import { checkinPlaceCall } from "../../api/checkinPlace";
import { useTimeKeeping } from "../../hooks/useTimeKeeping";
import { useCheckIn } from "../../hooks/useCheckIn";
import { exportFile } from "../../utils/tools";
import PopupImport from "../../components/popup/PopupImport";
import { ROLES_CONFIG } from "../../utils";
import { useSelector } from "react-redux";

export default function TimeKeeping() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { timeKeepingTab, setTimeKeepingTab } = useTab();
  const { refetch } = useTimeKeeping();
  const {
    checkInData,
    refetch: refetchCheckInData,
    isRefetching,
  } = useCheckIn();
  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  // Define available tabs with roles
  const tabEmployee = [
    {
      title: "Danh sách chấm công",
      content: <AttendanceList isRefetchingWithCreate={isRefetching} />,
      isShowButton: true,
      role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_QLCC.TAB.TAB_DSCC.role,
    },
    {
      title: "Danh sách điểm chấm công",
      content: <TimeKeepingPoints />,
      isShowButton: false,
      role: ROLES_CONFIG.MENU.MENU_QLNS.CHILDREN.CHILDREN_QLCC.TAB.TAB_DSDDCC.role,
    },
  ];

  // Filter tabs based on roles using useMemo to avoid recalculations
  const filteredTabs = useMemo(
    () =>
      tabEmployee.filter((tab) =>
        tab.role.some((role) => roles.includes(role) || roles.includes("ALL"))
      ),
    [tabEmployee, roles]
  );

  // Ensure timeKeepingTab is within the range of filteredTabs
  const activeTab = filteredTabs[timeKeepingTab] || filteredTabs[0];

  const handleOpen = () => {
    onOpen();
  };

  const onSubmit = async (data) => {
    const toastCreate = toast.loading("Đang thêm địa điểm ...");
    try {
      let status = data.status === 1 ? false : true;
      let checkInTypes =
        data.checkInTypes === "0"
          ? ["IMAGE"]
          : data.checkInTypes === "1"
          ? ["QRCODE"]
          : [];
      let filteredData = {
        status,
        name: data.name,
        location: data.location,
        timeForSale: data.timeForSale,
        lateTimeForSale: data.lateTimeForSale,
        timeForSupport: data.timeForSupport,
        lateTimeForSupport: data.lateTimeForSupport,
        checkInTypes,
      };

      await checkinPlaceCall.create(filteredData);
      await refetch();
      onToggle();
      toast.success("Thêm thành công");
    } catch (error) {
      toast.error("Có lỗi xảy ra khi thêm");
    }
    toast.dismiss(toastCreate);
  };

  const [popupVisible, setPopupVisible] = useState(false);

  const handleExport = () => {
    const checkInColumnMapping = {
      dateTime: "Thời gian điểm danh",
      checkinTime: "Giờ vào",
      code: "Mã nhân viên",
      fullName: "Họ và tên",
      name: "Địa điểm điểm danh",
      workingDayRate: "Hệ số ngày công",
      reason: "Lý do",
    };
    if (checkInData?.length) {
      const data = checkInData.map((e) => e.export);
      exportFile(data, checkInColumnMapping, "bang_cham_cong.xlsx");
      toast.success("Export thành công");
    } else toast.error("Không có dữ liệu");
  };

  return (
    <Box
      minH="calc(100vh - 80px)"
      w="100%"
      backgroundColor="var(--color-backgroundmain)"
      p="24px 16px 16px 15px"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap="74px">
          <Text fontSize="20px" fontWeight={600}>
            Quản lý chấm công
          </Text>
          <Flex justifyContent="space-around" gap="24px">
            {filteredTabs.map((tab, index) => (
              <Flex
                alignItems="center"
                key={index}
                onClick={() => setTimeKeepingTab(index)}
                cursor="pointer"
                p="12px 5px 0"
                zIndex="10"
              >
                <Text
                  position="relative"
                  pb="8px"
                  fontSize="16px"
                  fontWeight={600}
                  lineHeight="normal"
                  fontFamily="var(--font-heading-main)"
                  color={
                    timeKeepingTab === index ? "var(--color-secondary)" : "#9D9D9D"
                  }
                  _before={{
                    content: '""',
                    position: "absolute",
                    bottom: "-1px",
                    left: 0,
                    width: "100%",
                    height: timeKeepingTab === index ? "2px" : "0",
                    backgroundColor: "var(--color-secondary)",
                  }}
                  textAlign="center"
                  whiteSpace="nowrap"
                >
                  {tab.title}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {activeTab?.isShowButton ? (
          <Flex alignItems="center" gap="16px">
            <Button
              display="flex"
              alignItems="center"
              p="10px 16px"
              gap="8px"
              backgroundImage="var(--color-gradient-excel)"
              onClick={handleExport}
            >
              <Image src={iconFile} />
              <Text fontSize="15px" fontWeight={600} color="var(--color-main)">
                Xuất excel
              </Text>
            </Button>
            <Button
              display="flex"
              alignItems="center"
              p="10px 16px"
              gap="8px"
              backgroundImage="var(--color-button)"
              onClick={() => setPopupVisible(true)}
            >
              <Image src={iconExcel} />
              <Text fontSize="15px" fontWeight={600} color="var(--color-main)">
                Tải file lên
              </Text>
            </Button>
          </Flex>
        ) : (
          <Button
            display="flex"
            alignItems="center"
            p="10px 16px"
            gap="8px"
            backgroundImage="var(--color-button)"
            onClick={handleOpen}
          >
            <Image src={iconButton} />
            <Text fontSize="15px" fontWeight={600} color="var(--color-main)">
              Điểm chấm công mới
            </Text>
          </Button>
        )}
      </Flex>
      <Box mt="23px">{activeTab?.content}</Box>
      <PopUpTimeKeeping isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
      {popupVisible && (
        <PopupImport
          isOpen={popupVisible}
          onClose={() => setPopupVisible(false)}
          refetchCheckInData={refetchCheckInData}
        />
      )}
    </Box>
  );
}
