import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { formatDate, formatDateToLocal } from "../utils/utils";
import { formatDateDb, formatTimeDb } from "../utils/tools";

const NUMBER_NFT_PER_PAGE = 100;
const queryKey = "checkInHistorys";

async function fetchCheckInHistorys(currentPage, documentId) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI(
      "get",
      `/checkin/report/${documentId}/history?${queryParams}`
    );
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        changer: item?.modifiedBy?.fullName || "-",
        createdAt: item?.createdAt
          ? `${formatDateDb(item?.createdAt)} ${formatTimeDb(item?.createdAt)}`
          : "-",
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkInHistorys data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useCheckInHistorys(documentId) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, documentId],
    queryFn: () => fetchCheckInHistorys(currentPage, documentId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkInHistorys data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    checkInHistorysData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
