import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconUpFile from "../../assets/images/product/iconUpFile.svg";
import plus from "../../assets/images/product/plus.svg";
import TableOpenForSale from "./tableOpenForSale";
import AddOpenForSale from "./formOpenForSale/addOpenForSale";

export default function Booking() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState({
      headers: [
        {
          label: "",
          key: "",
        },
        {
          label: "MĐNBK",
          key: "code",
        },
        {
          label: "Tên đợt mở bán",
          key: "nameOpenForSale",
        },
        {
          label: "Tên dự án",
          key: "nameProject",
        },
        {
          label: "Tên sản phẩm bán",
          key: "product",
        },
        {
          label: "Giá bán",
          key: "price",
        },
        {
          label: "Giá tính hoa hồng",
          key: "basedSalary",
        },
        {
          label: "Nhân viên đưa",
          key: "employeeDelivery",
        },
        {
          label: "TG đưa",
          key: "timeDelivery",
        },
        {
          label: "TG bắt đầu",
          key: "timeStart",
        },
        {
          label: "TG kết thúc",
          key: "timeEnd",
        },
        {
          label: "Nhân viên tạo",
          key: "employee",
        },
        {
          label: "Ngày tạo",
          key: "dateCreate",
        },
      ],
      data: [
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {code: '686868',nameOpenForSale: 'Tên đợt booking', nameProject: 'Đây là tên dự án', product: 'Đây là tên căn hộ bán', price: '1.850.000.000', basedSalary: '135.000.000', employeeDelivery: 'Nguyễn thị ngọc ánh', timeDelivery: '05/09/2024', timeStart: '20/08/2024', timeEnd: '22/09/2024', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
      ],
    });

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý đợt mở bán
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"} >
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
            >
                <Image src={iconUpFile} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                  Tải file lên
                </Text>
            </Button>
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={onOpen}
            >
                <Image src={plus} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                  Đợt MBán mới
                </Text>
            </Button>
        </Flex>
        
      </Flex>
      <Box mt={{ base: "23px" }}><TableOpenForSale tableData={tableData} /></Box>
      <AddOpenForSale
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            onSubmit={()=>{console.log("dat")}}
        />
    </Box>
  );
}
