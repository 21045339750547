import React, { createContext, useContext, useState } from "react";

const PopupJobTitleContext = createContext();

export const ModalJobTitleContext = ({ children }) => {
  const [popupFormContextVisible, setPopupFormContextVisible] = useState(false);
  return (
    <PopupJobTitleContext.Provider
      value={{
        popupFormContextVisible,
        setPopupFormContextVisible,
      }}
    >
      {children}
    </PopupJobTitleContext.Provider>
  );
};

export const usePopupFormJobTitle = () => useContext(PopupJobTitleContext);