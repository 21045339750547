import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import React from "react";

export const FormTimeKeeping = ({
  type = "text",
  title,
  place,
  length,
  errors,
  register,
  onInput,
  value,
  step
}) => {
  return (
    <FormControl
      display={"flex"}
      flexDirection={"column"}
      gap={{ base: "6px" }}
      maxW={length}
    >
      <FormLabel
        fontSize={"12px"}
        fontWeight={400}
        color="var(--text-color-Subtittle)"
        lineHeight={"normal"}
        m={{ base: "0" }}
      >
        {title}
      </FormLabel>

      <InputGroup
        mt={"0"}
        p={{ base: "14px 12px 13px 12px" }}
        alignItems={"center"}
        h={"44px"}
        border={"0.5px solid var(--Line)"}
        borderRadius={"8px"}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Input
        step={step}
          color="var(--text-color-Subtittle)"
          fontWeight={500}
          fontSize={"14px"}
          border={"none"}
          p={"0px"}
          placeholder={place}
          type={type}
          _focus={{
            boxShadow: "none",
          }}
          value={value}
          onInput={onInput}
          {...register} 
        />
      </InputGroup>

      {errors && (
        <Text fontSize={{ base: "12px" }} color={"red"}>
          {errors.message}
        </Text>
      )}
    </FormControl>
  );
};
