import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { customDateFormat, customDateTimeFormat } from "../../utils/utils";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "requestDate":
      return <Text>{ customDateTimeFormat(value)}</Text>;
    case "startDate":
      return <Text>{ customDateFormat(value)}</Text>;
    case "approvedDate":
        return <Text>{ customDateTimeFormat(value)}</Text>;
    case "contact":
      const infos = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {infos?.length &&
            infos?.map((item, index) => <Text key={index}>{item}</Text>)}
        </Flex>
      );
    case "bankInfo":
      const bankInfo = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {bankInfo?.length &&
            bankInfo?.map((item, index) => <Text key={index}>{item}</Text>)}
        </Flex>
      );
    case "identificationNumberInfo":
      const identificationNumberInfo = value?.split("-") || [];
      return (
        <Flex direction={"column"} justifyContent={"left"}>
          {identificationNumberInfo?.length &&
            identificationNumberInfo?.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))}
        </Flex>
      );
    case "avatar":
      return <Avatar src={value} />;
    case "status":
      const status = !value ? "Đang làm việc" : "Dừng làm việc";
      return (
        <Text
          color={value ? "var(--text-red-employee)" : "var(--color-status-employ)"}
        >
          {status}
        </Text>
      );
    case "employee":
        return (
            <Box>
                <Text 
                    fontSize={"14px"}
                    lineHeight={"19.6px"}
                    fontWeight={"500"}
                    color={"var(--color-info-employee)"}
                >
                    {value.fullName}
                </Text>
                <Text 
                    fontSize={"12px"}
                    lineHeight={"16.8px"}
                    fontWeight={"400"}
                    color={"var(--color-label-employee)"}
                >
                    {value.jobPosition}
                </Text>
            </Box>
        )
        case "approved":
          return (
              <Box>
                  <Text 
                      fontSize={"14px"}
                      lineHeight={"19.6px"}
                      fontWeight={"500"}
                      color={"var(--color-info-employee)"}
                  >
                      {value.fullName}
                  </Text>
                  <Text 
                      fontSize={"12px"}
                      lineHeight={"16.8px"}
                      fontWeight={"400"}
                      color={"var(--color-label-employee)"}
                  >
                      {value.jobPosition}
                  </Text>
              </Box>
          )
    default:
      return <Text>{value}</Text>;
  }
};