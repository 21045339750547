import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
// import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import iconEdit from "../../assets/images/product/edit.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import { useForm } from "react-hook-form";
import AddAdminManagement from "./formAdminManagement/addAdminManagement";
import toast from "react-hot-toast";
import { userGroupRolesLinksCall } from "../../api/userGroupRolesLinks";

const TableAdminManagement = ({
  tableData,
  totalPages,
  handlePageChange,
  isLoading,
  isRefetching,
  currentPage,
  entries,
  startEntry,
  endEntry,
  refetch,
}) => {
  // table
  const [dataEdit, setDataEdit] = useState(null);
  const handleOpenEditEmployee = (data) => {
    setDataEdit(data);
  };

  const handleCloseEditEmployee = () => {
    setDataEdit(null);
  };

  const [dataDelete, setDataDelete] = useState(null);
  const handleOpenDetailEmployee = (data) => {
    setDataDelete(data);
  };

  const handleCloseDetailEmployee = () => {
    setDataDelete(null);
  };

  const onSubmitDelete = async () => {
    const toastEdit = toast.loading("Đang xóa dữ liệu ...");
    const user_id = dataDelete?.user_id;
    try {
      await userGroupRolesLinksCall.delete(user_id);
      await refetch();
      setDataDelete(null);
      toast.success("Xóa thành công");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Có lỗi xảy ra khi xóa");
    }
    toast.dismiss(toastEdit);
  };

  const onSubmitEdit = async (data) => {
    const toastCreate = toast.loading("Đang sửa quyền ...");
    const {
      employeeObject: { value: user_id },
      group_role_code,
    } = data;
    try {
      await userGroupRolesLinksCall.update(user_id, {
        group_role_code,
      });
      await refetch();
      setDataEdit(null);
      toast.success("Update thành công");
    } catch (error) {
      console.error("Error", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }

    toast.dismiss(toastCreate);
  };

  return (
    <>
      <Flex flexDirection={"column"} gap={{ base: "16px" }}>
        <Flex
          backgroundColor="var(--color-main)"
          borderRadius={{ base: "12px" }}
          flexDirection={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={{ base: "32px" }}
            p={"16px 16px 29px 16px"}
          >
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color={"var(--color-header-employee)"}
            >
              Phân loại admin
            </Text>
          </Flex>
          <Box width={"100%"} height={"100%"}>
            <TableContainer w={"100%"}>
              <Skeleton isLoaded={!isLoading && !isRefetching}>
                <Table w={"100%"} variant="unstyled">
                  <Thead
                    w={"100%"}
                    h={"41px"}
                    color="white"
                    fontWeight="400"
                    backgroundColor="#F9FAFD"
                  >
                    <Tr h={{ base: "41px" }}>
                      {tableData.headers.map((e, index) => {
                        let width = "auto";
                        let textAlign = "auto";
                        if (e.key === "") {
                          width = "2%";
                          textAlign = "center";
                        } else if (e.key === "code") {
                          width = "10%";
                          textAlign = "start";
                        } else if (e.key === "fullName") {
                          width = "auto";
                          textAlign = "start";
                        } else {
                          width = "15%";
                          textAlign = "end";
                        }
                        return (
                          <Td
                            p={"16px 16px 8px 16px"}
                            key={index}
                            border={"none"}
                            color={"#51607B"}
                            fontSize={{ base: "14px" }}
                            w={width}
                          >
                            <Box textAlign={textAlign}>{e.label}</Box>
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody w={"100%"} h={"100%"}>
                    {tableData.data?.length > 0 ? (
                      tableData.data?.map((e, rowIndex) => {
                        const items = { ...e };
                        delete items.delete;
                        delete items.edit;
                        const keyValues = Object.keys(items);
                        return (
                          <Tr key={rowIndex} h={"72px"}>
                            <Td
                              p={"16px 0px 8px 16px"}
                              w={"2%"}
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                            >
                              <Menu>
                                <MenuButton
                                  _hover={{ backgroundColor: "none" }}
                                  _active={{ backgroundColor: "none" }}
                                  background="none"
                                  as={Button}
                                  p={0}
                                >
                                  <AiOutlineMore fontSize={"20px"} w="20px" />
                                </MenuButton>
                                <MenuList
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color={"var(--color-info-employee)"}
                                >
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenEditEmployee(e?.edit)
                                    }
                                  >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                  </MenuItem>
                                  <MenuItem
                                    gap={"16px"}
                                    onClick={() =>
                                      handleOpenDetailEmployee(e?.delete)
                                    }
                                  >
                                    <Image src={icontrash} /> Xóa admin
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                            <Td
                              p={"16px 0px 8px 16px"}
                              w={"2%"}
                              bg={
                                rowIndex % 2 === 0
                                  ? "var(--color-main)"
                                  : "var(--fill-avatar)"
                              }
                            >
                              {rowIndex + 1}
                            </Td>
                            {keyValues.map((keyvalue, index) => {
                              let width = "auto";
                              let widthText = "100%";
                              let textAlign = "auto";
                              let whiteSpace = "inherit";
                              if (keyvalue === "") {
                                width = "2%";
                                textAlign = "center";
                              } else if (keyvalue === "code") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "fullName") {
                                width = "auto";
                                textAlign = "start";
                              } else {
                                width = "auto";
                                textAlign = "end";
                                whiteSpace = "inherit";
                              }
                              return (
                                <Td
                                  p={"16px 16px 8px 16px"}
                                  w={width}
                                  key={index}
                                  bg={
                                    rowIndex % 2 === 0
                                      ? "var(--color-main)"
                                      : "var(--fill-avatar)"
                                  }
                                >
                                  <Box
                                    fontSize={{ base: "14px" }}
                                    lineHeight={"19.6px"}
                                    textAlign={textAlign}
                                    fontWeight={500}
                                    color={"var(--color-info-employee)"}
                                    w={widthText}
                                    whiteSpace={whiteSpace}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {formatTableValue(
                                      items[keyvalue],
                                      keyvalue
                                    )}
                                  </Box>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td
                          colSpan={tableData.headers.length}
                          textAlign="center"
                          padding={"40px 0"}
                        >
                          Không có dữ liệu
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Skeleton>
            </TableContainer>

            <Box mt={10} mr={5} mb={5} ml={5}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                Showing {startEntry} to {endEntry} of {entries} entries
                <HStack spacing={2} justify="flex-end">
                  <ReactPaginate
                    className="pagination-custom"
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    breakClassName={"ellipsis"}
                    breakLabel={"..."}
                    previousLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronLeft}
                      />
                    }
                    nextLabel={
                      <Img
                        w={"16px"}
                        minW={"16px"}
                        h={"16px"}
                        src={chevronRight}
                      />
                    }
                    renderOnZeroPageCount={null}
                    initialPage={currentPage - 1}
                  />
                </HStack>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {dataEdit && (
        <AddAdminManagement
          isOpen={!!dataEdit}
          onClose={handleCloseEditEmployee}
          isEdit={true}
          defaultValues={dataEdit}
          onSubmit={onSubmitEdit}
        />
      )}
      {dataDelete && (
        <DeleteDialog
          isOpen={!!dataDelete}
          onClose={handleCloseDetailEmployee}
          action={onSubmitDelete}
          title="Xóa đợt quyền quản trị"
          description={
            <Text display={"inline"}>
              Bạn có xóa quyền quản trị của{" "}
              <Text fontWeight={"600"} display={"inline"}>
                {dataDelete?.fullName}
              </Text>{" "}
              không?
            </Text>
          }
        />
      )}
    </>
  );
};

export default TableAdminManagement;
