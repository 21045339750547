import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  RadioGroup,
  Radio,
  SimpleGrid,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useNumberInput,
  HStack,
} from "@chakra-ui/react";
import CloseIconBlack from "../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { InputControl } from "../../components/form/formControls";
import { FormTimeKeeping } from "../form/formTimeKeeping";

const schema = Yup.object().shape({
  dateTime: Yup.string().required("Vui lòng nhập ngày chấm công"),
  checkinTime: Yup.string()
    .required("Vui lòng nhập giờ chấm công")
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Thời gian không hợp lệ, định dạng phải là HH:mm"
    ),
});

const PopUpTimeKeepingEditHistory = ({
  isOpen,
  onClose,
  data,
  defaultValues,
  onSubmit,
}) => {
  const [focusStates, setFocusStates] = useState({});
  const [inputColors, setInputColors] = useState({
    dateTime: "var(--color-text-unclick)",
  });

  const {
    reset,
    setValue,
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;
    setValue(inputName, selectedDate);
    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex h={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Chỉnh sửa chấm công
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                m={"24px"}
              >
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={600}
                  color={"var(--color-header-employee)"}
                >
                  {watch("code")}-{watch("fullName")}
                </Text>
              </Flex>
              <Box m={"24px auto 0"} w={"545px"}>
                <Text
                  fontSize={"12px"}
                  lineHeight={"15px"}
                  color={"var(--color-label-employee)"}
                  fontWeight={400}
                  mb={"6px"}
                >
                  Điểm chấm công
                </Text>
                <Text
                  mt={"8px"}
                  fontSize={"14px"}
                  lineHeight={"17px"}
                  color={"var(--color-info-employee)"}
                  fontWeight={500}
                >
                  {watch("name")}
                </Text>
                <Flex justifyContent={"space-between"} gap={"27px"} mt={"20px"}>
                  <InputControl
                    type={"date"}
                    label="Ngày chấm công"
                    placeholder="dd/mm/yy"
                    colorIp={
                      getValues("dateTime")
                        ? "var(--color-info-employee)"
                        : inputColors.dateTime
                    }
                    value={watch("dateTime")}
                    onChange={(e) => handleDateChange(e, "dateTime")}
                    errors={errors.dateTime}
                    onFocus={() => handleFocus("dateTime")}
                    onBlur={() => handleBlur("dateTime")}
                    register={register("dateTime")}
                    state={focusStates["dateTime"]}
                  />
                  <FormTimeKeeping
                    title={"Giờ chấm công"}
                    place={"00:00:00"}
                    type="time"
                    isIconPlace={false}
                    isIconTime={true}
                    length={"259px"}
                    register={register("checkinTime")}
                    errors={errors.checkinTime}
                    value={watch("checkinTime")}
                  />
                </Flex>
                <Box
                  mt={"20px"}
                  mb={"16px"}
                  h={"0.5px"}
                  bg={"var(--bg-line-employee)"}
                ></Box>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                    <Text
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={400}
                      color={"var(--color-label-employee)"}
                    >
                      Lí do:
                    </Text>
                    <Text
                      ml={"3px"}
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={500}
                      color={"var(--color-option-employee-hover)"}
                    >
                      {watch("reason")}
                    </Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={400}
                      color={"var(--color-label-employee)"}
                    >
                      Số ngày công:
                    </Text>
                    <Text
                      ml={"3px"}
                      fontSize={"16px"}
                      lineHeight={"19px"}
                      fontWeight={500}
                      color={"var(--color-option-employee-hover)"}
                    >
                      {watch("workingDayRate")}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              <Button
                w={"112px"}
                h={"44px"}
                color={"var(--color-option-employee-hover)"}
                border={"1px solid var(--color-option-employee-hover)"}
                bg={"var(--color-main)"}
                onClick={onClose}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--linear-gradient-employee)"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Lưu
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PopUpTimeKeepingEditHistory;
