import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  Input,
  Button,
  InputGroup,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  Avatar,
  InputRightElement,
  Image,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { InputControl, RadioControl, SelectControl } from "./formControls";
import NonAvatar from "../Icons/avartar";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { clientAPI } from "../../api/client";
import calendarIcon from "../../assets/images/onleave/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import { useCategories } from "../../hooks/useCategories";
import { employeeCall } from "../../api/employee";

registerLocale("vi", vi);

export const FormEmployee = memo(
  ({ isOpen, onClose, department, defaultValues, onSubmit, showInfo }) => {

    const schema = Yup.object().shape({
      fullName: Yup.string().required("Vui lòng nhập họ tên"),
      code: Yup.string().required("Mã nhân viên là bắt buộc")
      .test(
        'is-unique', 
        'Mã nhân viên đã tồn tại', 
        async (value) => {
          if (!value) return true;
          return await employeeCall.checkCodeUnique(value);
        }
      ),
      password: Yup.string()
        .required("Mật khẩu là bắt buộc")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[a-zA-Z0-9!@#$%&*]+$/,
          "Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số, 1 ký tự đặc biệt, và không chứa dấu."
        ),
      phone: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
      email: Yup.string()
        .required("Vui lòng nhập email")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
    
        identificationNumber: Yup.string()
        .test(
          "is-valid-id-number",
          "Số CCCD/CMND không hợp lệ (9 hoặc 12 số).",
          (value) => {
            if (!value) {
              return true; 
            }
            return /^\d{9}$|^\d{12}$/.test(value);
          }
        )
        .nullable(),
      dateOfIdentification: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian cấp không hợp lệ."),
      startTime: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian bắt đầu không hợp lệ."),
    });
    const schema2 = Yup.object().shape({
      fullName: Yup.string().required("Vui lòng nhập họ tên"),
      code: Yup.string()
      .required("Mã nhân viên là bắt buộc")
      .test(
        'is-unique', 
        'Mã nhân viên đã tồn tại', 
        async (value) => {
          if (!value) return true;
          return await employeeCall.checkCodeUnique(value);
        }
      ),
      referralCodeId: Yup.string()
        .test(
          "is-valid",
          "Mã giới thiệu phải có độ dài 24 ký tự và chỉ chứa ký tự hexadecimal",
          (value) => {
            if (!value) {
              return true;
            }
            return value.length === 24 && /^[a-fA-F0-9]+$/.test(value);
          }
        )
        .nullable(),
      password: Yup.string()
        .required("Mật khẩu là bắt buộc")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[a-zA-Z0-9!@#$%&*]+$/,
          "Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số, 1 ký tự đặc biệt, và không chứa dấu."
        ),
      phone: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
      email: Yup.string()
        .required("Vui lòng nhập email")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
    
        identificationNumber: Yup.string()
        .test(
          "is-valid-id-number",
          "Số CCCD/CMND không hợp lệ (9 hoặc 12 số).",
          (value) => {
            if (!value) {
              return true; 
            }
            return /^\d{9}$|^\d{12}$/.test(value);
          }
        )
        .nullable(),
      dateOfIdentification: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian cấp không hợp lệ."),
      startTime: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian bắt đầu không hợp lệ."),
    });
    const schemaEdit = Yup.object().shape({
      fullName: Yup.string().required("Vui lòng nhập họ tên"),
      referralCodeId: Yup.string()
        .test(
          "is-valid",
          "Mã giới thiệu phải có độ dài 24 ký tự và chỉ chứa ký tự hexadecimal",
          (value) => {
            if (!value) {
              return true;
            }
            return value.length === 24 && /^[a-fA-F0-9]+$/.test(value);
          }
        )
        .nullable(),
      phone: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
      email: Yup.string()
        .required("Vui lòng nhập email")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
        identificationNumber: Yup.string()
        .test(
          "is-valid-id-number",
          "Số CCCD/CMND không hợp lệ (9 hoặc 12 số).",
          (value) => {
            if (!value) {
              return true; 
            }
            return /^\d{9}$|^\d{12}$/.test(value);
          }
        )
        .nullable(),
      dateOfIdentification: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian cấp không hợp lệ."),
      startTime: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value;
        })
        .typeError("Thời gian bắt đầu không hợp lệ."),
    });
  
    const {
      register,
      getValues,
      handleSubmit,
      setValue,
      clearErrors,
      setError,
      watch,
      reset,
      formState: { errors },
    } = useForm({
      resolver: defaultValues
        ? yupResolver(schemaEdit)
        : department === 0
        ? yupResolver(schema)
        : yupResolver(schema2),

      defaultValues: { ...defaultValues, department } || {},
    });

    useEffect(() => {
      if (isOpen) {
        reset({ ...defaultValues, department });
        setAvatar(null);
      }
    }, [isOpen, defaultValues, reset]);

    const { constantData } = useCategories(watch("saleAreaId"));

    const formatEntriesForSelect = (entries) =>
      Object.entries(entries || {}).map(([key, value]) => ({
        value: key,
        label: value,
      }));

    const jobPositionSupportBussines = useMemo(
      () => formatEntriesForSelect(constantData?.jobPositionSupportBussines),
      [constantData?.jobPositionSupportBussines]
    );
    const jobPositionBussines = useMemo(
      () => formatEntriesForSelect(constantData?.jobPositionBussines),
      [constantData?.jobPositionBussines]
    );
    const supportDepartment = useMemo(
      () => formatEntriesForSelect(constantData?.supportDepartments),
      [constantData?.supportDepartments]
    );
    const jobTitles = useMemo(
      () => formatEntriesForSelect(constantData?.jobTitles),
      [constantData?.jobTitles]
    );
    const branches = useMemo(
      () => formatEntriesForSelect(constantData?.branches),
      [constantData?.branches]
    );
    const departments = useMemo(
      () => formatEntriesForSelect(constantData?.departments),
      [constantData?.departments]
    );
    const businessCategorys = useMemo(
      () => formatEntriesForSelect(constantData?.businessCategorys),
      [constantData?.businessCategorys]
    );
    const guaranteeTypes = useMemo(
      () => formatEntriesForSelect(constantData?.guaranteeTypes),
      [constantData?.guaranteeTypes]
    );
    const saleAreas = useMemo(
      () => formatEntriesForSelect(constantData?.saleAreas),
      [constantData?.saleAreas]
    );
    const saleBlocks = useMemo(
      () => formatEntriesForSelect(constantData?.saleBlocks),
      [constantData?.saleBlocks]
    );

    const onChangeCode = (e) => {
      setValue("code", e.target.value);
    };
    const onchangeJobPosition = (option) => {
      setValue("jobPositionId", option);
    };

    const onchangeJobTitle = (option) => {
      setValue("jobTitleId", option);
    };
    const onchangeSupportDepartment = (option) => {
      setValue("supportDepartmentId", option);
    };

    const onchangeBranch = (option) => {
      setValue("branchId", option);
    };

    const onchangeBusinessCategorys = (option) => {
      setValue("businessCategoryId", option);
    };

    const onchangeGuaranteeTypes = (option) => {
      setValue("guaranteeTypeId", option);
    };

    // const onchangeDepartments = (option) => {
    //   setValue("department", Number(option));
    // };

    const onchangeSaleAreas = (option) => {
      clearErrors("saleBlockId");
      setValue("saleAreaId", option);
    };

    const onchangeSaleBlocks = (option) => {
      if (!watch("saleAreaId")) {
        setError("saleBlockId", {
          type: "manual",
          message: "Bạn phải chọn vùng trước khi chọn khối",
        });
        return;
      }
      setValue("saleBlockId", option);
    };

    const status = [
      { value: 0, label: "Đang làm việc" },
      { value: 1, label: "Dừng làm việc" },
    ];
    const [zIndex, setZIndex] = useState("");
    const [focusStates, setFocusStates] = useState({});
    // eslint-disable-next-line
    const [disableInput, setDisableInput] = useState(true);
    // const [inputColors, setInputColors] = useState({
    //   startTime: "var(--color-text-unclick)",
    //   endTime: "var(--color-text-unclick)",
    //   guaranteeDate: "var(--color-text-unclick)",
    //   dateOfIdentification: "var(--color-text-unclick)",
    //   depositDate: "var(--color-text-unclick)",
    // });

    const handleFocus = (field,data) => {
      if(data){
        setZIndex(data);
      }
      setFocusStates((prev) => ({ ...prev, [field]: true }));
    };

    const handleBlur = (field,data) => {
      setZIndex("");
      setFocusStates((prev) => ({ ...prev, [field]: false }));
    };

    const fileAvatarInputRef = useRef(null);
    const [avatar, setAvatar] = useState(null);

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageDataUrl = e.target.result;
          setAvatar(imageDataUrl);
          setValue("file", file);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleClick = () => {
      if (fileAvatarInputRef.current) {
        fileAvatarInputRef.current.click();
      }
    };
    const handleDateChange = (selectedDate, inputName) => {
      setValue(inputName, selectedDate, { shouldValidate: true });
      // setInputColors((prevColors) => ({
      //   ...prevColors,
      //   [inputName]: selectedDate
      //     ? "var(--color-info-employee)"
      //     : "var(--color-text-unclick)",
      // }));
    };

    const handleActionStateChange = (option) => {
      setValue("status", option);
    };

    const submitHandler = (data) => {
      onSubmit(data);
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        // motionPreset="fade"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          padding={"24px"}
          h={"86.5vh"}
          w={"100%"}
          maxW={"625px"}
          overflow="auto"
          // sx={{
          //   "&::-webkit-scrollbar": {
          //     width: "4px",
          //   },
          //   "&::-webkit-scrollbar-thumb": {
          //     width: "4px",
          //     borderRadius: "50px",
          //     background: "var(--color-background)",
          //   },
          //   "&::-moz-scrollbar": {
          //     width: "4px",
          //   },
          //   "&::-moz-scrollbar-thumb": {
          //     width: "4px",
          //     borderRadius: "50px",
          //     background: "var(--color-background)",
          //   },
          // }}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {
                showInfo?
                  "Thông tin nhân viên"
                :
                Boolean(department)
                  ? defaultValues
                    ? "Sửa thông tin nhân viên kinh doanh"
                    : "Nhân viên kinh doanh mới"
                  : defaultValues
                  ? "Sửa thông tin nhân viên hỗ trợ kinh doanh"
                  : "Nhân viên hỗ trợ kinh doanh mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            </Flex>
            <Box
              w={"100%"}
              h={"1px"}
              backgroundColor={"var(--bg-line-employee)"}
              marginTop={"26px"}
              marginBottom={"24px"}
            ></Box>
            <Box w={"100%"} maxW={"545px"} m={"0 auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
              >
                <Box flex={"1"} position={"relative"}>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"600"}
                    color={"var(--color-header-employee)"}
                    marginBottom={"16px"}
                  >
                    Ảnh đại diện
                  </Text>
                  <Flex
                    w={"120px"}
                    h={"120px"}
                    borderRadius={"50%"}
                    bgColor={"var(--fill-avatar)"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={handleClick}
                    cursor="pointer"
                    position={"relative"}
                  >
                    {avatar || watch("avatar") ? (
                      <Avatar
                        src={
                          avatar ||
                          `${process.env.REACT_APP_BACKEND_API}/${watch(
                            "avatar"
                          )}`
                        }
                        size="2xl"
                        name=""
                      />
                    ) : (
                      <NonAvatar />
                    )}
                  </Flex>
                  <Input
                    ref={fileAvatarInputRef}
                    position={"absolute"}
                    type="file"
                    w={"0"}
                    h={"0"}
                    border={"0"}
                    outline={"0"}
                    padding={"0"}
                    m={"0"}
                    overflow={"hidden"}
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg"
                  />
                </Box>
                <Box flex={"1"}>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    mb={"16px"}
                  >
                    Thông tin tài khoản
                  </Text>
                  <Box mb={"13px"}>
                    <InputControl
                      label="Mã nhân viên"
                      placeholder="Nhập mã nhân viên"
                      errors={errors.code}
                      register={register("code")}
                      onFocus={() => handleFocus("code")}
                      onBlur={() => handleBlur("code")}
                      state={focusStates["code"]}
                      mwidth={"100%"}
                    />
                  </Box>
                  <InputControl
                    type="password"
                    label="Mật khẩu"
                    placeholder="Đặt mật khẩu"
                    errors={errors.password}
                    register={register("password")}
                    onFocus={() => handleFocus("password")}
                    onBlur={() => handleBlur("password")}
                    state={focusStates["password"]}
                    mwidth={"100%"}
                  />
                </Box>
              </Flex>
              {/* Khooi 1 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin cá nhân
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <InputControl
                    label="Họ và tên"
                    placeholder="Nhập họ và tên"
                    errors={errors.fullName}
                    register={register("fullName")}
                    onFocus={() => handleFocus("fullName")}
                    onBlur={() => handleBlur("fullName")}
                    state={focusStates["fullName"]}
                    mwidth={"100%"}
                  />
                  <SelectControl
                    label="Chi nhánh"
                    dataOption={branches}
                    selectedMain={watch("branchId")}
                    placeholder="Chọn chi nhánh"
                    onChange={onchangeBranch}
                    onFocus={() => handleFocus("branchId")}
                    onBlur={() => handleBlur("branchId")}
                    state={focusStates["branchId"]}
                    registerProps={register("branchId")}
                  />
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  {Boolean(department) ? (
                    <SelectControl
                      label="Chức vụ"
                      dataOption={jobPositionBussines}
                      selectedMain={watch("jobPositionId")}
                      placeholder="Chọn chức vụ"
                      onChange={onchangeJobPosition}
                      onFocus={() => handleFocus("jobPositionId")}
                      onBlur={() => handleBlur("jobPositionId")}
                      state={focusStates["jobPositionId"]}
                    />
                  ) : (
                    <SelectControl
                      label="Chức vụ"
                      dataOption={jobPositionSupportBussines}
                      selectedMain={watch("jobPositionId")}
                      placeholder="Chọn chức vụ"
                      onChange={onchangeJobPosition}
                      onFocus={() => handleFocus("jobPositionId")}
                      onBlur={() => handleBlur("jobPositionId")}
                      state={focusStates["jobPositionId"]}
                    />
                  )}

                  <SelectControl
                    label="Chức danh"
                    dataOption={jobTitles}
                    selectedMain={watch("jobTitleId")}
                    placeholder="Chọn chức danh"
                    onChange={onchangeJobTitle}
                    onFocus={() => handleFocus("jobTitleId")}
                    onBlur={() => handleBlur("jobTitleId")}
                    state={focusStates["jobTitleId"]}
                    registerProps={register("jobTitleId")}
                  />
                </Flex>
                {!Boolean(department) && (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Chức phòng ban"
                      dataOption={supportDepartment}
                      selectedMain={watch("supportDepartmentId")}
                      placeholder="Chọn phòng ban"
                      onChange={onchangeSupportDepartment}
                      onFocus={() => handleFocus("supportDepartmentId")}
                      onBlur={() => handleBlur("supportDepartmentId")}
                      state={focusStates["supportDepartmentId"]}
                      registerProps={register("supportDepartmentId")}
                    />
                  </Flex>
                )}
                {/* 3 */}
                {Boolean(department) && (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <SelectControl
                      label="Vùng"
                      dataOption={saleAreas}
                      selectedMain={watch("saleAreaId")}
                      placeholder="Chọn vùng"
                      isSearchable={true}
                      onChange={onchangeSaleAreas}
                      onFocus={() => handleFocus("saleAreaId")}
                      onBlur={() => handleBlur("saleAreaId")}
                      state={focusStates["saleAreaId"]}
                      registerProps={register("saleAreaId")}
                    />
                    <SelectControl
                       label="Khối"
                       selectedMain={watch("saleBlockId")}
                       dataOption={saleBlocks}
                       placeholder="Chọn khối"
                       errorMessage={errors.saleBlockId}
                       onChange={onchangeSaleBlocks}
                       onFocus={() => handleFocus("saleBlockId")}
                       onBlur={() => handleBlur("saleBlockId")}
                       state={focusStates["saleBlockId"]}
                       registerProps={register("saleBlockId")}
                    />
                  </Flex>
                )}
              </Box>
              {/* Khooi 2 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Hoạt động
                </Text>
                {/* 1 */}
                <FormLabel
                  fontSize={{ base: "12px" }}
                  fontWeight={400}
                  color={"var(--color-label-employee)"}
                  mb={"12px"}
                >
                  Trạng thái hoạt động
                </FormLabel>

                <RadioControl
                  options={status}
                  selectedValue={watch("status")}
                  onChange={handleActionStateChange}
                />
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <FormControl zIndex={zIndex==='startTime' ? 3 : 0}>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["startTime"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày vào làm
                    </FormLabel>
                    <InputGroup>
                      <DatePicker
                        width="100%"
                        selected={watch("startTime")}
                        onChange={(date) => handleDateChange(date, "startTime")}
                        dateFormat="dd/MM/yyyy"
                        locale="vi"
                        placeholderText="dd/mm/yy"
                        className="custom-datepicker datePickerStyle"
                        onFocus={() => handleFocus("startTime","startTime")}
                        onBlur={() => handleBlur("startTime")}
                      />
                      <InputRightElement pointerEvents="none" h={"44px"}>
                        <Image src={calendarIcon} />
                      </InputRightElement>
                    </InputGroup>
                    {errors.startTime && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.startTime.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl zIndex={zIndex==='endTime' ? 3 : 0}>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        disableInput
                          ? "var(--bg-line-employee)"
                          : focusStates["endTime"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày nghỉ việc
                    </FormLabel>
                    <InputGroup>
                      <DatePicker
                        selected={watch("endTime")}
                        onChange={(date) => handleDateChange(date, "endTime")}
                        dateFormat="dd/MM/yyyy"
                        locale="vi"
                        placeholderText="dd/mm/yy"
                        className="custom-datepicker datePickerStyle colorDisableInput"
                        onFocus={() => handleFocus("endTime","endTime")}
                        onBlur={() => handleBlur("endTime")}
                        disabled={disableInput ? true : false}
                      />
                      <InputRightElement pointerEvents="none" h={"44px"}>
                        <Image src={calendarIcon} />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Flex>
              </Box>
              {/* Khooi 3 */}
              {Boolean(department) && (
                <Box mt={"20px"}>
                  <Text
                    fontSize={"16px"}
                    lineHeight={"19px"}
                    fontWeight={"600"}
                    color={"var(--color-header-employee)"}
                    mb={"16px"}
                  >
                    Phân loại nhân sự
                  </Text>
                  {/* 1 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Loại nhân sự"
                      dataOption={businessCategorys}
                      selectedMain={watch("businessCategoryId")}
                      placeholder="Chọn loại nhân sự"
                      onChange={onchangeBusinessCategorys}
                      onFocus={() => handleFocus("businessCategoryId")}
                      onBlur={() => handleBlur("businessCategoryId")}
                      state={focusStates["businessCategoryId"]}
                    />
                    <InputControl
                      label="Mã giới thiệu"
                      placeholder="Nhập mã giới thiệu"
                      errors={errors.referralCodeId}
                      register={register("referralCodeId")}
                      onFocus={() => handleFocus("referralCodeId")}
                      onBlur={() => handleBlur("referralCodeId")}
                      state={focusStates["referralCodeId"]}
                      mwidth={"100%"}
                    />
                  </Flex>
                  {/* 2 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Loại bảo lãnh"
                      dataOption={guaranteeTypes}
                      placeholder="Chọn loại bảo lãnh"
                      selectedMain={watch("guaranteeTypeId")}
                      onChange={onchangeGuaranteeTypes}
                      onFocus={() => handleFocus("guaranteeTypeId")}
                      onBlur={() => handleBlur("guaranteeTypeId")}
                      state={focusStates["guaranteeTypeId"]}
                    />
                    <FormControl  zIndex={zIndex==='datguaranteeDatee' ? 3 : 0}>
                      <FormLabel
                        mb={"6px"}
                        fontSize={{ base: "12px" }}
                        fontWeight={400}
                        lineHeight={"15px"}
                        color={
                          focusStates["guaranteeDate"]
                            ? "var(--color-option-employee-hover)"
                            : "var(--color-label-employee)"
                        }
                      >
                        Ngày bảo lãnh
                      </FormLabel>
                      <InputGroup>
                        <DatePicker
                          width="100%"
                          selected={watch("guaranteeDate")}
                          onChange={(date) =>
                            handleDateChange(date, "guaranteeDate")
                          }
                          dateFormat="dd/MM/yyyy"
                          locale="vi"
                          placeholderText="dd/mm/yy"
                          className="custom-datepicker datePickerStyle"
                          onFocus={() => handleFocus("guaranteeDate","datguaranteeDatee")}
                          onBlur={() => handleBlur("guaranteeDate")}
                        />
                        <InputRightElement pointerEvents="none" h={"44px"}>
                          <Image src={calendarIcon} />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </Flex>
                  {/* 3 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <FormControl maxW={"259px"} zIndex={zIndex==='depositDate' ? 3 : 0}>
                      <FormLabel
                        mb={"6px"}
                        fontSize={{ base: "12px" }}
                        fontWeight={400}
                        lineHeight={"15px"}
                        color={
                          focusStates["depositDate"]
                            ? "var(--color-option-employee-hover)"
                            : "var(--color-label-employee)"
                        }
                      >
                        Ngày ký quỹ
                      </FormLabel>
                      <InputGroup>
                        <DatePicker
                          width="100%"
                          selected={watch("depositDate")}
                          onChange={(date) =>
                            handleDateChange(date, "depositDate")
                          }
                          dateFormat="dd/MM/yyyy"
                          locale="vi"
                          placeholderText="dd/mm/yy"
                          className="custom-datepicker datePickerStyle"
                          onFocus={() => handleFocus("depositDate","depositDate")}
                          onBlur={() => handleBlur("depositDate")}
                        />
                        <InputRightElement pointerEvents="none" h={"44px"}>
                          <Image src={calendarIcon} />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </Flex>
                </Box>
              )}
              {/* Khooi 4 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin liên hệ
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <InputControl
                    type="number"
                    label="Số điện thoại"
                    placeholder="Nhập số điện thoại"
                    errors={errors.phone}
                    register={register("phone")}
                    onFocus={() => handleFocus("phone")}
                    onBlur={() => handleBlur("phone")}
                    state={focusStates["phone"]}
                    mwidth={"100%"}
                  />
                  <InputControl
                    type="email"
                    label="Email"
                    placeholder="Nhập email"
                    errors={errors.email}
                    register={register("email")}
                    onFocus={() => handleFocus("email")}
                    onBlur={() => handleBlur("email")}
                    state={focusStates["email"]}
                    mwidth={"100%"}
                  />
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <InputControl
                    label="Chỗ ở hiện tại"
                    placeholder="Nhập chỗ ở hiện tại"
                    // errors={errors.currentAddress}
                    register={register("currentAddress")}
                    onFocus={() => handleFocus("currentAddress")}
                    onBlur={() => handleBlur("currentAddress")}
                    state={focusStates["currentAddress"]}
                    mwidth={"100%"}
                  />
                </Flex>
              </Box>
              {/* Khooi 5 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin giấy tờ
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <InputControl
                    type="number"
                    label="Số CCCD/CMND"
                    placeholder="Vui lòng nhập CCCD"
                    errors={errors.identificationNumber}
                    register={register("identificationNumber")}
                    onFocus={() => handleFocus("identificationNumber")}
                    onBlur={() => handleBlur("identificationNumber")}
                    state={focusStates["identificationNumber"]}
                    mwidth={"100%"}
                  />
                  <FormControl w={"100%"} maxW={"259px"} zIndex={zIndex==='dateOfIdentification' ? 3 : 0}>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      lineHeight={"15px"}
                      fontWeight={400}
                      color={
                        focusStates["dateOfIdentification"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày cấp
                    </FormLabel>
                    <InputGroup>
                      <DatePicker
                        width="100%"
                        selected={watch("dateOfIdentification")}
                        onChange={(date) =>
                          handleDateChange(date, "dateOfIdentification")
                        }
                        dateFormat="dd/MM/yyyy"
                        locale="vi"
                        placeholderText="dd/mm/yy"
                        className="custom-datepicker datePickerStyle"
                        onFocus={() => handleFocus("dateOfIdentification","dateOfIdentification")}
                        onBlur={() => handleBlur("dateOfIdentification")}
                      />
                      <InputRightElement pointerEvents="none" h={"44px"}>
                        <Image src={calendarIcon} />
                      </InputRightElement>
                    </InputGroup>
                    {errors.dateOfIdentification && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.dateOfIdentification.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <InputControl
                    label="Nơi cấp"
                    placeholder="Nhập nơi cấp..."
                    // errors={errors.placeOfIdentification}
                    register={register("placeOfIdentification")}
                    onFocus={() => handleFocus("placeOfIdentification")}
                    onBlur={() => handleBlur("placeOfIdentification")}
                    state={focusStates["placeOfIdentification"]}
                    mwidth={"100%"}
                  />
                  <InputControl
                    label="Địa chỉ thường trú"
                    placeholder="Nhập địa chỉ thường trú..."
                    // errors={errors.permanentAddress}
                    register={register("permanentAddress")}
                    onFocus={() => handleFocus("permanentAddress")}
                    onBlur={() => handleBlur("permanentAddress")}
                    state={focusStates["permanentAddress"]}
                    mwidth={"100%"}
                  />
                </Flex>
              </Box>
              {/* Khooi 6 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin thanh toán
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <InputControl
                    type="number"
                    label="Số tài khoản"
                    placeholder="Nhập số tài khoản"
                    // errors={errors.bankAccount}
                    register={register("bankAccount")}
                    onFocus={() => handleFocus("bankAccount")}
                    onBlur={() => handleBlur("bankAccount")}
                    state={focusStates["bankAccount"]}
                    mwidth={"100%"}
                  />
                  <InputControl
                    label="Tên ngân hàng"
                    placeholder="Nhập tên ngân hàng"
                    // errors={errors.bankName}
                    register={register("bankName")}
                    onFocus={() => handleFocus("bankName")}
                    onBlur={() => handleBlur("bankName")}
                    state={focusStates["bankName"]}
                    mwidth={"100%"}
                  />
                  {/* <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["bankAccount"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Số tài khoản
                    </FormLabel>
                    <Input
                      type="number"
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập số tài khoản NH..."
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      value={watch("bankAccount") || ""}
                      {...register("bankAccount")}
                      onChange={(e) => setValue("bankAccount", e.target.value)}
                      onFocus={() => handleFocus("bankAccount")}
                      onBlur={() => handleBlur("bankAccount")}
                    />
                    {errors.bankAccount && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.bankAccount.message}
                      </Text>
                    )}
                  </FormControl> */}
                  {/* <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["bankName"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Tên ngân hàng
                    </FormLabel>
                    <Input
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập tên ngân hàng"
                      value={watch("bankName") || ""}
                      onChange={(e) => setValue("bankName", e.target.value)}
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      {...register("bankName")}
                      onFocus={() => handleFocus("bankName")}
                      onBlur={() => handleBlur("bankName")}
                    />
                    {errors.bankName && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.bankName.message}
                      </Text>
                    )}
                  </FormControl> */}
                </Flex>
              </Box>
            </Box>
            <Flex
              justifyContent={"flex-end"}
              mt={"24px"}
              w={"100%"}
              // maxW={"545px"}
            >
              <Button
                type="submit"
                borderRadius={"8px"}
                background={"var(--linear-gradient-employee)"}
                color={"var(--color-main)"}
                w={"164px"}
                h={"44px"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                {defaultValues ? "Lưu thông tin" : "Thêm nhân viên"}
              </Button>
            </Flex>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

export default FormEmployee;
