import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { clientAPI } from "../api/client";
import { formatDateForInput } from "../utils/utils";

const queryKey = "employee";

async function fetchEmployeeInfo({
  department,
  id
}) {
  try {

    // Fetch employee data
    let item = await clientAPI(
      "get",
      `/employee/app/getMe`
    );
    
    // Transform the employee data
    let newData;
    if (department) {
        newData= {
            ...(item?._id && { id: item._id }),
            ...(item?.code && { code: item.code }),
            ...(item?.fullName && { fullName: item.fullName }),
            ...(item?.phone && { phone: item.phone }),
            ...(item?.email && { email: item.email }),
            ...(item?.avatar && { avatar: item.avatar }),
            ...(item?.jobTitleId && { jobTitleId: item.jobTitleId }),
            ...(item?.jobPositionId && { jobPositionId: item.jobPositionId }),
            ...(item?.identificationNumber && {
              identificationNumber: item.identificationNumber,
            }),
            ...(item?.dateOfIdentification && {
              dateOfIdentification: formatDateForInput(
                item.dateOfIdentification
              ),
            }),
            ...(item?.placeOfIdentification && {
              placeOfIdentification: item.placeOfIdentification,
            }),
            ...(item?.permanentAddress && {
              permanentAddress: item.permanentAddress,
            }),
            ...(item?.currentAddress && {
              currentAddress: item.currentAddress,
            }),
            ...(item?.bankAccount && { bankAccount: item.bankAccount }),
            ...(item?.bankName && { bankName: item.bankName }),
            ...(item?.startTime && {
              startTime: formatDateForInput(item.startTime),
            }),
            ...(item?.endTime && { endTime: formatDateForInput(item.endTime) }),
            status: item?.status,
            department: item?.department,
            ...(item?.supportDepartmentId && {
              supportDepartmentId: item.supportDepartmentId,
            }),
            ...(item?.businessCategoryId && {
              businessCategoryId: item.businessCategoryId,
            }),
            ...(item?.guaranteeTypeId && {
              guaranteeTypeId: item.guaranteeTypeId,
            }),
            ...(item?.guaranteeDate && {
              guaranteeDate: formatDateForInput(item.guaranteeDate),
            }),
            ...(item?.depositDate && {
              depositDate: formatDateForInput(item.depositDate),
            }),
            ...(item?.referralCodeId && {
              referralCodeId: item.referralCodeId,
            }),
            ...(item?.saleBlockId && { saleBlockId: item.saleBlockId }),
            ...(item?.saleAreaId && { saleAreaId: item.saleAreaId }),
            ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
            ...(item?.branchId && { branchId: item.branchId }),
        }
    } else {
        newData= {
        ...(item?._id && { id: item._id }),
        ...(item?.code && { code: item.code }),
        ...(item?.fullName && { fullName: item.fullName }),
        ...(item?.phone && { phone: item.phone }),
        ...(item?.email && { email: item.email }),
        ...(item?.avatar && { avatar: item.avatar }),
        ...(item?.jobTitleId && { jobTitleId: item.jobTitleId }),
        ...(item?.jobPositionId && { jobPositionId: item.jobPositionId }),
        ...(item?.identificationNumber && {
            identificationNumber: item.identificationNumber,
        }),
        ...(item?.dateOfIdentification && {
            dateOfIdentification: formatDateForInput(
            item.dateOfIdentification
            ),
        }),
        ...(item?.placeOfIdentification && {
            placeOfIdentification: item.placeOfIdentification,
        }),
        ...(item?.permanentAddress && {
            permanentAddress: item.permanentAddress,
        }),
        ...(item?.currentAddress && {
            currentAddress: item.currentAddress,
        }),
        ...(item?.bankAccount && { bankAccount: item.bankAccount }),
        ...(item?.bankName && { bankName: item.bankName }),
        ...(item?.startTime && {
            startTime: formatDateForInput(item.startTime),
        }),
        ...(item?.endTime && { endTime: formatDateForInput(item.endTime) }),
        status: item?.status,
        department: item?.department,
        ...(item?.supportDepartmentId && {
            supportDepartmentId: item.supportDepartmentId,
        }),
        ...(item?.businessCategoryId && {
            businessCategoryId: item.businessCategoryId,
        }),
        ...(item?.guaranteeTypeId && {
            guaranteeTypeId: item.guaranteeTypeId,
        }),
        ...(item?.guaranteeDate && {
            guaranteeDate: formatDateForInput(item.guaranteeDate),
        }),
        ...(item?.depositDate && {
            depositDate: formatDateForInput(item.depositDate),
        }),
        ...(item?.referralCodeId && {
            referralCodeId: item.referralCodeId,
        }),
        ...(item?.saleBlock && { saleBlock: item.saleBlock }),
        ...(item?.saleArea && { saleArea: item.saleArea }),
        ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
        ...(item?.branchId && { branchId: item.branchId }),
        }
    }

    return { data: newData };
  } catch (error) {
    console.log("error", error);
    return { data: []};
  }
}

export function useEmployeeInfo(
  department,
  id
) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      queryKey,
      currentPage,
      department,
      id
    ],
    queryFn: () =>
      fetchEmployeeInfo({
        currentPage,
        department,
        id
      }),
    refetchOnWindowFocus: false,
  });

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    if (data?.totalpages && currentPage > data.totalpages) {
      setCurrentPage(data.totalpages);
    }
  }, [currentPage, data?.totalpages]);

  return {
    employeeData: data?.data,
    totalPages: data?.totalpages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}
