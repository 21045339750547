import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import iconFile from "../../assets/images/projectManagement/icon-file.svg";
const FormProjectManagementAdd = ({
  title,
  placeholder,
  isBorderBottom,
  isIcon,
  isIconFile,
}) => {
  return (
    <FormControl gap={"8px"}>
      <FormLabel
        fontSize={{ base: "12px" }}
        fontWeight={400}
        color="var(--color-black-label)"
        m={"0px"}
      >
        {title}
      </FormLabel>
      <InputGroup>
        <Input
          borderRadius={"0px"}
          fontSize={{ base: "14px" }}
          fontWeight={500}
          lineHeight={"normal"}
          type="text"
          placeholder={placeholder}
          border={"none"}
          _active={{
            boxShadow: "none",
            border: "none",
            zIndex: "0",
            borderBottom: isBorderBottom
              ? "1px solid var(--color-boder)"
              : "none",
          }}
          _focus={{
            boxShadow: "none",
            border: "none",
            zIndex: "0",
            borderBottom: isBorderBottom
              ? "1px solid var(--color-boder)"
              : "none",
          }}
          _placeholder={{ color: "var(--text-gray-form)" }}
          p={"0px"}
          borderBottom={
            isBorderBottom ? "1px solid var(--color-boder)" : "none"
          }
        />
        {isIcon ? (
          <InputRightElement>
            <RiArrowDownSLine cursor={"pointer"} />
          </InputRightElement>
        ) : null}
        {isIconFile ? (
          <InputRightElement>
            <Image src={iconFile} cursor={"pointer"} />
          </InputRightElement>
        ) : null}
      </InputGroup>
    </FormControl>
  );
};

export default FormProjectManagementAdd;
