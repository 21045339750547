import { Avatar, Box, Flex, Text } from "@chakra-ui/react";

const formatAndTruncateText = (text, maxLength) => {
  const formattedText = text.replace(/,/g, ', ');
  return formattedText.length > maxLength ? `${formattedText.slice(0, maxLength)}...` : formattedText;
};
export const formatTableValue = (value, key) => {
  switch (key) {
    case "group_role_code":
      return <Text>{formatAndTruncateText(value, 100)}</Text>;
    default:
      return <Text>{value}</Text>;
  }
};
