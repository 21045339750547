import {
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { QRCodeCanvas } from 'qrcode.react';
import { useQuery } from '@tanstack/react-query';
import { checkinPlaceCall } from "../../api/checkinPlace";

const PopupQRCode = ({ isOpen, onClose, id }) => { 
  
  const { data } = useQuery({
    queryKey: ['checkInCode', id],
    queryFn: () => checkinPlaceCall.get(id),
    enabled: !!isOpen,
    refetchInterval: isOpen ? 1000 : false, 
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"325px"}>
        {data?.checkInCode?.code && 
          <QRCodeCanvas 
            value={data.checkInCode.code}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",  
              padding: "10px",      
              backgroundColor: "#fff" 
            }}
          />}
      </ModalContent>
    </Modal>
  );
};

export default PopupQRCode;
