import { Flex, Image, Text } from "@chakra-ui/react";
import iconDelete from "../../assets/images/projectManagement/icon-delete.svg";

export const formatTablePopup = (value, key) => {
  switch (key) {
    case "name":
      return <Text textAlign={"start"}>{value}</Text>;
    case "jobtitle":
      return <Text textAlign={"start"}>{value}</Text>;
    case "bonuscoefficient":
      return <Text textAlign={"center"}>{value}</Text>;
    default:
      return (
        <Flex justifyContent={"flex-end"}>
          <Image src={iconDelete} />
        </Flex>
      );
  }
};
