import { useQuery } from "@tanstack/react-query";
import { clientAPI } from "../api/client";
import { customDateFormatType2 } from "../utils/utils";

async function fetchAllData() {
  const [holiday, leaveType, businessCategory, guaranteeType] = await Promise.all([
    clientAPI("get", `/holiday?page=1&limit=9999`),
    clientAPI("get", `/leaveType?page=1&limit=9999`),
    clientAPI("get", `/businessCategory?page=1&limit=9999`),
    clientAPI("get", `/guaranteeType?page=1&limit=9999`),
  ]);

  return {
    holiday: holiday?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name,
      numberDay: item.numberDay,
      startDate: customDateFormatType2(item.startDate),
    })) || [],
    leaveType: leaveType?.items?.map(item => ({ 
      id: item._id,
      name: item.name,
      totalDaysAllocated: item.totalDaysAllocated,
      daysAllocated: item.daysAllocated,
    })) || [],
    businessCategory: businessCategory?.items?.map(item => ({ 
      id: item._id,
      code: item.code,
      name: item.name,
      rate: item.rate,
    })) || [],
    guaranteeType: guaranteeType?.items?.map(item => ({ 
        id: item._id,
        code: item.code,
        name: item.name ,
    })) || [],
  };
}

export function useOnLeaveCategories() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false
  });

  return {
    holiday: data?.holiday || {}, 
    leaveType: data?.leaveType || {}, 
    businessCategory: data?.businessCategory || {},
    guaranteeType: data?.guaranteeType || {},
    isLoading,
    isRefetching,
    error,
    refetch, 
  };
}
