import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconUpFile from "../../assets/images/product/iconUpFile.svg";
import plus from "../../assets/images/product/plus.svg";
import TableAdminManagement from "./tableAdminManagement";
import AddAdminManagement from "./formAdminManagement/addAdminManagement";
import toast from "react-hot-toast";
import { userGroupRolesLinksCall } from "../../api/userGroupRolesLinks";
import { useEmployeeGroupRoles } from "../../hooks/useEmployeeGroupRoles";

export default function Booking() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    employeeData,
    refetch,
    totalPages,
    setCurrentPage,
    isLoading,
    isRefetching,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useEmployeeGroupRoles();

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const tableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "",
      },
      {
        label: "Mã nhân viên",
        key: "code",
      },
      {
        label: "Tên nhân viên",
        key: "fullName",
      },
      {
        label: "Quyền truy cập",
        key: "group_role_code",
      },
    ],
    data: employeeData,
  };

  const onSubmit = async (data) => {
    const toastCreate = toast.loading("Đang thêm quyền...");
    try {
      await userGroupRolesLinksCall.create(data);
      await refetch();
      onClose();
      toast.success("Thêm thành công");
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi thêm");
    }

    toast.dismiss(toastCreate);
  };
  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý admin
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"}>
          <Button
            w={"176px"}
            h={"44px"}
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            background="var(--linear-gradient-employee)"
            _hover={{
              filter: "brightness(90%)",
            }}
            onClick={onOpen}
          >
            <Image src={plus} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Thêm admin
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>
        <TableAdminManagement
          tableData={tableData}
          totalPages={totalPages}
          isLoading={isLoading}
          isRefetching={isRefetching}
          currentPage={currentPage}
          entries={entries}
          startEntry={startEntry}
          endEntry={endEntry}
          handlePageChange={handlePageChange}
          refetch={refetch}
        />
      </Box>
      <AddAdminManagement
        isOpen={isOpen}
        onClose={onClose}
        isEdit={true}
        onSubmit={onSubmit}
        defaultValues={null}
      />
    </Box>
  );
}
