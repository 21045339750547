import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    login: {
      currentUser: null,
      isFetching: false,
      error: false,
    },
  },
  reducers: {
    loginStart: (state) => {
      state.login.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.login.isFetching = false;

      const currentUser = action.payload;
      const groupRoleCodes = currentUser?.user?.group_roles?.group_role_code;
      const roles = typeof groupRoleCodes === "string" ? groupRoleCodes.split(",") : [];
      state.login.currentUser = { ...currentUser, roles }; 
      
      state.login.error = false;
    },
    loginFailed: (state) => {
      state.login.isFetching = false;
      state.login.error = true;
    },
    loginUpdateData: (state, action) => {
      state.login.currentUser = {
        ...state.login.currentUser,
        user: {
          ...state.login.currentUser?.user,
          ...action.payload,
        },
      };
    },
    logOutSuccess: (state) => {
      state.login.isFetching = false;
      state.login.currentUser = null;
      state.login.error = false;
    },
    logOutFailed: (state) => {
      state.login.isFetching = false;
      state.login.error = true;
    },
    logOutStart: (state) => {
      state.login.isFetching = true;
    },
  },
});

export const {
  loginStart,
  loginFailed,
  loginSuccess,
  logOutStart,
  loginUpdateData,
  logOutSuccess,
  logOutFailed,
} = authSlice.actions;

export default authSlice.reducer;
