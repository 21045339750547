import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Tbody,
  Thead,
  Table,
  TableContainer,
  Tr,
  Td,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import Bell from "../../../assets/images/onleave/Frame.svg";
import { useForm } from "react-hook-form";
import { customDateTimeFormat, customDateFormat } from "../../../utils/utils";
import { leaveRequestCall } from "../../../api/leaveRequest";

const FormDetail = ({ isOpen, onClose, dataDetail, onSubmit }) => {
  const hearders = [
    {
      label: "Thời gian bắt đầu nghỉ",
      key: "dateStart",
    },
    {
      label: "Số ngày nghỉ",
      key: "number",
    },
    {
      label: "Phân loại",
      key: "type",
    },
  ];

  let month = 1;
  if(dataDetail?.startDate){
    const dateStr = dataDetail?.startDate;
    const date = new Date(dateStr);
    month = date.getMonth() + 1; 
  }

  const [remainingDaysInMonth, setRemainingDaysInMonth] = useState(0);
  useEffect(() => {
    if (dataDetail?.edit?.id) {
      leaveRequestCall
        .get(dataDetail?.edit?.id)
        .then((response) => {
          setRemainingDaysInMonth(response?.remainingDaysInMonth);
        })
        .catch((error) => {
          console.error("Error fetching detail:", error);
        });
    } else {
      console.warn("ID is undefined, cannot fetch detail.");
    }
  }, [dataDetail?.edit?.id]);

  const datas = [
    {
      dateStart: dataDetail.startDate
        ? customDateFormat(dataDetail.startDate)
        : "",
      number: dataDetail.daysRequested ? dataDetail.daysRequested : "",
      type: dataDetail.leaveType ? dataDetail.leaveType : "",
    },
  ];

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const submitHandler = (data) => {};

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex h={"625px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Chi tiết
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                m={"24px"}
              >
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={600}
                  color={"var(--color-header-employee)"}
                >
                  {dataDetail?.codeEmployee ? dataDetail?.codeEmployee : ""}-
                  {dataDetail?.employee?.fullName
                    ? dataDetail?.employee?.fullName
                    : ""}
                </Text>
                <Flex
                  flexWrap={"wrap"}
                  display={"inline"}
                  fontSize={"14px"}
                  lineHeight={"17px"}
                  fontWeight={400}
                  color={"var(--color-label-employee)"}
                >
                  Ngày tạo:
                  <Text
                    display={"inline"}
                    ml={"12px"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={500}
                    color={"var(--color-option-employee)"}
                  >
                    {dataDetail.requestDate
                      ? customDateTimeFormat(dataDetail.requestDate)
                      : ""}
                  </Text>
                </Flex>
              </Flex>
              <Box
                m={"24px auto 0"}
                w={"545px"}
                borderRadius={"8px"}
                overflow={"hidden"}
                border={"1px solid var(--border-input-employee)"}
              >
                <Text
                  padding={"16px 12px"}
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  color={"--color-header-employee"}
                  fontWeight={600}
                >
                  {dataDetail.reason ? dataDetail.reason : ""}
                </Text>
                <TableContainer w={"100%"}>
                  <Table w={"100%"} variant="unstyled">
                    <Thead
                      w={"100%"}
                      h={"35px"}
                      color="var(--color-label-employee)"
                      fontSize={"12px"}
                      fontWeight="400"
                      backgroundColor="var(--fill-avatar)"
                      borderBottom={"1px solid var(--bg-line-employee)"}
                    >
                      <Tr h={{ base: "41px" }}>
                        {hearders.map((e, index) => {
                          let width = "auto";
                          let textAlign = "auto";
                          if (e.key === "dateStart") {
                            textAlign = "start";
                          } else if (e.key === "number") {
                            textAlign = "center";
                          } else {
                            width = "33%";
                            textAlign = "end";
                          }
                          return (
                            <Td
                              key={index}
                              border={"none"}
                              w={width}
                              h={"100%"}
                              p={"10px 12px"}
                              lineHeight={"15px"}
                            >
                              <Box textAlign={textAlign}>{e.label}</Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody w={"100%"} h={"100%"}>
                      {datas?.length > 0 ? (
                        datas?.map((e, rowIndex) => {
                          const keyValues = Object.keys(e);
                          return (
                            <Tr key={rowIndex} h={"72px"}>
                              {keyValues.map((keyvalue, index) => {
                                let width = "auto";
                                let textAlign = "auto";
                                if (keyvalue === "dateStart") {
                                  textAlign = "start";
                                } else if (keyvalue === "number") {
                                  textAlign = "center";
                                } else {
                                  width = "33%";
                                  textAlign = "end";
                                }
                                return (
                                  <Td
                                    p={"16px 16px 8px 16px"}
                                    w={width}
                                    key={index}
                                    bg={
                                      rowIndex % 2 === 0
                                        ? "var(--color-main)"
                                        : "var(--fill-avatar)"
                                    }
                                  >
                                    <Box
                                      fontSize={{ base: "14px" }}
                                      lineHeight={"19.6px"}
                                      textAlign={textAlign}
                                      fontWeight={500}
                                      color={"#293755"}
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      display="-webkit-box"
                                      sx={{
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      <Text>{e[keyvalue]}</Text>
                                    </Box>
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td
                            colSpan={hearders.length}
                            textAlign="center"
                            padding={"40px 0"}
                          >
                            Không có dữ liệu
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              {
                remainingDaysInMonth ?
                <Flex
                m={"24px 0"}
                padding={"12px 0"}
                bg={"var(--fill-avatar)"}
                alignItems={"center"}
                gap={"12px"}
                flexWrap={"wrap"}
                justifyContent={"center"}
              >
                <Image src={Bell} />
                <Text
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"17px"}
                  color={"var(--color-label-employee)"}
                >
                  số ngày {dataDetail?.leaveType.toLowerCase()} còn lại trong tháng {month} là:
                </Text>
                <Text
                  fontSize={"16px"}
                  fontWeight={"600"}
                  lineHeight={"19px"}
                  color={"var(--color-option-employee-hover)"}
                >
                  {remainingDaysInMonth} ngày
                </Text>
              </Flex>
              :""
              }
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              <Button
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--linear-gradient-employee)"}
                onClick={onClose}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Hủy
              </Button>
              <Button
                // type="submit"
                onClick={onClose}
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--linear-gradient-red)"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                Xong
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormDetail;
