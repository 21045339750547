import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import TableOnLeave from "./tableOnLeave";

export default function Onleave() {
  
  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý nghỉ phép
          </Text>
        </Flex>
        <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
                filter: "brightness(90%)",
            }}
            >
            <Image src={iconExcel} />
            <Text
                fontSize={{ base: "15px" }}
                fontWeight={600}
                color="var(--color-main)"
            >
                Xuất excel
            </Text>
            </Button>
      </Flex>
      <Box mt={{ base: "23px" }}><TableOnLeave /></Box>
    </Box>
  );
}