import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { NumberOfPerPage } from "../utils/globalVariable";
import { customDateFormat, formatDateToLocal } from "../utils/utils";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.onLeave;
const queryKey = "onLeave";

async function fetchAllData() {
  const [leaveType] = await Promise.all([
    clientAPI("get", `/leaveType?page=1&limit=9999`),
  ]);

  return {
    leaveType:
      leaveType?.items?.map((item) => ({
        value: item.code,
        name: item.name,
        daysAllocated: item.daysAllocated,
      })) || [],
  };
}

async function fetchOnLeave(
  currentPage,
  search,
  requestDateFrom,
  requestDateTo
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (requestDateFrom !== undefined)
      options.requestDateFrom = requestDateFrom;
    if (requestDateTo !== undefined) options.requestDateTo = requestDateTo;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/leaveRequest/?${queryParams}`);
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        requestDate: item?.requestDate || "-",
        codeEmployee: item?.employeeObject?.code || "-",
        employee: {
          fullName: item?.employeeObject?.fullName || "",
          jobPosition: item?.employeeObject?.jobPosition?.name || "_",
        },
        startDate: item?.startDate || "-",
        daysRequested: item?.daysRequested || "-",
        leaveType: item?.leaveType?.name || "-",
        reason: item?.reason || "-",
        approved: {
          fullName: item?.approverObject?.fullName || "_",
          jobPosition: item?.approverObject?.jobPosition?.name || "_",
        },
        approvedDate: item?.approvedDate || "-",
        edit: {
          id: item?._id || "",
          startDate: item?.startDate
            ? new Date(item?.startDate).toISOString().split("T")[0]
            : "",
          reason: item?.reason || "",
          leaveType: item?.leaveType?.code || "",
          daysRequested: item?.daysRequested || "",
          requestDate: item?.requestDate || "",
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching onLeave data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useOnLeave(search, dateFrom, dateTo) {
  const [currentPage, setCurrentPage] = useState(1);
  const requestDateFrom = dateFrom ? formatDateToLocal(dateFrom) : "";
  const requestDateTo = dateTo ? formatDateToLocal(dateTo) : "";
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage, search, requestDateFrom, requestDateTo],
    queryFn: () =>
      fetchOnLeave(currentPage, search, requestDateFrom, requestDateTo),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching onLeave data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    onLeaveData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}

export function useOnLeaveType() {
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: ["allData"],
    queryFn: fetchAllData,
    refetchOnWindowFocus: false,
  });

  return {
    leaveTypeData: data?.leaveType || null,
    isLoading,
    isRefetching,
    error,
    refetch,
  };
}
