import React, { useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTab } from "../../contexts/useTab";
import OrganizationalInfor from "./organizationalInfor";
import OrganizationalStructure from "./organizationalStructure";
import { ROLES_CONFIG } from "../../utils";
import { useSelector } from "react-redux";

export default function Employee() {
  const { personnelCategoriesTab, setPersonnelCategoriesTab } = useTab();
  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const tabEmployee = [
    {
      title: "Chức danh & Phòng ban & Chi nhánh",
      content: <OrganizationalInfor />,
      role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB.TAB_CD_AND_PB_AND_CN.role,
    },
    {
      title: "Khối bộ phận & Vùng & Khối",
      content: <OrganizationalStructure />,
      role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB.TAB_KBP_AND_V_AND_K.role,
    },
  ];

    // Filter tabs based on roles using useMemo to avoid recalculations
    const filteredTabs = useMemo(
      () =>
        tabEmployee.filter((tab) =>
          tab.role.some((role) => roles.includes(role) || roles.includes("ALL"))
        ),
      [tabEmployee, roles]
    );
  
    // Ensure personnelCategoriesTab is within the range of filteredTabs
    const activeTab = filteredTabs[personnelCategoriesTab] || filteredTabs[0];

  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Danh mục nhân viên
          </Text>
          <Flex
            justifyContent={{ base: "space-around", xl: "flex-start" }}
            gap={{ base: "24px" }}
          >
            {filteredTabs.map((e, index) => {
              return (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  key={index}
                  onClick={() => setPersonnelCategoriesTab(index)}
                  cursor={"pointer"}
                  padding={"12px 5px 0px 5px"}
                  zIndex={"10"}
                >
                  <Text
                    position={"relative"}
                    paddingBottom={"8px"}
                    _before={{
                      content: '""',
                      position: "absolute",
                      bottom: "-1px",
                      left: 0,
                      width: "100%",
                      height: {
                        base: personnelCategoriesTab === index ? "2px" : "0",
                      },
                      backgroundColor: "var(--color-secondary)",
                    }}
                    fontSize={{
                      base: "16px",
                    }}
                    fontWeight={600}
                    lineHeight={"normal"}
                    fontFamily="var(--font-heading-main)"
                    color={
                      personnelCategoriesTab === index
                        ? "var(--color-secondary)"
                        : "#9D9D9D"
                    }
                    textAlign={"center"}
                    whiteSpace="nowrap"
                  >
                    {e?.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Box mt={{ base: "23px" }}>{activeTab?.content}</Box>
    </Box>
  );
}
