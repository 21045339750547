import React, { useState, useEffect } from "react";
import { Box, Checkbox, VStack } from "@chakra-ui/react";

const TreeCheckbox = ({ node, selectedRoles, toggleRole, defaultExpandedRoles }) => {
  // Kiểm tra xem quyền cha có được tích chọn đầy đủ hay không
  const isChecked =
    selectedRoles.includes(node.code) &&
    node.child.every((child) => selectedRoles.includes(child.code));
  const isIndeterminate =
    selectedRoles.includes(node.code) &&
    !isChecked;

  // Đệ quy kiểm tra xem có bất kỳ quyền con nào được chọn không
  const hasSelectedDescendant = (node) => {
    if (!node.child) return false;
    return node.child.some(
      (child) =>
        selectedRoles.includes(child.code) ||
        hasSelectedDescendant(child)
    );
  };

  // Khởi tạo `showChildren` để mở rộng nếu có quyền con nào được chọn, ở bất kỳ cấp độ nào
  const [showChildren, setShowChildren] = useState(
    defaultExpandedRoles.includes(node.code) || hasSelectedDescendant(node)
  );

  const handleToggle = () => {
    toggleRole(node.code, getChildCodes(node));
    setShowChildren((prev) => !prev);
  };

  // Cập nhật `showChildren` khi `selectedRoles` thay đổi để hiển thị các quyền con được chọn
  useEffect(() => {
    if (hasSelectedDescendant(node)) {
      setShowChildren(true); // Mở rộng nhánh nếu có bất kỳ quyền con nào được chọn
    }
  }, [selectedRoles, node]);

  return (
    <VStack align="start" spacing={2} ml={node.level > 0 ? 4 : 0}>
      <Checkbox
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        onChange={handleToggle}
      >
        {node.label}
      </Checkbox>
      {node.child && showChildren && (
        <VStack align="start" spacing={2} pl={5}>
          {node.child.map((childNode) => (
            <TreeCheckbox
              key={childNode.code}
              node={childNode}
              selectedRoles={selectedRoles}
              toggleRole={toggleRole}
              defaultExpandedRoles={defaultExpandedRoles}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
};

// Lấy tất cả mã quyền con của quyền cha
const getChildCodes = (node) => {
  let codes = [];
  if (node.child) {
    node.child.forEach((child) => {
      codes.push(child.code);
      codes = codes.concat(getChildCodes(child));
    });
  }
  return codes;
};

export default TreeCheckbox;
