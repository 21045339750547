import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Box, Image } from "@chakra-ui/react";
import iconMap from "../../assets/images/timeKeeping/x-circle.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapContainerStyle = {
  width: "80vw",
  height: "80vh",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 10000,
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

const libraries = ["places"];

const PopUpGoogleMap = ({ onMapClick, onClose }) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [inputSearch, setInputSearch] = useState(null);
  const inputRef = useRef(null);
  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GOOGLE_MAP,
    libraries: libraries,
  });

  const resetMapState = () => {
    setCurrentPosition(null);
    setSelectedPosition(null);
    setLocationName("");
    getCurrentLocation();
  };

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const options = { fields: ["geometry", "name", "formatted_address"] };
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      setPlaceAutocomplete(autocomplete);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          setSelectedPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          getLocationName(
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            (address) => {
              onMapClick(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
                address
              );

              if (mapRef.current) {
                const latLng = new window.google.maps.LatLng(
                  place.geometry.location.lat(),
                  place.geometry.location.lng()
                );
                mapRef.current.panTo(latLng);
              }
            }
          );
        }
      });
    }
  }, [isLoaded, inputSearch]);

  const handleInputChange = () => {
    const value = inputRef.current.value;
    setInputSearch(value);
    if (value && placeAutocomplete) {
      placeAutocomplete.setBounds(new window.google.maps.LatLngBounds());
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error getting location", error);
      }
    );
  };

  const getLocationName = (lat, lng, callback) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setLocationName(results[0].formatted_address);
        callback(results[0].formatted_address);
      } else {
        callback("Không tìm thấy tên địa điểm");
      }
    });
  };

  useEffect(() => {
    resetMapState();
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Box>
      {currentPosition && (
        <Box position={"relative"}>
          <Box style={overlayStyle} onClick={onClose}></Box>
          <Box style={mapContainerStyle}>
            <Box
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                color: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10001,
              }}
              onClick={onClose}
            >
              <Image src={iconMap} />
            </Box>
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={containerStyle}
              center={currentPosition}
              onLoad={(map) => {
                mapRef.current = map; 
              }}
              zoom={15}
              onClick={(e) => {
                const lat = e.latLng.lat();
                const lng = e.latLng.lng();
                setSelectedPosition({ lat, lng });
                getLocationName(lat, lng, (address) => {
                  onMapClick(lat, lng, address);
                });
              }}
            >
              {selectedPosition && <Marker position={selectedPosition} />}
            </GoogleMap>
            <Box
              style={{
                position: "absolute",
                top: "10px",
                left: "50%",
                zIndex: 10001,
                background: "white",
                borderRadius: "5px",
                transform: "translateX(-50%)",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Nhập địa chỉ cần tìm."
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PopUpGoogleMap;
