import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import FormFeature from "./form/formFeature";
import { usePersonnelStructure } from "../../../hooks/usePersonnelStructure";
import { ROLES_CONFIG } from "../../../utils";
import { useSelector } from "react-redux";

const OrganizationalStructure = () => {
  const {
    department,
    saleArea,
    saleBlock,
    saleAreaSelect,
    businessCategory,
    businessCategorySelect,
    refetch,
    isLoading,
    isRefetching,
  } = usePersonnelStructure();

  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = (requiredRoles) => {
    return (
      requiredRoles.some((role) => roles.includes(role)) ||
      roles.includes("ALL")
    );
  };

  const gridItemsData = [
    {
      title: "Danh sách khối bộ phận",
      button: "Thêm khối bộ phận",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên khối bộ phận", key: "name" },
        ],
        data: department,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
          .TAB_KBP_AND_V_AND_K.CHILDREN_TAB.CHILDREN_TAB_KBP.role,
      },
      type: 0,
      rowSpan: 2,
      colSpan: 1,
    },
    {
      title: "Danh sách vùng",
      button: "Thêm vùng",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên vùng", key: "name" },
        ],
        data: saleArea,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
        .TAB_KBP_AND_V_AND_K.CHILDREN_TAB.CHILDREN_TAB_V.role,
      },
      type: 1,
      rowSpan: 2,
      colSpan: 1,
    },
    {
      title: "Danh sách khối",
      button: "Thêm khối",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Tên khối", key: "name" },
        ],
        data: saleBlock,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
        .TAB_KBP_AND_V_AND_K.CHILDREN_TAB.CHILDREN_TAB_K.role,
      },
      type: 2,
      dataSelect: saleAreaSelect,
    },
    {
      title: "Phân loại NVKD",
      button: "Thêm phân loại NVKD",
      data: {
        headers: [
          { label: "", key: "" },
          { label: "STT", key: "" },
          { label: "Loại nhân viên kinh doanh", key: "name" },
          { label: "Tỷ lệ ưu đãi", key: "rate" },
        ],
        data: businessCategory,
        role: ROLES_CONFIG.MENU.MENU_QLDM.CHILDREN.CHILDREN_NS.TAB
        .TAB_KBP_AND_V_AND_K.CHILDREN_TAB.CHILDREN_TAB_PLNVKD.role,
      },
      type: 3,
      formTable: true,
      dataSelect: businessCategorySelect,
    },
  ];

  return (
    <Grid
      minH={"80vh"}
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(2, 1fr)"
      gap={4}
    >
      {gridItemsData.map(
        (item, index) =>
          hasPermission(item.data.role) && (
            <GridItem
              key={index}
              rowSpan={item.rowSpan || 1}
              colSpan={item.colSpan || 1}
              p={"16px"}
              bg={"var(--color-main)"}
              borderRadius={"16px"}
            >
              <FormFeature
                title={item.title}
                button={item.button}
                data={item.data}
                isLoading={isLoading}
                isRefetching={isRefetching}
                refetch={refetch}
                type={item.type}
                dataSelect={item.dataSelect}
                formTable={item.formTable}
              />
            </GridItem>
          )
      )}
    </Grid>
  );
};

export default OrganizationalStructure;
