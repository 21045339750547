import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
// import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import { InputControl, SelectControl } from "../../components/form/formControls";
import iconEdit from "../../assets/images/product/edit.svg";
import iconAdd from "../../assets/images/booking/plus-square.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import { useForm } from "react-hook-form";
import AddOpenForSale from "./formOpenForSale/addOpenForSale";
import AddProduct from "./formOpenForSale/addProduct";

const TableApartments = ({tableData}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: "",
      typeApartment: "",
    },
  });
  // table
  const [search, setSearch] = useState("");
  const [inputColors, setInputColors] = useState({
    dateStart: "var(--color-text-unclick)",
    dateEnd: "var(--color-text-unclick)",
  });
  const [popupDelete, setPopupDelete] = useState(false);
  const [isAddBooking, setIsAddBooking] = useState(false);
  // const [isEditBooking, setIsEditBooking] = useState(false);
  const [focusStates, setFocusStates] = useState({});

  // const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const saleAreas = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ]

  const handlePopupDelete = () => {
    setPopupDelete(true);
    onOpen();
  }
  
  const handlePopup = (type) => {
    setIsAddBooking(type);
    setPopupDelete(false);
    onOpen();
  }
      
  const handleFocus = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("project", option);
  };

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;

    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };

  return (
    <>
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={{ base: "32px" }}
          p={"16px 16px 12px 16px"}
        >
            <Flex alignItems={"center"} gap={{ base: "32px" }} flex={"1"}  flexDirection={{ base: "column", xl: "row" }}>
                <InputGroup
                    maxW="506px"
                    borderRadius={{ base: "8px" }}
                    w={{ base: "100%" }}
                >
                    <Input
                    placeholder="Nhập Mã ĐNBK,tên,..."
                    type="text"
                    borderRadius={{ base: "8px" }}
                    border={{ base: "1px solid var(--color-boder)" }}
                    onChange={onChangeSearch}
                    />
                    <InputRightElement
                    borderTopRightRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                    pointerEvents="none"
                    bg="var(--color-boder)"
                    cursor={"pointer"}
                    >
                    <SearchIcon color="var(--color-black)" />
                    </InputRightElement>
                </InputGroup>
                <Flex alignItems={"center"} gap={{ base: "32px", lg: "24px" }}>
                    <InputControl 
                        label={"Từ ngày"}
                        type="date"
                        bdTop= {true}
                        colorIp={inputColors.dateStart}
                        state={focusStates["dateStart"]}
                        onFocus={() => handleFocus("dateStart")}
                        onBlur={() => handleBlur("dateStart")}
                        placeholder="Từ ngày"
                        onChange={(e) => handleDateChange(e, "dateStart")}
                    />
                    <InputControl 
                        label={"Đến ngày"}
                        type="date"
                        bdTop= {true}
                        colorIp={inputColors.dateEnd}
                        state={focusStates["dateEnd"]}
                        onFocus={() => handleFocus("dateEnd")}
                        onBlur={() => handleBlur("dateEnd")}
                        placeholder="Đến ngày"
                        onChange={(e) => handleDateChange(e, "dateEnd")}
                    />
                    <SelectControl
                        bdTop= {true}
                        label="Dự án"
                        dataOption={saleAreas}
                        placeholder="Chọn dự án"
                        selectedMain={watch("project")}
                        onChange={onchangeProject}
                        onFocus={() => handleFocus("project")}
                        onBlur={() => handleBlur("project")}
                        state={focusStates["project"]}
                    />
                </Flex>
            </Flex>
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {tableData.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "") {
                        width = "2%";
                        textAlign = "center";
                    } else if (e.key === "code") {
                        width = "5%";
                        textAlign = "start";
                    } else if (e.key === "nameOpenForSale") {
                        width = "10%";
                        textAlign = "start";
                    } else if (e.key === "nameProject") {
                        width = "10%";
                        textAlign = "start";
                    } else if (e.key === "product") {
                        width = "10%";
                        textAlign = "start";
                    } else if (e.key === "price") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "basedSalary") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "timeDelivery" || e.key === "timeStart" || e.key === "timeEnd" || e.key === "dateCreate") {
                      width = "5%";
                      textAlign = "end";
                    } 
                    else {
                      width = "10%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {tableData.data?.length > 0 ? (
                  tableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"}>
                        <Td p={"16px 0px 8px 16px"} w={"2%"} bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"}>
                            <Menu>
                            <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                                p={0}
                            >
                                <AiOutlineMore fontSize={"20px"} w="20px" />
                            </MenuButton>
                            <MenuList
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                lineHeight={"140%"}
                                color={"var(--color-info-employee)"}
                            >
                                <MenuItem
                                    gap={"16px"}
                                    onClick={()=>handlePopup(true)}
                                >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                </MenuItem>
                                <MenuItem
                                    gap={"16px"}
                                    onClick={()=>handlePopup(false)}
                                >
                                    <Image src={iconAdd} /> Thêm sản phẩm
                                </MenuItem>
                                <MenuItem
                                    gap={"16px"}
                                    onClick={handlePopupDelete}
                                >
                                    <Image src={icontrash} /> Xóa đợt mở bán
                                </MenuItem>
                            </MenuList>
                            </Menu>
                        </Td>
                        {keyValues.map((keyvalue, index) => {
                            let width = "auto";
                            let widthText = "100%";
                            let textAlign = "auto";
                            let whiteSpace = "inherit";
                            if (keyvalue === "") {
                                width = "2%";
                                textAlign = "center";
                            } else if (keyvalue === "code") {
                                width = "5%";
                                textAlign = "start";
                            } else if (keyvalue === "nameOpenForSale") {
                                width = "10%";
                                textAlign = "start";
                            } else if (keyvalue === "nameProject") {
                                width = "10%";
                                textAlign = "start";
                                whiteSpace = "normal";
                                widthText = "118px";
                            } else if (keyvalue === "product") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "price") {
                                width = "10%";
                                textAlign = "start";
                              } else if (keyvalue === "basedSalary") {
                                width = "10%";
                                textAlign = "start";
                              } 
                              else if (keyvalue === "timeDelivery" || keyvalue === "timeStart" || keyvalue === "timeEnd" || keyvalue === "dateCreate") {
                                width = "5%";
                              } 
                              else {
                                  width = "10%";
                                  textAlign = "end";
                                  whiteSpace = "inherit";
                              }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index} bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"}>
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                color={"var(--color-info-employee)"}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}  
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={tableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>Showing 1 to 10 of 12 entries</Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={8}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={8}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />}
                  nextLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />}
                  renderOnZeroPageCount={null}
                  initialPage={8 - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
    {
    !popupDelete ? 
        isAddBooking ?
          <AddOpenForSale
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            defaultValues={{
              nameOpenForSale: '1',
              // dateStart: '22/09/2024',
              // dateEnd: '22/09/2024',
              price: "1000",
              basedSalary: "1000",
              employeeDelivery: "Giang Văn Đạt",
              // timeDelivery: '22/09/2024',
            }}
            onSubmit={() => {console.log("dat")}}
          />
            :
            <AddProduct
                isOpen={isOpen}
                onClose={onClose}
                isEdit={true}
                onSubmit={()=>{console.log("dat")}}
            />
        :
        <DeleteDialog 
          isOpen={isOpen}
          onClose={onClose}
          action={()=>{console.log("oke")}}
          title="Xóa đợt nhận booking"
          description= {<Text display={"inline"}>"Bạn có chắc chắn muốn xóa đợt mở bán <Text fontWeight={"600"} display={"inline"}>Tên đợt mở bán</Text> này không?"</Text>} 
        />
    }
    </>
  );
};

export default TableApartments;