import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  InputControl,
  SelectControl,
} from "../../../../components/form/formControls";
import { generateCodeFromName, removeAccents } from "../../../../utils/utils";
import { Switch } from "@chakra-ui/react";

const schemaType1 = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập nhập tên khối bộ phận"),
  code: Yup.string().required("Vui lòng nhập mã khối bộ phận"),
});

const schemaType2 = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên vùng"),
  code: Yup.string().required("Vui lòng nhập mã vùng"),
});

const schemaType3 = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên khối"),
  code: Yup.string().required("Vui lòng nhập mã khối"),
  saleAreaId: Yup.string().required("Vui lòng chọn vùng"),
});

const schemaType4 = Yup.object().shape({
  code: Yup.string().required("Vui lòng nhập mã phân loại"),
  name: Yup.string().required("Vui lòng nhập tên phân loại"),
  rate: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() === "") {
        return null;
      }
      return Number(originalValue);
    })
    .typeError("Vui lòng nhập số")
    .required("Vui lòng nhập tỉ lệ ưu đãi")
    .min(0, "Tỉ lệ phải lớn hơn hoặc bằng 0")
    .max(100, "Tỉ lệ phải nhỏ hơn hoặc bằng 100"),
  is_related_other_type: Yup.boolean().default(false),
  min_working_days: Yup.number()
  .nullable()
  .transform((value, originalValue) => {
    if (typeof originalValue === "string" && originalValue.trim() === "") {
      return null;
    }
    const parsedValue = parseFloat(originalValue);
    return isNaN(parsedValue) ? undefined : parsedValue; 
  })
  .when("is_related_other_type", {
    is: true,
    then: (schema) =>
      schema
        .required("Vui lòng nhập số ngày làm việc tối thiểu")
        .moreThan(0, "Số ngày làm việc tối thiểu phải lớn hơn 0"),
    otherwise: (schema) => schema.notRequired().nullable(),
  })
  .typeError("Số ngày làm việc tối thiểu phải là một số hợp lệ"),
  related_to: Yup.string()
    .nullable()
    .when("is_related_other_type", {
      is: true,
      then: (schema) => schema.required("Vui lòng nhập mã liên quan"),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
});

const FormCustom = ({
  isOpen,
  onClose,
  type,
  data,
  defaultValues,
  onSubmit,
  isEdit,
  dataSelect,
}) => {
  const [focusStates, setFocusStates] = useState({});
  const [isCodeEdited, setIsCodeEdited] = useState(false);
  const [checkedSwitch, setCheckedSwitch] = useState(
    defaultValues?.is_related_other_type || false
  );

  if (Array.isArray(dataSelect) && type === 3) {
    dataSelect = dataSelect.filter(
      (item) => item?.value !== defaultValues?.id
    );
  }
  
  let schema = null;
  if (type === 0) {
    schema = schemaType1;
  } else if (type === 1) {
    schema = schemaType2;
  } else if (type === 2) {
    schema = schemaType3;
  } else {
    schema = schemaType4;
  }

  const {
    reset,
    register,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
  });

  useEffect(() => {
    if (isOpen) {
      if (defaultValues?.is_related_other_type) {
        setCheckedSwitch(defaultValues?.is_related_other_type || false);
      } else {
        setCheckedSwitch(false);
      }
      if (isEdit) {
        reset(defaultValues);
      } else {
        reset(
          dataSelect
            ? type === 3
              ? {
                  code: "",
                  name: "",
                  rate: "",
                }
              : {
                  code: "",
                  name: "",
                  saleAreaId: "",
                }
            : {
                code: "",
                name: "",
              }
        );
      }
    }
  }, [isOpen, isEdit, defaultValues, reset]);

  useEffect(() => {
    if (type === 3) {
      const name = watch("name");
      const nameWithoutAccents = removeAccents(name || "");
      const generatedCode = generateCodeFromName(nameWithoutAccents);

      if (!isCodeEdited && name) {
        setValue("code", generatedCode, {
          shouldValidate: false,
          shouldDirty: false,
        });
        clearErrors("code");
      }

      if (name === "") {
        setValue("code", "", { shouldValidate: false, shouldDirty: false });
      }
    }
  }, [watch("name"), isCodeEdited, setValue]);

  const submitHandler = (data) => {
    onSubmit(data);
  };

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
    if (type === 3) {
      if (field === "code") {
        setIsCodeEdited(true);
      } else if (field === "name") {
        setIsCodeEdited(false);
      }
    }
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeSaleArea = (option) => {
    setValue("saleAreaId", option);
  };

  const onchangeRelatedTo = (option) => {
    setValue("related_to", option);
  };

  const handleCodeChange = (e) => {
    setValue("code", e.target.value, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"max-content"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {isEdit
                  ? type === 0
                    ? "Chỉnh sửa khối bộ phận"
                    : type === 1
                    ? "Chỉnh sửa vùng"
                    : type === 2
                    ? "Chỉnh sửa khối"
                    : "Chỉnh sửa phân loại nhân viên kinh doanh"
                  : type === 0
                  ? "Khối bộ phận mới"
                  : type === 1
                  ? "Vùng mới"
                  : type === 2
                  ? "Khối mới"
                  : "Phân loại nhân viên kinh doanh mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1}>
              <Box
                m={"24px auto 0"}
                w={"545px"}
                borderRadius={"8px"}
                overflow={"hidden"}
              >
                {(() => {
                  switch (type) {
                    case 0:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                        >
                          <InputControl
                            label="Mã khối bộ phận"
                            placeholder="Nhập mã khối bộ phận"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên khối bộ phận"
                            placeholder="Nhập tên khối bộ phận"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    case 1:
                      return (
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          gap={"27px"}
                        >
                          <InputControl
                            label="Mã vùng"
                            placeholder="Nhập mã vùng"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            mwidth="100%"
                          />
                          <InputControl
                            label="Tên vùng"
                            placeholder="Nhập tên vùng"
                            errors={errors.name}
                            register={register("name")}
                            onFocus={() => handleFocus("name")}
                            onBlur={() => handleBlur("name")}
                            state={focusStates["name"]}
                            mwidth="100%"
                          />
                        </Flex>
                      );
                    case 2:
                      return (
                        <>
                          <Flex
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            gap={"27px"}
                            mb={"16px"}
                          >
                            <InputControl
                              label="Mã khối"
                              placeholder="Nhập mã khối"
                              errors={errors.code}
                              register={register("code")}
                              onFocus={() => handleFocus("code")}
                              onBlur={() => handleBlur("code")}
                              state={focusStates["code"]}
                              mwidth="100%"
                            />
                            <InputControl
                              label="Tên khối"
                              placeholder="Nhập tên khối"
                              errors={errors.name}
                              register={register("name")}
                              onFocus={() => handleFocus("name")}
                              onBlur={() => handleBlur("name")}
                              state={focusStates["name"]}
                              mwidth="100%"
                            />
                          </Flex>
                          {dataSelect && (
                            <SelectControl
                              label="Vùng"
                              dataOption={dataSelect}
                              placeholder="Chọn vùng"
                              selectedMain={watch("saleAreaId")}
                              onChange={onchangeSaleArea}
                              onFocus={() => handleFocus("saleAreaId")}
                              onBlur={() => handleBlur("saleAreaId")}
                              state={focusStates["saleAreaId"]}
                              registerProps={register("saleAreaId")}
                              errorMessage={errors.saleAreaId}
                              mwidth="100%"
                            />
                          )}
                        </>
                      );
                    default:
                      return (
                        <>
                          <InputControl
                            label="Mã phân loại"
                            placeholder="Nhập mã phân loại"
                            errors={errors.code}
                            register={register("code")}
                            onFocus={() => handleFocus("code")}
                            onBlur={() => handleBlur("code")}
                            state={focusStates["code"]}
                            onChange={handleCodeChange}
                            mwidth="100%"
                          />
                          <Flex
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            gap={"27px"}
                            mt={"16px"}
                          >
                            <InputControl
                              label="Phân loại"
                              placeholder="Nhập tên phân loại"
                              errors={errors.name}
                              register={register("name")}
                              onFocus={() => handleFocus("name")}
                              onBlur={() => handleBlur("name")}
                              state={focusStates["name"]}
                              mwidth="100%"
                            />
                            <InputControl
                              //   type="number"
                              label="Tỷ lệ ưu đãi"
                              placeholder="Nhập tỷ lệ ưu đãi"
                              errors={errors.rate}
                              register={register("rate")}
                              onFocus={() => handleFocus("rate")}
                              onBlur={() => handleBlur("rate")}
                              state={focusStates["rate"]}
                              mwidth="100%"
                            />
                          </Flex>
                          <Box mt={"16px"}>
                            <Text
                              lineHeight={"15px"}
                              m="0"
                              fontSize={{ base: "12px" }}
                              mb={"6px"}
                              fontWeight={400}
                              color={"var(--color-label-employee)"}
                            >
                              Liên kết với loại nhân viên kinh doanh
                            </Text>
                            <Switch
                              {...register("is_related_other_type")}
                              checked={checkedSwitch}
                              onChange={(e) =>
                                setCheckedSwitch(e.target.checked)
                              }
                            />
                          </Box>

                          {checkedSwitch && (
                            <Flex
                              justifyContent={"space-between"}
                              alignItems={"flex-start"}
                              gap={"27px"}
                              mt={"16px"}
                            >
                              <InputControl
                                label="Số ngày công tối thiểu"
                                placeholder="Nhập số ngày công tối thiểu"
                                errors={errors.min_working_days}
                                register={register("min_working_days")}
                                onFocus={() => handleFocus("min_working_days")}
                                onBlur={() => handleBlur("min_working_days")}
                                state={focusStates["min_working_days"]}
                              />
                              <SelectControl
                                label="NVKD liên kết"
                                dataOption={dataSelect}
                                placeholder="Chọn NVKD liên kết"
                                selectedMain={watch("related_to")}
                                onChange={onchangeRelatedTo}
                                onFocus={() => handleFocus("related_to")}
                                onBlur={() => handleBlur("related_to")}
                                state={focusStates["related_to"]}
                                registerProps={register("related_to")}
                                errorMessage={errors.related_to}
                              />
                            </Flex>
                          )}
                        </>
                      );
                  }
                })()}
              </Box>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              {isEdit ? (
                <>
                  <Button
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-option-employee-hover)"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    bg={"var(--color-main)"}
                    onClick={onClose}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    type="submit"
                    w={"112px"}
                    h={"44px"}
                    lineHeight={"18px"}
                    fontSize={"15px"}
                    fontWeight={600}
                    color={"var(--color-main)"}
                    bg={"var(--linear-gradient-employee)"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    Lưu
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  padding={"13px 20px"}
                  lineHeight={"18px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  h={"44px"}
                  color={"var(--color-main)"}
                  bg={"var(--linear-gradient-employee)"}
                  _hover={{
                    filter: "brightness(90%)",
                  }}
                >
                  {type === 0
                    ? "Tạo khối bộ phận"
                    : type === 1
                    ? "Tạo vùng"
                    : type === 2
                    ? "Tạo khối"
                    : "Tạo phân loại nhân viên kinh doanh"}
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormCustom;
