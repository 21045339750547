import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";
import { formatDate, formatDateToLocal } from "../utils/utils";
import { formatDateDb, formatTimeDb } from "../utils/tools";
import { NumberOfPerPage } from "../utils/globalVariable";

const NUMBER_NFT_PER_PAGE = NumberOfPerPage.checkIn;
const queryKey = "checkIn";

async function fetchCheckIn(
  currentPage,
  search,
  saleBlockId,
  saleAreaId,
  fromDate,
  toDate,
  checkinTimeSort,
  fullNameSort,
  codeSort
) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };

    if (search !== undefined) options.search = search;
    if (saleBlockId) options.saleBlockId = saleBlockId;
    if (saleAreaId) options.saleAreaId = saleAreaId;
    if (fromDate) options.fromDate = formatDate(fromDate);
    if (toDate) options.toDate = formatDate(toDate);
    if (checkinTimeSort) options.checkinTimeSort = checkinTimeSort;
    if (fullNameSort) options.fullNameSort = fullNameSort;
    if (codeSort) options.codeSort = codeSort;

    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI(
      "get",
      `/checkin/report/list-checkin?${queryParams}`
    );
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => {
      return {
        dateTime: item?.checkinTime ? formatDateDb(item?.checkinTime) : "-",
        checkinTime: item?.checkinTime ? formatTimeDb(item?.checkinTime) : "-",
        code: item?.employeeObject?.code || "-",
        fullName: item?.employeeObject?.fullName || "-",
        name: item?.checkinPlaceObject?.name || "-",
        workingDayRate: item?.workingDayRate || "-",
        reason: item?.comment || "-",
        edit: {
          _id: item?._id || "",
          dateTime: item?.checkinTime ? formatDateDb(item?.checkinTime, 1) : "",
          checkinTime: item?.checkinTime
            ? formatTimeDb(item?.checkinTime, 1)
            : "",
          code: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
          name: item?.checkinPlaceObject?.name || "",
          workingDayRate: item?.workingDayRate || "",
          reason: item?.comment || "",
        },
        detail: {
          documentId: item?._id || "",
          code: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
        },
        export: {
          dateTime: item?.checkinTime ? formatDateDb(item?.checkinTime) : "",
          checkinTime: item?.checkinTime ? formatTimeDb(item?.checkinTime) : "",
          code: item?.employeeObject?.code || "",
          fullName: item?.employeeObject?.fullName || "",
          name: item?.checkinPlaceObject?.name || "",
          workingDayRate: item?.workingDayRate || "",
          reason: item?.comment || "",
        },
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

async function fetchCheckinByMonth(month, employeeId) {
  try {
    const options = {};

    if (month) options.date = month;
    if (employeeId) options.employeeId = employeeId;
    const id = employeeId || '66ff210dd24c7fb43f20ceae';
    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI(
      "get",
      `/checkin/report/${id}/by-month?${queryParams}`
    );
   
    const newData = data?.items.map((item, index) => {
      return {
        dateTime: item?.checkinTime ? formatDateDb(item?.checkinTime) : "-",
        checkinTime: item?.checkinTime ? formatTimeDb(item?.checkinTime) : "-",
        code: item?.employeeObject?.code || "-",
        fullName: item?.employeeObject?.fullName || "-",
        name: item?.checkinPlaceObject?.name || "-",
        workingDayRate: item?.workingDayRate || "-",
        reason: item?.comment || "-",
      };
    });

    return { data: newData };
  } catch (error) {
    console.error("Error fetching checkIn data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useCheckIn(
  search,
  saleBlockId,
  saleAreaId,
  dateFrom,
  dateTo,
  checkinTimeSort,
  fullNameSort,
  codeSort
) {
  const [currentPage, setCurrentPage] = useState(1);
  const fromDate = dateFrom ? formatDateToLocal(dateFrom) : "";
  const toDate = dateTo ? formatDateToLocal(dateTo) : "";
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      queryKey,
      currentPage,
      search,
      saleBlockId,
      saleAreaId,
      fromDate,
      toDate,
      checkinTimeSort,
      fullNameSort,
      codeSort
    ],
    queryFn: () =>
      fetchCheckIn(
        currentPage,
        search,
        saleBlockId,
        saleAreaId,
        fromDate,
        toDate,
        checkinTimeSort,
        fullNameSort,
        codeSort
      ),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkIn data:", error);
    },
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    checkInData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}

export function useCheckinByMonth(month, employeeId) { 
  const { data } = useQuery({
    queryKey: [month, employeeId],
    queryFn: () =>
      fetchCheckinByMonth(month, employeeId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching checkinByMonth data:", error);
    },
  });

  return {
    checkinData: data?.data,
  };
}
