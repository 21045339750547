import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import logoMain from "../../assets/images/sider/logo-main.png";
import { enumMenu } from "../../utils/contants";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sider = () => {
  const [activeItems, setActiveItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.login?.currentUser);
  const roles = user?.roles || [];

  const hasPermission = useCallback(
    (itemRoles) => itemRoles?.some((role) => roles.includes(role)) || roles.includes("ALL"),
    [roles]
  );

  const handleToggle = useCallback((item) => {
    setActiveItems((prev) => (prev === item.name ? null : item.name));
    navigate(item.path);
  }, [navigate]);

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  return (
    <Flex flexDirection="column" w="250px" h="100vh">
      {/* Logo */}
      <Box h="80px" p="10px 78px">
        <Image src={logoMain} alt="Logo" />
      </Box>

      {/* Menu Accordion */}
      <Accordion allowToggle w="250px" bg="var(--color-sidermain)">
        {enumMenu
          .filter((item) => hasPermission(item.role))
          .map((item) => (
            <AccordionItem
              border="none"
              key={item.name}
              bg={activeItems === item.name ? "var(--color-sidersecondary)" : "var(--color-sidermain)"}
              _hover={{ bg: "var(--color-hover)" }}
              transition="background-color 0.3s ease"
            >
              <h2>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  gap="15px"
                  p="12px 20px"
                  onClick={() => handleToggle(item)}
                >
                  <Flex as="span" flex="1" textAlign="left" alignItems="center" gap="12px">
                    <Image src={item.icon} alt={item.name} />
                    <Text
                      fontSize="15px"
                      fontWeight="600"
                      color={location.pathname === item.path ? "var(--color-option-employee-hover)" : "var(--color-main)"}
                    >
                      {item.name}
                    </Text>
                  </Flex>
                  {item.children && (
                    <AccordionIcon
                      color="var(--color-main)"
                      transform={activeItems === item.name ? "rotate(0deg)" : "rotate(-90deg)"}
                      transition="transform 0.3s ease"
                    />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {item.children && (
                  <Box>
                    {item.children
                      .filter((subItem) => hasPermission(subItem.role))
                      .map((subItem) => (
                        <Box
                          key={subItem.name}
                          p="12px 0px 12px 44px"
                          cursor="pointer"
                          onClick={() => handleNavigate(subItem.path)}
                          _hover={{ color: "var(--color-hover)" }}
                        >
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            className={location.pathname === subItem.path ? "text-liner" : "text-unclick"}
                          >
                            {subItem.name}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Flex>
  );
};

export default React.memo(Sider);
