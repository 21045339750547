import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import CloseIconBlack from "../../../assets/images/product/closeIconBlack.svg";
import { useForm } from "react-hook-form";

const AddProduct = ({ isOpen, onClose, data, defaultValues, onSubmit }) => {
  const products = [
    {
      label: "Tên sản phẩm 1",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 2",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 3",
      value: "product1",
    },
    {
      label: "Tên sản phẩm 4",
      value: "product4",
    },
    {
      label: "Tên sản phẩm 5",
      value: "product5",
    },
  ];

  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const { handleSubmit } = useForm({
    defaultValues: defaultValues || {},
  });

  const submitHandler = (data) => {
    onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"501px"}
        w={"100%"}
        maxW={"500px"}
        overflowY={"auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex maxH={"501px"} flexDirection={"column"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
              padding={"25px 24px"}
              borderBottom={"1px solid var(--bg-line-employee)"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                Thêm sản phẩm vào đợt mở bán
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIconBlack} />
              </Box>
            </Flex>
            <Box flex={1} w={"452px"} m={"24px auto"}>
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                lineHeight={"19px"}
                textTransform={"uppercase"}
              >
                Tên dự án
              </Text>
              <Text
                fontSize={"12px"}
                fontWeight={"400"}
                lineHeight={"15px"}
                mt={"20px"}
              >
                Sản phẩm
              </Text>
              <Stack gap={0}>
                {products.map((item, key) => (
                  <Checkbox
                    key={key}
                    p={key === products.length - 1 ? "15px 0 4px 0" : "15px 0"}
                    gap={"24px"}
                    fontSize={"14px"}
                    lineHeight={"17px"}
                    fontWeight={"500"}
                    borderBottom={
                      key === products.length - 1
                        ? ""
                        : "1px solid var(--color-boder)"
                    }
                    onChange={() => handleCheckboxChange(item.value)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
              </Stack>
            </Box>
            <Flex p={"24px"} gap={"16px"} justifyContent={"flex-end"}>
              <Button
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--color-black)"}
                onClick={onClose}
                _hover={{
                  filter: "brightness(80%)",
                }}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                w={"112px"}
                h={"44px"}
                color={"var(--color-main)"}
                bg={"var(--color-black)"}
                _hover={{
                  filter: "brightness(80%)",
                }}
              >
                Xong
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddProduct;
