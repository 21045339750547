import React from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
} from "@chakra-ui/react";
import CloseIcon from "../../../assets/images/employee/closeIcon.svg";
import iconExcel from "../../../assets/images/employee/iconExcel.svg";
import { formatTableValue } from "../formatTable";
import { useGetCheckinEmployeeByMonth } from "../../../hooks/useAttendance";

const FormJobTitleDetail = ({ isOpen, onClose, dataDetail, onSubmit }) => {

  const date = (dataDetail.month && dataDetail.year) ?  dataDetail.month + "-"+ dataDetail.year : null;
  const {
    getCheckinEmployeeByMonth,
  } = useGetCheckinEmployeeByMonth(
    dataDetail.idEmployee,
    date,
  );

  const tableData = 
    {
        headers: [
            {
              label: "Ngày",
              key: "time",
            },
            {
              label: "Thời gian check in",
              key: "timeCheckIn",
            },
            {
              label: "Phân loại",
              key: "type",
            },
            {
              label: "Số công",
              key: "workingDayRate",
            },
        ],
        data: getCheckinEmployeeByMonth
    }
    
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent
      backgroundColor="var(--color-main)"
      borderRadius={"16px"}
      h={"86.5vh"}
      w={"100%"}
      maxW={"625px"}
      overflowY={"scroll"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          width: "4px",
          borderRadius: "50px",
          background: "var(--color-background)",
        },
        "&::-moz-scrollbar": {
          width: "4px",
        },
        "&::-moz-scrollbar-thumb": {
          width: "4px",
          borderRadius: "50px",
          background: "var(--color-background)",
        },
      }}
    >
      <Box>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
            padding={"25px 24px"}
            borderBottom={"1px solid var(--bg-line-employee)"}
        >
          <Text
            color="var(--color-header-employee)"
            fontSize={"18px"}
            fontWeight={"600"}
          >
            Bảng chấm công chi tiết
          </Text>
          <Box cursor={"pointer"} onClick={onClose}>
            <Image src={CloseIcon} />
          </Box>
        </Flex>
      </Box>
      <Flex padding={"29px 24px 16px 24px"}alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={"16px"} fontWeight={"600"} color={"var(--color-header-employee)"} >{dataDetail.fullName}</Text>
        <Button
          display={"flex"}
          alignItems={"center"}
          p={"10px 16px 10px 12px"}
          gap={"8px"}
          backgroundImage="var(--linear-gradient-excel)"
          maxW={"132px"}
          h={"44px"}
          _hover={{
            filter: "brightness(90%)",
          }}
        >
          <Image src={iconExcel} />
          <Text
            fontSize={{ base: "15px" }}
            fontWeight={600}
            color="var(--color-main)"
          >
            Xuất excel
          </Text>
        </Button>
      </Flex>
      <Box 
        borderRadius={"8px"}
        border={"1px solid var(--bg-line-employee)"}
        w={"545px"}
        m={"0 auto"}
      >
        <Flex 
            alignItems={"center"} 
            justifyContent={"space-between"} 
            p={"16px 12px"}
        >
            <Text 
                color={"var(--color-header-employee)"} 
                fontWeight={"600"}
                fontSize={"16px"}
            >
                {dataDetail.time}
            </Text>
            <Flex 
            alignItems={"center"} 
            gap={"12px"}
            >
                <Text 
                    color={"var(--color-label-employee)"} 
                    fontWeight={"400"}
                    fontSize={"16px"}
                >
                    Tổng số:
                </Text>
                <Text 
                    color={"var(--color-option-employee-hover)"} 
                    fontWeight={"600"}
                    fontSize={"16px"}
                >
                    {dataDetail?.workingDays || 0}
                </Text>
            </Flex>
        </Flex>
        <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {tableData.headers.map((e, index) => {
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={"20%"}
                      >
                        <Box textAlign={"center"}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {tableData.data?.length > 0 ? (
                  tableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"} bg={rowIndex%2===0 ? "var(--color-main)" : "var(--fill-avatar)"}>
                        {keyValues.map((keyvalue, index) => {
                          return (
                            <Td p={"16px 16px 8px 16px"} w={'auto'} key={index}>
                              <Box
                                fontSize={{ base: "14px" }}
                                textAlign={"center"}
                                fontWeight={500}
                                color={"#293755"}
                                h={"140%"}
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={tableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
      </Box>
    </ModalContent>
  </Modal>
  )
}

export default FormJobTitleDetail