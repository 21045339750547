import { Box, Stack, Switch, Text } from "@chakra-ui/react";

export const formatTableValue = (value, key, fn) => {
  switch (key) {
    case "num":
      return <Text textAlign={"center"}>{value}</Text>;
    case "status":
      const status = value;
      return (
        <Stack align="center" direction="row" justifyContent={"center"}>
          <Switch
            size="md"
            colorScheme="green"
            isChecked={status}
            onChange={fn}
          />
        </Stack>
      );
    case "code":
      return <Text>{value}</Text>;
    case "name":
      return <Text>{value}</Text>;
    case "timesupport":
      return <Text>{value}</Text>;
    case "date":
      const timestamp = value;

      const date = new Date(timestamp);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return <Text textAlign={"end"}>{formattedDate}</Text>;
    case "start":
      return (
        <Box>
          <Text textAlign={"end"}>{value[0]}</Text>
          <Text mt={"4px"} textAlign={"end"}>
            {value[1]}
          </Text>
        </Box>
      );
    case "end":
      return (
        <Box>
          <Text textAlign={"end"}>{value[0]}</Text>
          <Text mt={"4px"} textAlign={"end"}>
            {value[1]}
          </Text>
        </Box>
      );
    case "type":
      return (
        <Box>
          <Text textAlign={"start"}>{value[0]}</Text>
          <Text mt={"4px"} textAlign={"start"}>
            {value[1]}
          </Text>
        </Box>
      );
    case "employeecreate":
      return <Text textAlign={"end"}>{value}</Text>;
  }
};
