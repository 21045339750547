import React from "react";
import { Box, Flex } from "@chakra-ui/react";

export default function NonAvatar() {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <g clipPath="url(#clip0_1750_2784)">
          <path
            d="M27.6009 26.9754C31.3067 26.9754 34.5158 25.6461 37.1378 23.0237C39.7599 20.4017 41.089 17.1936 41.089 13.4873C41.089 9.78213 39.7599 6.57363 37.1374 3.95072C34.5149 1.32912 31.3063 0 27.6009 0C23.8945 0 20.6863 1.32912 18.0643 3.95116C15.4424 6.57319 14.1127 9.78181 14.1127 13.4873C14.1127 17.1936 15.4423 20.4022 18.0648 23.0242C20.6872 25.6458 23.8959 26.9754 27.6009 26.9754ZM51.2014 43.0611C51.1257 41.97 50.9728 40.7795 50.7476 39.5227C50.5204 38.2563 50.2278 37.0592 49.8773 35.965C49.5155 34.834 49.0233 33.7173 48.415 32.647C47.7835 31.5362 47.0418 30.5689 46.2095 29.773C45.3392 28.9403 44.2736 28.2708 43.0414 27.7823C41.8136 27.2967 40.4528 27.0506 38.9972 27.0506C38.4255 27.0506 37.8727 27.2851 36.805 27.9803C36.046 28.4745 35.2848 28.9653 34.5214 29.4525C33.7878 29.92 32.794 30.3579 31.5665 30.7544C30.369 31.1419 29.1531 31.3384 27.9529 31.3384C26.7528 31.3384 25.5372 31.1419 24.3384 30.7544C23.1123 30.3582 22.1185 29.9204 21.3857 29.4529C20.5359 28.9099 19.767 28.4143 19.1 27.9798C18.0336 27.2847 17.4803 27.0501 16.9087 27.0501C15.4526 27.0501 14.0923 27.2966 12.8648 27.7829C11.6334 28.2704 10.5675 28.9399 9.6963 29.7734C8.86451 30.5698 8.1224 31.5365 7.49175 32.647C6.88384 33.7172 6.39166 34.8336 6.0293 35.9654C5.6793 37.0596 5.38673 38.2563 5.15945 39.5227C4.93424 40.7779 4.78134 41.9687 4.70565 43.0624C4.63036 44.1631 4.59304 45.266 4.59376 46.3692C4.59376 49.2925 5.52301 51.6589 7.35547 53.4042C9.16529 55.1265 11.5599 56.0002 14.472 56.0002H41.4364C44.3485 56.0002 46.7422 55.127 48.5525 53.4043C50.3854 51.6602 51.3146 49.2934 51.3146 46.3688C51.3142 45.2405 51.2762 44.1275 51.2014 43.0611Z"
            fill="#EFF3F9"
          />
        </g>
        <defs>
          <clipPath id="clip0_1750_2784">
            <rect width="56" height="56" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Flex
        position={"absolute"}
        bottom={"0"}
        right={"0"}
        borderRadius={"50%"}
        w={"32px"}
        h={"32px"}
        justifyContent={"center"}
        alignItems={"center"}
        background={"var(--bg-option-employee)"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
            stroke="#1A9ED0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.66675 6.66663C6.21903 6.66663 6.66675 6.21891 6.66675 5.66663C6.66675 5.11434 6.21903 4.66663 5.66675 4.66663C5.11446 4.66663 4.66675 5.11434 4.66675 5.66663C4.66675 6.21891 5.11446 6.66663 5.66675 6.66663Z"
            stroke="#1A9ED0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9999 9.99996L10.6666 6.66663L3.33325 14"
            stroke="#1A9ED0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Flex>
    </Box>
  );
}
