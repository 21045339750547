export const inputStyles = {
  components: {
    Input: {
      baseStyle: {
        border: "none",
        borderRadius: "0px",
        _focus: {
          boxShadow: "none",
          borderColor: "#E1E1E1",
        },
      },
    },
  },
};
