import React, { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [employeeTab, setEmployeeTab] = useState(0);
  const [personnelCategoriesTab, setPersonnelCategoriesTab] = useState(0);
  const [timeKeepingTab, setTimeKeepingTab] = useState(0);

  return (
    <TabContext.Provider
      value={{
        employeeTab,
        setEmployeeTab,
        personnelCategoriesTab,
        setPersonnelCategoriesTab,
        timeKeepingTab,
        setTimeKeepingTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export const useTab = () => useContext(TabContext);
