import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  Button,
  Stack,
} from "@chakra-ui/react";
import CloseIcon from "../../../assets/images/employee/closeIcon.svg";
import { SelectControl } from "../../../components/form/formControls";
import { useForm } from "react-hook-form";

const FormJobTitleEdit = ({ isOpen, onClose, dataEdit, onSubmit }) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      saleArea: "-1",
      saleBlock: "-1",
      category: "-1",
      jobTitle: "-1",
    },
  });

  const [focusStates, setFocusStates] = useState({});
  const dataBranch = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ];

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeSaleArea= (option) => {
    console.log(option);
    setValue("saleArea", Number(option));
  };
  const onchangeSaleBlock = (option) => {
    setValue("saleBlock", Number(option));
  };
  const onchangeCategory = (option) => {
    setValue("category", Number(option));
  };
  const onchangeJobTitle = (option) => {
    setValue("jobTitle", Number(option));
  };

  const submitHandler = (data) => {
    // onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"86.5vh"}
        maxH={"625px"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"scroll"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
            <Flex flexDirection={"column"} h={"100%"}>
            <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"10px"}
                padding={"25px 24px"}
                borderBottom={"1px solid var(--bg-line-employee)"}
            >
                <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
                >
                Chỉnh sửa xác nhận công và chức danh
                </Text>
                <Box cursor={"pointer"} onClick={onClose}>
                <Image src={CloseIcon} />
                </Box>
            </Flex>
            <Flex flex={"1"} p={"24px"} flexDirection={"column"} h={"100%"}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text
                    color={"var(--color-header-employee)"}
                    fontWeight={"600"}
                    fontSize={"16px"}
                >
                    {dataEdit.fullName}
                </Text>
                <Flex alignItems={"center"} gap={"12px"}>
                    <Text
                    color={"var(--color-label-employee)"}
                    fontWeight={"400"}
                    fontSize={"16px"}
                    >
                    Tổng số công:
                    </Text>
                    <Text
                    color={"var(--color-option-employee-hover)"}
                    fontWeight={"600"}
                    fontSize={"16px"}
                    >
                    {dataEdit.number}
                    </Text>
                </Flex>
                </Flex>
                <Box flex={1} w={"100%"} maxW={"545px"} m={"24px auto"}>
                <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                >
                    <SelectControl
                    label="Phân loại"
                    dataOption={dataBranch}
                    placeholder="Chọn phân loại"
                    onChange={onchangeCategory}
                    selectedMain={watch("category")}
                    onFocus={() => handleFocus("category")}
                    onBlur={() => handleBlur("category")}
                    state={focusStates["category"]}
                    />
                    <SelectControl
                    label="Chức danh"
                    dataOption={dataBranch}
                    placeholder="Chọn chức danh"
                    onChange={onchangeJobTitle}
                    selectedMain={watch("jobTitle")}
                    onFocus={() => handleFocus("jobTitle")}
                    onBlur={() => handleBlur("jobTitle")}
                    state={focusStates["jobTitle"]}
                    />
                </Flex>
                <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                >
                    <SelectControl
                    onChange={onchangeSaleArea}
                    isSearchable={true}
                    selectedMain={watch("saleArea")}
                    onFocus={() => handleFocus("saleArea")}
                    onBlur={() => handleBlur("saleArea")}
                    state={focusStates["saleArea"]}
                    label="Vùng"
                    dataOption={dataBranch}
                    placeholder="Chọn vùng"
                    />
                    <SelectControl
                    label="Khối"
                    dataOption={dataBranch}
                    placeholder="Chọn khối"
                    onChange={onchangeSaleBlock}
                    selectedMain={watch("saleBlock")}
                    onFocus={() => handleFocus("saleBlock")}
                    onBlur={() => handleBlur("saleBlock")}
                    state={focusStates["saleBlock"]}
                    />
                </Flex>
                </Box>
                <Stack
                direction="row"
                spacing={4}
                align="center"
                justify={"flex-end"}
                >
                <Button
                    fontSize={"15px"}
                    fontWeight={"600"}
                    bg="var(--color-main)"
                    w={"112px"}
                    h={"44px"}
                    border={"1px solid var(--color-option-employee-hover)"}
                    color={"var(--color-option-employee-hover)"}
                    _hover={{
                    filter: "brightness(90%)",
                    }}
                    onClick={onClose}
                >
                    Hủy
                </Button>
                <Button
                    type="submit"
                    fontSize={"15px"}
                    fontWeight={"600"}
                    color={"var(--color-main)"}
                    bg="var(--linear-gradient-employee)"
                    w={"112px"}
                    h={"44px"}
                    _hover={{
                    filter: "brightness(90%)",
                    }}
                >
                    Xong
                </Button>
                </Stack>
            </Flex>
            </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FormJobTitleEdit;
