import {
  Box,
  Flex,
  Image,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Avatar,
} from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { usePopupFormInfo } from "../../contexts/usePopupFormInfo";
import { formatDate } from "../../utils/utils";
import useDebounce from "../../hooks/useDebounce";

const FormInfo = memo(({ isOpen, onClose, value }) => {
  // const [value, setDataEmployee] = useState(value);
  // const debouncedValue = useDebounce(value, 300);
  // useEffect(() => {
  //   if (debouncedValue) {
  //     setDataEmployee(debouncedValue);
  //   }
  // }, [debouncedValue, value]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="var(--color-main)"
        borderRadius={"16px"}
        h={"86.5vh"}
        w={"100%"}
        maxW={"625px"}
        overflowY={"scroll"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
          "&::-moz-scrollbar": {
            width: "4px",
          },
          "&::-moz-scrollbar-thumb": {
            width: "4px",
            borderRadius: "50px",
            background: "var(--color-background)",
          },
        }}
      >
        <Box>
          <Box
            paddingTop={"40px"}
            paddingBottom={"24px"}
            position={"relative"}
            borderBottom={"1px solid var(--border-input-employee)"}
          >
            <Box
              position={"absolute"}
              top={"24px"}
              right={"24px"}
              cursor={"pointer"}
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#99A4B8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 9L9 15"
                  stroke="#99A4B8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 9L15 15"
                  stroke="#99A4B8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
            <Flex
              maxW={"545px"}
              margin={"0 auto"}
              alignItems={"center"}
              gap={"40px"}
            >
              <Avatar
                size="2xl"
                name=""
                src={
                  `${process.env.REACT_APP_BACKEND_API}/${value?.avatar}` || ""
                }
              />
              <Box>
                <Text
                  fontSize={"20px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                >
                  {value?.fullName}
                </Text>
                <Flex
                  mt={"12px"}
                  gap={"8px"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Text
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"var(--color-label-employee)"}
                  >
                    {value?.code}
                  </Text>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={"5px"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="5"
                      viewBox="0 0 4 5"
                      fill="none"
                    >
                      <circle cx="2" cy="2.5" r="2" fill="#DAE0EB" />
                    </svg>
                  </Box>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    p={"8px 16px"}
                    // h={"33px"}
                    // w={"100px"}
                    fontSize={"14px"}
                    fontWeight={"600"}
                    color={"var(--color-main)"}
                    background={"var(--linear-gradient-employee)"}
                    borderRadius={"100px"}
                    cursor={"pointer"}
                    _hover={{
                      filter: "brightness(90%)",
                    }}
                  >
                    {value?.jobPosition}
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            paddingTop={"24px"}
            paddingBottom={"41px"}
            maxW={"545px"}
            margin={"0 auto"}
          >
            <Box>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Tổng quan
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"13px"}
                borderRadius={"8px"}
              >
                <Flex>
                  {value?.branch && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Chi nhánh
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.branch}
                      </Text>
                    </Box>
                  )}
                  {value?.department != undefined && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Khối bộ phận
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.department
                          ? "Khối kinh doanh"
                          : "Khối hỗ trợ kinh doanh"}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.saleArea && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Vùng
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.saleArea}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.supportDepartment && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Phòng ban
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.supportDepartment}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.saleArea && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Vùng
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.saleArea}
                      </Text>
                    </Box>
                  )}
                  {value?.saleBlock && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Khối
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.saleBlock}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.startTime && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ngày vào làm
                      </Text>
                      <Text
                        color={"var(--color-info-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {formatDate(value?.startTime)}
                      </Text>
                    </Box>
                  )}
                  {value?.status !== (undefined || null) && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Trạng thái hoạt động
                      </Text>
                      <Text
                        color={
                          !Boolean(value?.status)
                            ? "var(--color-status-employ)"
                            : "var(--text-red-employee)"
                        }
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {!Boolean(value?.status)
                          ? "Đang làm việc"
                          : "Đã nghỉ làm"}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
            {value?.department == 1 && (
              <Box mt={"20px"}>
                <Text
                  color={"var(--color-header-employee)"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                  Phân loại nhân sự
                </Text>
                <Box
                  background={"var(--fill-avatar)"}
                  p={"16px 24px 16px 16px"}
                  mt={"16px"}
                  borderRadius={"8px"}
                >
                  <Flex>
                    {value?.businessCategory && (
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Phân loại
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {value?.businessCategory}
                        </Text>
                      </Box>
                    )}
                    {value?.referralCode && (
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Mã giới thiệu
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {value?.referralCode}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                  <Flex mt={"16px"}>
                    {value?.guaranteeType && (
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Loại bảo lãnh
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {value?.guaranteeType}
                        </Text>
                      </Box>
                    )}
                    {value?.guaranteeDate && (
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ngày bảo lãnh
                        </Text>
                        <Text
                          color={"var(--color-option-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {formatDate(value?.guaranteeDate)}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                  <Flex mt={"16px"}>
                    {value?.depositDate && (
                      <Box flex={"1"}>
                        <Text
                          color={"var(--color-label-employee)"}
                          fontSize={"12px"}
                        >
                          Ngày ký quỹ
                        </Text>
                        <Text
                          color={"var(--color-info-employee)"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                          mt={"8px"}
                        >
                          {formatDate(value?.depositDate)}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Box>
            )}
            <Box mt={"20px"}>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Thông tin liên hệ
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"16px"}
                borderRadius={"8px"}
              >
                <Flex>
                  {value?.phone && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Số điện thoại
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.phone}
                      </Text>
                    </Box>
                  )}
                  {value?.email && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Email
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.email}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.currentAddress && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Chỗ ở hiện tại
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.currentAddress}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
            <Box mt={"20px"}>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Thông tin giấy tờ
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"16px"}
                borderRadius={"8px"}
              >
                <Flex>
                  {value?.identificationNumber && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Số CCCD
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.identificationNumber}
                      </Text>
                    </Box>
                  )}
                  {value?.dateOfIdentification && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Ngày cấp
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {formatDate(value?.dateOfIdentification)}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={"16px"}>
                  {value?.placeOfIdentification && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Nơi cấp
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.placeOfIdentification}
                      </Text>
                    </Box>
                  )}
                  {value?.permanentAddress && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Địa chỉ thường trú
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.permanentAddress}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
            <Box mt={"20px"}>
              <Text
                color={"var(--color-header-employee)"}
                fontSize={"16px"}
                fontWeight={"600"}
              >
                Thông tin thanh toán
              </Text>
              <Box
                background={"var(--fill-avatar)"}
                p={"16px 24px 16px 16px"}
                mt={"16px"}
                borderRadius={"8px"}
              >
                <Flex>
                  {value?.bankAccount && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Số tài khoản
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.bankAccount}
                      </Text>
                    </Box>
                  )}
                  {value?.bankName && (
                    <Box flex={"1"}>
                      <Text
                        color={"var(--color-label-employee)"}
                        fontSize={"12px"}
                      >
                        Tên ngân hàng
                      </Text>
                      <Text
                        color={"var(--color-option-employee)"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mt={"8px"}
                      >
                        {value?.bankName}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default FormInfo;
