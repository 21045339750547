export const ROLES_CONFIG = {
  ROUTES: {
    SignIn: [],
    NotFoundPage: [],
    Employee: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_EMPLOYEE_MANAGEMENT",
    ],
    TimeKeeping: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_TIMEKEEPING_MANAGEMENT",
      // Level 3
      "MSH_LIST_TIMEKEEPING_MANAGEMENT",
      "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
      "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
    ],
    OnLeave: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_LEAVE_MANAGEMENT",
    ],
    JobTitle: [
      // Level 1
      "MSH_HUMAN_RESOURCE_MANAGEMENT",
      // Level 2
      "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
    ],
    ProjectManagement: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_PROJECT_MANAGEMENT",
    ],
    Apartments: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
    ],
    Booking: [
      // Level 1
      "MSH_SALES_MANAGEMENT",
      // Level 2
      "MSH_BOOKING_BATCH_MANAGEMENT",
    ],
    OpenForSale: [
      // Level 1
      "MSH_INVENTORY_MANAGEMENT",
      // Level 2
      "MSH_OPENING_SALE_MANAGEMENT",
    ],
    Customers: [
      // Level 1
      "MSH_INVENTORY_MANAGEMENT",
      // Level 2
      "MSH_CUSTOMER_MANAGEMENT",
    ],
    PersonnelCategories: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
      // Level 3
      "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
      "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
      "MSH_CATEGORIES_BRANCH_MANAGEMENT",
      "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
      "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
      "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
      "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
    ],
    OnLeaveCategories: [
      // Level 1
      "MSH_CATEGORIES_MANAGEMENT",
      // Level 2
      "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
      // Level 3
      "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
      "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
    ],
    AdminManagement: ["MSH_ADMIN_MANAGEMENT"],
  },
  MENU: {
    MENU_QLNS: {
      role: [
        // Level 1
        "MSH_HUMAN_RESOURCE_MANAGEMENT",
        // Level 2
        "MSH_EMPLOYEE_MANAGEMENT",
        "MSH_TIMEKEEPING_MANAGEMENT",
        "MSH_LEAVE_MANAGEMENT",
        "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
        // Level 3
        "MSH_LIST_TIMEKEEPING_MANAGEMENT",
        "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
        "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_QLNV: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_EMPLOYEE_MANAGEMENT",
          ],
        },
        CHILDREN_QLCC: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_TIMEKEEPING_MANAGEMENT",
            // Level 3
            "MSH_LIST_TIMEKEEPING_MANAGEMENT",
            "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
            "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
          ],
          TAB: {
            TAB_DSCC: {
              role: [
                // Level 1
                "MSH_HUMAN_RESOURCE_MANAGEMENT",
                // Level 2
                "MSH_TIMEKEEPING_MANAGEMENT",
                // Level 3
                // "MSH_LIST_TIMEKEEPING_MANAGEMENT",
                "MSH_REPORT_TIMEKEEPING_MANAGEMENT",
              ],
            },
            TAB_DSDDCC: {
              role: [
                // Level 1
                "MSH_HUMAN_RESOURCE_MANAGEMENT",
                // Level 2
                "MSH_TIMEKEEPING_MANAGEMENT",
                // Level 3
                // "MSH_LIST_TIMEKEEPING_MANAGEMENT",
                "MSH_PLACE_TIMEKEEPING_MANAGEMENT",
              ],
            },
          },
        },
        CHILDREN_XNP: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_LEAVE_MANAGEMENT",
          ],
        },
        CHILDREN_XNC_AND_CDHT: {
          role: [
            // Level 1
            "MSH_HUMAN_RESOURCE_MANAGEMENT",
            // Level 2
            "MSH_MONTHLY_CONFIRMATION_OF_WORK_AND_TITLE",
          ],
        },
      },
    },
    MENU_QLNH: {
      role: [
        // Level 1
        "MSH_INVENTORY_MANAGEMENT",
        // Level 2
        "MSH_PROJECT_MANAGEMENT",
        "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
        "MSH_BOOKING_BATCH_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_QLDA: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_PROJECT_MANAGEMENT",
          ],
        },
        CHILDREN_QLSP_AND_CH: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_PRODUCT_AND_APARTMENT_MANAGEMENT",
          ],
        },
        CHILDREN_DN_BOOKING: {
          role: [
            // Level 1
            "MSH_INVENTORY_MANAGEMENT",
            // Level 2
            "MSH_BOOKING_BATCH_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QLBH: {
      role: [
        // Level 1
        "MSH_SALES_MANAGEMENT",
        // Level 2
        "MSH_OPENING_SALE_MANAGEMENT",
        "MSH_CUSTOMER_MANAGEMENT",
        "MSH_BOOKING_MANAGEMENT",
        "MSH_LOOKING_MANAGEMENT",
        "MSH_CONFIRMATION_SALES_CONTRACT",
      ],
      CHILDREN: {
        CHILDREN_QLDMB: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_OPENING_SALE_MANAGEMENT",
          ],
        },
        CHILDREN_QLKH: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_CUSTOMER_MANAGEMENT",
          ],
        },
        CHILDREN_QL_BOOKING: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_BOOKING_MANAGEMENT",
          ],
        },
        CHILDREN_QL_LOOKING: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_LOOKING_MANAGEMENT",
          ],
        },
        CHILDREN_XNHDMB: {
          role: [
            // Level 1
            "MSH_SALES_MANAGEMENT",
            // Level 2
            "MSH_CONFIRMATION_SALES_CONTRACT",
          ],
        },
      },
    },
    MENU_QLDM: {
      role: [
        // Level 1
        "MSH_CATEGORIES_MANAGEMENT",
        // Level 2
        "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
        "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
        "MSH_CATEGORIES_PROJECT_MANAGEMENT",
        "MSH_CATEGORIES_PRODUCT_MANAGEMENT",
        // Level 3
        "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
        "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
        "MSH_CATEGORIES_BRANCH_MANAGEMENT",
        "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
        "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
        "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
        "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
        "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
        "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_NS: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
            // Level 3
            "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
            "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
            "MSH_CATEGORIES_BRANCH_MANAGEMENT",
            "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
            "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
            "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
            "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
          ],
          TAB: {
            TAB_CD_AND_PB_AND_CN: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
                "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
                "MSH_CATEGORIES_BRANCH_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_DSCD: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_JOBTITLE_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DSPB: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SUPPORT_DEPARTMENT_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_DSCN: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_BRANCH_MANAGEMENT",
                  ],
                },
              },
            },
            TAB_KBP_AND_V_AND_K: {
              role: [
                // Level 1
                "MSH_CATEGORIES_MANAGEMENT",
                // Level 2
                "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                // Level 3
                "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
                "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
                "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
                "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
              ],
              CHILDREN_TAB: {
                CHILDREN_TAB_KBP: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_DEPARTMENT_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_V: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SALE_AREA_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_K: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_SALE_BLOCK_MANAGEMENT",
                  ],
                },
                CHILDREN_TAB_PLNVKD: {
                  role: [
                    "MSH_CATEGORIES_MANAGEMENT",
                    "MSH_CATEGORIES_EMPLOYEE_MANAGEMENT",
                    "MSH_CATEGORIES_BUSINESS_MANAGEMENT",
                  ],
                },
              },
            },
          },
        },
        CHILDREN_C_AND_NP: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_WORK_AND_LEAVE_MANAGEMENT",
            // Level 3
            "MSH_CATEGORIES_HOLIDAY_MANAGEMENT",
            "MSH_CATEGORIES_BUSINESS_CATEGORY_MANAGEMENT",
          ],
        },
        CHILDREN_DA: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_PROJECT_MANAGEMENT",
          ],
        },
        CHILDREN_SP: {
          role: [
            // Level 1
            "MSH_CATEGORIES_MANAGEMENT",
            // Level 2
            "MSH_CATEGORIES_PRODUCT_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QLDS: {
      role: [
        // Level 1
        "MSH_INCOME_MANAGEMENT",
        // Level 2
        "MSH_CACULATION_MANAGEMENT",
        "MSH_COMMISSION_CONFIRMATION",
        "MSH_REVENUE_MANAGEMENT",
      ],
      CHILDREN: {
        CHILDREN_THH: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_CACULATION_MANAGEMENT",
          ],
        },
        CHILDREN_XNHH: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_COMMISSION_CONFIRMATION",
          ],
        },
        CHILDREN_QLDS: {
          role: [
            // Level 1
            "MSH_INCOME_MANAGEMENT",
            // Level 2
            "MSH_REVENUE_MANAGEMENT",
          ],
        },
      },
    },
    MENU_QL_ADMIN: {
      role: [
        // Level 1
        "MSH_ADMIN_MANAGEMENT",
      ],
      CHILDREN: {},
    },
  },
};
