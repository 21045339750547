import {
  Box,
  Flex,
  Image,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import iconClose from "../../assets/images/projectManagement/icon-close.svg";
import FormProjectManagementAdd from "../form/formProjectManagementAdd";
import { formatTablePopup } from "../../pages/projectManagement/formatTablePopup";
import Slider from "../slider";

const PopupProjectManagementTitle = ({ isOpen, onClose }) => {
  const historyTableData = {
    headers: [
      {
        label: "Tên nhân viên",
        key: "name",
      },
      {
        label: "Chức danh",
        key: "jobtitle",
      },
      {
        label: "Hệ số thưởng",
        key: "bonuscoefficient",
      },
    ],
    data: [
      {
        name: "Lương Nhật Trường",
        jobtitle: "Nhân viên",
        bonuscoefficient: "20%",
      },
      {
        name: "Lương Nhật Trường",
        jobtitle: "Nhân viên",
        bonuscoefficient: "20%",
      },
      {
        name: "Lương Nhật Trường",
        jobtitle: "Nhân viên",
        bonuscoefficient: "20%",
      },
      {
        name: "Lương Nhật Trường",
        jobtitle: "Nhân viên",
        bonuscoefficient: "20%",
      },
    ],
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"16px"}
        maxW={"1210px"}
        maxH={"800px"}
        overflow="auto"
      >
        <Flex
          borderBottom={"1px solid var(--color-boder)"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"24px 20px 24px 24px"}
        >
          <Text fontSize={"18px"} fontWeight={600} lineHeight={"normal"}>
            Chi tiết dự án
          </Text>
          <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
        </Flex>
        <Box p={"24px 40px 107px 40px"}>
          <Flex gap={"40px"}>
            <Flex w={"50%"} flexDirection={"column"} gap={{ base: "24px" }}>
              <Flex flexDirection={"column"} gap={"8px"}>
                <Text
                  fontSize={"20px"}
                  fontWeight={600}
                  lineHeight={"140%"}
                  textTransform={"uppercase"}
                  color="var(--color-black)"
                >
                  THE DIAMOND RESIDENCE
                </Text>
                <Text
                  color="var(--color-black)"
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"140%"}
                  textTransform={"capitalize"}
                >
                  25 Lê văn Lương, Nhân Chính, Thanh xuân, hà nội
                </Text>
              </Flex>
              <Flex flexDirection={"column"} gap={"16px"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"normal"}
                  color="var(--color-black)"
                >
                  Đặc điểm dự án
                </Text>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <FormProjectManagementAdd
                    title="Hệ số K"
                    placeholder="Nhập hệ số K"
                    isBorderBottom={true}
                  />
                  <FormProjectManagementAdd
                    title="Diện tích"
                    placeholder="Nhập diện tích"
                    isBorderBottom={true}
                  />
                </Flex>
                <Flex alignItems={"center"} gap={{ base: "27px" }}>
                  <FormProjectManagementAdd
                    title="Loại hình dự án"
                    placeholder="Chọn loại hình dự án"
                    isBorderBottom={true}
                  />
                  <FormProjectManagementAdd
                    title="Thành phố"
                    placeholder="Nhập tên thành phố"
                    isBorderBottom={true}
                  />
                </Flex>
                <FormProjectManagementAdd
                  title="Chủ đầu tư"
                  placeholder="Chọn chủ đầu tư"
                  isBorderBottom={true}
                />
                <FormProjectManagementAdd
                  title="Tài liệu dự án"
                  placeholder="Tải tài liệu lên"
                  isBorderBottom={true}
                />
              </Flex>
              <Flex flexDirection={"column"} gap={"16px"}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"normal"}
                    color="var(--color-black)"
                  >
                    Nhân viên quản lý
                  </Text>
                </Flex>
                <Flex
                  border={"1px solid var(--color-boder)"}
                  borderRadius={"8px"}
                >
                  <TableContainer w={"100%"} pb={"32px"}>
                    <Table w={"100%"} variant="unstyled">
                      <Thead
                        fontSize={"12px"}
                        w={"100%"}
                        color="var(--color-black-label)"
                        fontWeight="400"
                        borderBottom={"1px solid var(--color-boder)"}
                      >
                        <Tr>
                          {historyTableData.headers.map((e, index) => {
                            let width = "auto";
                            let textAlign = "auto";
                            if (e.key === "name") {
                              width = "40%";
                              textAlign = "start";
                            } else if (e.key === "jobtitle") {
                              width = "20%";
                              textAlign = "start";
                            } else if (e.key === "bonuscoefficient") {
                              width = "20%";
                              textAlign = "start";
                            }
                            return (
                              <Td
                                p={"12px 12px 8px 16px"}
                                key={index}
                                border={"none"}
                                color={"#51607B"}
                                fontSize={{ base: "14px" }}
                                w={width}
                              >
                                <Box textAlign={textAlign}>{e.label}</Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody w={"100%"} h={"100%"}>
                        {historyTableData.data?.length > 0 ? (
                          historyTableData.data?.map((e, rowIndex) => {
                            const keyValues = Object.keys(e);
                            return (
                              <Tr
                                w={"100%"}
                                key={rowIndex}
                                h={"44px"}
                                backgroundColor={
                                  rowIndex % 2 === 0
                                    ? "#transparent"
                                    : "var(--fill-avatar)"
                                }
                              >
                                {keyValues.map((keyvalue, index) => {
                                  return (
                                    <Td p={"12px 10px 12px 16px"} key={index}>
                                      <Box
                                        fontSize={{ base: "14px" }}
                                        fontWeight={500}
                                        color="var(--color-black)"
                                        h={"140%"}
                                      >
                                        {formatTablePopup(
                                          e[keyvalue],
                                          keyvalue
                                        )}
                                      </Box>
                                    </Td>
                                  );
                                })}
                              </Tr>
                            );
                          })
                        ) : (
                          <Tr>
                            <Td
                              colSpan={historyTableData.headers.length}
                              textAlign="center"
                              padding={"40px 0"}
                            >
                              Không có dữ liệu
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
            <Flex w={"50%"} flexDirection={"column"} gap={"24px"}>
              <Flex flexDirection={"column"} gap={"8px"}>
                <Text fontSize={"16px"} fontWeight={600}>
                  Ảnh dự án
                </Text>
                <Slider />
              </Flex>

              <Flex flexDirection={"column"} gap={"8px"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  lineHeight={"normal"}
                  color="var(--color-black)"
                >
                  Mô tả dự án
                </Text>
                <UnorderedList
                  m={0}
                  listStyleType="none"
                  fontSize={"14px"}
                  fontWeight={400}
                  lineHeight={"140%"}
                  textTransform={"capitalize"}
                  color="var(--color-black)"
                >
                  <ListItem>
                    - Trực Tiếp Từ Chủ Đầu Tư BRG, Chiết Khấu 1.5%. Giá Từ 70
                    Triệu/M².
                  </ListItem>
                  <ListItem>- Thiết kế Từ 2 - 4 Phòng Ngủ.</ListItem>
                  <ListItem>Dự Án BRG Diamond:</ListItem>
                  <UnorderedList listStyleType="none" m={0}>
                    <ListItem>
                      - Đầy Đủ Các Tiện Ích Trong Tòa Nhà: Trung Tâm Thương Mại,
                      Bể Bơi, Gym, Spa,...
                    </ListItem>
                    <ListItem>
                      + 5,5 Tầng Đỗ Xe, Để Ô Tô, Xe Máy Thoải Mái, Khu Vui Chơi
                      Trẻ Em, Phòng Sinh Hoạt Cộng Đồng.Mật Độ Thang Máy Cực Kỳ
                      Hợp Lý Với 6 Thang / 1 Tòa.
                    </ListItem>
                    <ListItem>
                      + Vận Hành Bởi Savills, Đơn Vị Vận Hành Chuẩn 5*.
                    </ListItem>
                    <ListItem>
                      + Hành Lang Căn Hộ Lên Tới 2.1m, Chiều Cao Trần 3.6m Cho
                      Căn Hộ Cực Kỳ Thoáng.
                    </ListItem>
                  </UnorderedList>
                  <ListItem>
                    - Liên Hệ Ngay Em Hà Để Được Tư Vấn Tốt Nhất Về Ưu Đãi,
                    Chính Sách Bán Hàng Mới Nhất.
                  </ListItem>
                  <ListItem>- Làm Việc 24/7 Miễn Phí.</ListItem>
                </UnorderedList>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PopupProjectManagementTitle;
