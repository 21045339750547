import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  Button,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  MenuButton,
  Image,
  HStack,
  Text,
  useDisclosure,
  Img,
  Skeleton,
} from "@chakra-ui/react";
import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../../assets/images/employee/edit.svg";
import iconDelete from "../../../assets/images/timeKeeping/delete.svg";
import chevronLeft from "../../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../../assets/images/employee/chevron-right.svg";
import PopUpTimeKeeping from "../../../components/popup/popupTimeKeepingEdit";
import { useTimeKeeping } from "../../../hooks/useTimeKeeping";
import ReactPaginate from "react-paginate";
import DeleteDialog from "../../../components/dialog/delete";
import toast from "react-hot-toast";
import { timeKeepingCall } from "../../../api/timekeeping";
import { checkinPlaceCall } from "../../../api/checkinPlace";
import PopupQRCode from "../../../components/popup/popupQrcode";

const TimeKeepingPoints = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [inputColors, setInputColors] = useState({
    dateStartEp: "var(--color-text-unclick)",
    dateEndEp: "var(--color-text-unclick)",
    dateGuaranteeEp: "var(--color-text-unclick)",
    dateOfIdentificationEp: "var(--color-text-unclick)",
    dateSignFundEp: "var(--color-text-unclick)",
  });

  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: oncloseDialog,
    onToggle: toggleDialog,
  } = useDisclosure();
  const {
    timekeepingData,
    totalPages,
    setCurrentPage,
    refetch,
    isLoading,
    isRefetching,
    currentPage,
    entries,
    startEntry,
    endEntry,
  } = useTimeKeeping();

  const [qrCode, setQrCode] = useState(false);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    refetch();
  };

  const [dataEdit, setDataEdit] = useState({});
  //Delete
  const handleOpenDeleteTimeKeeping = (data) => { 
    setQrCode(false);
    onOpenDialog();
    setDataEdit(data);
  };
  const handleDeleteTimeKeeping = async (data) => { console.log(data)
    const toastUpdate = toast.loading("Đang xóa điểm chấm công ...");
    const id = data.edit.id;
    try {
      await timeKeepingCall.delete(id);
      await refetch();
      // await getStats();
      toast.success("Xóa thành công");
      toggleDialog();
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  const handleChangeEmployeeStatus = async (data) => {
    const toastUpdate = toast.loading("Đang cập nhật trạng thái ...");
    const { id, status } = data;

    try {
      await timeKeepingCall.updateStatus(id, !status);
      await refetch();
      // await getStats();
      toast.success("Cập nhập trang thái thành công");
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastUpdate);
  };

  const handleOpenEdit = (data) => { 
    onOpen();
    setQrCode(false);
    let id = data.edit.id;
    let checkInTypes;
      if(data?.editData.checkInTypes[0] === 'IMAGE'){
        checkInTypes = "0";
      }else if(data?.editData.checkInTypes[0] === 'QRCODE'){
        checkInTypes = "1";
      }else{
        checkInTypes = null;
      }
    let datas = {
      status: data?.status ? 0 : 1,
      name: data?.name,
      location: {
          lat: data?.editData.location.coordinates[1],
          lng: data?.editData.location.coordinates[0]
      },
      timeForSale: data?.editData.timeForSale,
      lateTimeForSale: data?.editData.lateTimeForSale,
      timeForSupport: data?.editData.timeForSupport,
      lateTimeForSupport: data?.editData.lateTimeForSupport,
      checkInTypes: checkInTypes
    }
  
    setDataEdit({id,datas});
  };

  const handleQrCode = async (data) => {
    setQrCode(true);
    onOpen();
    let id = data.edit.id;
    setDataEdit({id,data});
  }

  const onSubmit = async (id, data) => {
    const toastCreate = toast.loading("Đang sửa địa điểm ...");
    try { 
      let status = data.status === 1 ? false : true;
      let checkInTypes;
      if( data.checkInTypes === '0'){
        checkInTypes = ['IMAGE'];
      }else if( data.checkInTypes === '1'){
        checkInTypes = ['QRCODE'];
      }else{
        checkInTypes = [];
      }

      let filteredData = {
        status: status,
        name: data.name,
        location: data.location,
        timeForSale: data.timeForSale,
        lateTimeForSale: data.lateTimeForSale,
        timeForSupport: data.timeForSupport,
        lateTimeForSupport: data.lateTimeForSupport,
        checkInTypes: checkInTypes
      }
    
      await checkinPlaceCall.update(id, filteredData); 
      await refetch();
      onToggle();
      toast.success("Update thành công");
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }
      
    toast.dismiss(toastCreate);
  };

  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "STT",
        key: "num",
      },
      {
        label: "Trạng thái hđ",
        key: "status",
      },
      {
        label: "Mã Địa điểm chấm",
        key: "code",
      },
      {
        label: "Địa điểm chấm",
        key: "name",
      },
      {
        label: "Phân loại",
        key: "type",
      },
      {
        label: "TGCC đúng giờ",
        key: "timestart",
      },
      {
        label: "TGCC muộn",
        key: "timelate",
      },
      {
        label: "Ngày tạo",
        key: "date",
      },
      {
        label: "Người tạo",
        key: "employeecreate",
      },
    ],
    data: timekeepingData,
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        minHeight="calc(100vh - 167px)"
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup width={"300px"} borderRadius={{ base: "8px" }}>
            <Input
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
            />
            <InputRightElement
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"100%"}
          minH="calc(100vh - 235px)"
        >
          <TableContainer w={"100%"} pb={"32px"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {historyTableData.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "") {
                      width = "2%";
                      textAlign = "center";
                    } else if (e.key === "num") {
                      width = "2%";
                      textAlign = "center";
                    } else if (e.key === "type") {
                      width = "12%";
                      textAlign = "start";
                    } else if (e.key === "status") {
                      width = "10%";
                      textAlign = "center";
                    } else if (e.key === "name") {
                      width = "30%";
                      textAlign = "start";
                    } else if (e.key === "timesupport") {
                      width = "8%";
                      textAlign = "end";
                    } else if (e.key === "timelate") {
                      width = "8%";
                      textAlign = "end";
                    } else {
                      width = "10%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {historyTableData.data?.length > 0 ? (
                  historyTableData.data?.map((e, rowIndex) => {
                    const items = { ...e };
                    delete items.edit;
                    delete items.editData;
                    const keyValues = Object.keys(items);
                    return (
                      <Tr
                        w={"100%"}
                        key={rowIndex}
                        h={"72px"}
                        backgroundColor={
                          rowIndex % 2 === 0
                            ? "#transparent"
                            : "var(--fill-avatar)"
                        }
                      >
                        <Td p={"16px 16px 8px 16px"}  verticalAlign={"top"}>
                          <Menu>
                            <MenuButton
                              _hover={{ backgroundColor: "none" }}
                              _active={{ backgroundColor: "none" }}
                              background="none"
                              as={Button}
                              alignItems={"unset"}
                            >
                              <AiOutlineMore fontSize={"20px"} />
                            </MenuButton>
                            <MenuList
                              fontSize={{ base: "14px" }}
                              fontWeight={500}
                              lineHeight={"140%"}
                              color="var(--color-option-employee)"
                            >
                              <MenuItem gap={"16px"} 
                                onClick={() =>
                                  handleOpenEdit(e)
                                }
                                >
                                <Image src={iconEdit} /> Chỉnh sửa
                              </MenuItem>
                              <MenuItem gap={"16px"} 
                                onClick={() =>
                                  handleQrCode(e)
                                }
                                >
                                <ExternalLinkIcon w={"20px"} h={"20px"} /> QrCode
                              </MenuItem>
                              <MenuItem
                                gap={"16px"}
                                onClick={() =>handleOpenDeleteTimeKeeping(e)}
                              >
                                <Image src={iconDelete} />
                                Xóa điểm CC
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                        {keyValues.map((keyvalue, index) => {
                          const isStatus = keyvalue === "status";
                          let width = "auto";
                          let textAlign = "auto";
                          if (keyvalue === "") {
                            width = "2%";
                            textAlign = "center";
                          } else if (keyvalue === "num") {
                            width = "2%";
                            textAlign = "center";
                          } else if (keyvalue === "type") {
                            width = "12%";
                            textAlign = "start";
                          } else if (keyvalue === "status") {
                            width = "10%";
                            textAlign = "center";
                          } else if (keyvalue === "name") {
                            width = "30%";
                            textAlign = "start";
                          } else if (keyvalue === "timesupport") {
                            width = "8%";
                            textAlign = "end";
                          } else if (keyvalue === "timelate") {
                            width = "8%";
                            textAlign = "end";
                          } else {
                            width = "10%";
                            textAlign = "end";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index} verticalAlign={"top"}>
                              <Box
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                textAlign={textAlign}
                                color="var(--color-option-employee)"
                                h={"140%"}
                                onClick={() => {
                                  console.log();
                                }}
                              >
                                {formatTableValue(
                                  items[keyvalue],
                                  keyvalue,
                                  isStatus
                                    ? () => handleChangeEmployeeStatus(e?.edit)
                                    : undefined
                                )}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={historyTableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex
            m={"50px 16px 16px 16px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"20px"}
              color={"var(--text-color-Subtittle)"}
            >
              Showing {startEntry} to {endEntry} of {entries} entries
            </Text>
            <HStack spacing={2} justify="flex-end">
              <ReactPaginate
                className="pagination-custom"
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"ellipsis"}
                breakLabel={"..."}
                previousLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />
                }
                nextLabel={
                  <Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />
                }
                renderOnZeroPageCount={null}
                initialPage={currentPage - 1}
              />
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      {
      qrCode ?
      <PopupQRCode
        isOpen={isOpen}
        onClose={onClose}
        id={dataEdit.id}
      />
        :
        <>
        <PopUpTimeKeeping 
          isOpen={isOpen} 
          onClose={onClose} 
          onSubmit={onSubmit}
          idEdit={dataEdit.id}
          defaultValues={dataEdit.datas}
        />
        <DeleteDialog
          isOpen={isOpenDialog}
          onClose={oncloseDialog}
          title={"Xóa điểm chấm công"}
          description={`Bạn có muốn xóa ${dataEdit?.name} không ?`}
          action={() => handleDeleteTimeKeeping(dataEdit)}
        />
        </>
        
      }
    </Flex>
  );
};

export default TimeKeepingPoints;
